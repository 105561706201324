import axios from "axios";
import { API_URL, APP_STRIPE_BASE_URL } from "./AppApiUrls";

//const BASE_URL = "https://www.webhook.synx.ai/app/api/stripe";
const BASE_URL = `${API_URL}${APP_STRIPE_BASE_URL}`;
//const BASE_URL = "http://13.232.11.86:8000/app/api/user";
//const BASE_URL = "http://192.168.0.109:8000/app/api/user";

export async function createStripeSession() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/create-customer-portal-session`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else return Promise.reject(data.msg);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function linkAccountWithCustomerId() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/customer/link`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve();
    else return Promise.reject(data.msg);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function getStripeCustomerDetails() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/customer/details`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.type);
    else return Promise.reject(data.msg);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}
