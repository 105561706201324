import React, { useEffect, useState } from "react";
import { Card, Typography, CardBody, Spinner, Button } from "@material-tailwind/react";
import { accountStatusUpdate, getAllUserData } from "../../../../helpers/AppUserApi";
import { toast } from "react-hot-toast";
import CustomMenuHandler from "./CustomMenuHandler";
import ActiveSwitchHandler from "./ActiveSwitchHandler";
import HintHandler from "./HintHandler";
import OverrideSwitchHandler from "./OverrideSwitchHandler";

const TABLE_HEAD = ["Account name", "Email", "Status", "Override", "Connected With", ""];

const UserTable = ({ handleTableLoader, handleDisableChangeButton, handleEnableChangeButton }) => {
  let toastBox = toast;
  const [userData, setUserData] = useState([]);
  const [newUserData, setNewUserData] = useState([]);
  const [userDataFetchedOnce, setUserDataFetchedOnce] = useState(false);
  const [showChangeButton, setShowChangeButton] = useState(false);

  const getAllUserDataFunc = () => {
    const getAllUserDataPromise = getAllUserData();
    getAllUserDataPromise
      .then(
        (data) => {
          setUserData(data);
          setUserDataFetchedOnce(true);
          // setTimeout(() => {
          //   handleTableLoader();
          // }, 500);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const handleUserChangeButton = () => {
    let toastBox = toast;
    toast.loading("Updating...", {
      id: toastBox,
    });
    let statusUpdatePromise = accountStatusUpdate(newUserData);
    statusUpdatePromise.then(
      (data) => {
        toast.success("Updated Successfully!", {
          id: toastBox,
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      },
      (msg) => {
        toast.error(`${msg}`, {
          id: toastBox,
        });
      }
    );
  };

  // const handleUserDeleteModal = (event) => {
  //   const userid = event.target.value;
  //   setShowUserData(userData.filter((element) => element.id === userid));
  //   console.log(userData.filter((element) => element.id === userid));
  //   setShowDeleteModal(true);
  // };

  useEffect(() => {
    const handleButtonChangeFunc = () => {
      if (newUserData.length === 0) {
        setShowChangeButton(false);
      } else {
        setShowChangeButton(true);
      }
    };
    handleButtonChangeFunc();
  }, [newUserData]);

  return (
    <>
      {!userDataFetchedOnce && getAllUserDataFunc()}
      {userDataFetchedOnce ? (
        <>
          <div className="w-full pt-5 mx-auto justify-center md:flex">
            <Card className="h-full md:w-5/6 bg-blue-gray-50">
              <CardBody
                className={"overflow-x-scroll xl:overflow-x-hidden px-0"}
                style={{
                  maxHeight: `calc(100vh - 250px)`,
                  borderRadius: "0px",
                }}
              >
                {/* {showChangeButton
                  ? handleEnableChangeButton()
                  : handleDisableChangeButton()} */}
                <table className="w-full table-auto mx-auto justify-center text-center items-center">
                  <thead>
                    <tr>
                      {TABLE_HEAD.map((head) => (
                        <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 min-w-max">
                          <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70 min-w-max">
                            {head} {head === "Status" || head === "Override" ? <HintHandler headName={head} /> : null}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {userData &&
                      userData.map((data, index) => {
                        const isLast = index === userData.length - 1;
                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                        return (
                          <tr key={data.id}>
                            <td className={classes}>
                              <div className="w-full mx-auto justify-center items-center text-center gap-3 min-w-max">
                                <Typography variant="small" color="blue-gray" className="font-bold">
                                  {data.name}
                                </Typography>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="w-full mx-auto justify-center items-center text-center gap-3 min-w-max">
                                <Typography variant="small" color="blue-gray" className="font-bold">
                                  {data.email}
                                </Typography>
                              </div>
                            </td>
                            <td className={classes}>
                              <ActiveSwitchHandler
                                userData={data}
                                newUserData={newUserData}
                                setNewUserData={(data) => {
                                  if (data) {
                                    const filteredArray2 = data.filter((obj) => userData.some((item) => item.id === obj.id && (item.active !== obj.active || item.override !== obj.override)));
                                    var unique = [];
                                    var distinct = [];
                                    for (let i = 0; i < filteredArray2.length; i++) {
                                      if (!unique[filteredArray2[i].id]) {
                                        distinct.push(filteredArray2[i]);
                                        unique[filteredArray2[i].id] = 1;
                                      }
                                    }
                                    setNewUserData(distinct);
                                    // setNewUserData(filteredArray2);
                                  }
                                }}
                              />
                            </td>
                            <td className={classes}>
                              <OverrideSwitchHandler
                                userData={data}
                                newUserData={newUserData}
                                setNewUserData={(data) => {
                                  if (data) {
                                    const filteredArray2 = data.filter((obj) => userData.some((item) => item.id === obj.id && (item.active !== obj.active || item.override !== obj.override)));
                                    var unique = [];
                                    var distinct = [];
                                    for (let i = 0; i < filteredArray2.length; i++) {
                                      if (!unique[filteredArray2[i].id]) {
                                        distinct.push(filteredArray2[i]);
                                        unique[filteredArray2[i].id] = 1;
                                      }
                                    }
                                    setNewUserData(distinct);
                                  }
                                }}
                              />
                            </td>
                            <td className={classes}>
                              <div className="w-full mx-auto justify-center items-center text-center gap-3 min-w-max">
                                <Typography variant="small" color="blue-gray" className="font-bold">
                                  {data?.connectedWith
                                    ?.split(",")
                                    ?.map((element) => {
                                      if (element === "MT") return "Mariana-Tek";
                                      else if (element === "MB") return "Mindbody";
                                    })
                                    .join(", ")}
                                </Typography>
                              </div>
                            </td>
                            <td className={classes}>
                              <CustomMenuHandler userData={[data]} />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </div>
          <div className="w-11/12 sm:pr-14 pt-5 sm:text-end mx-auto text-center">
            {showChangeButton ? (
              <Button color="blue" onClick={handleUserChangeButton}>
                Save Changes
              </Button>
            ) : (
              <Button color="blue" disabled>
                Save Changes
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="flex place-items-center w-full m-auto justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      )}
    </>
  );
};

export default UserTable;
