import { Alert, Button, Input, InputNumber, Select, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { getMtPartnersList, getMtProductsList } from "../../../../../../helpers/MtApi";
const { Text } = Typography;

const LinkMembershipGhlToMtConnection = ({ connectionData, setConnectionData, isExist }) => {
  const [mtPartners, setMtPartners] = useState([]);
  const [mtProducts, setMtProducts] = useState([]);
  const [dataLoader, setDataLoader] = useState({
    mtPartners: false,
    mtProducts: false,
  });

  useEffect(() => {
    const getMtPartnersAvail = () => {
      const toastBox = toast;
      const getMBAvailableLocationPromise = getMtPartnersList({
        mt_id: connectionData?.mt_id,
      });
      getMBAvailableLocationPromise
        .then(
          (data) => {
            if (data) {
              setMtPartners(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        })
        .finally(() => {
          setDataLoader((prevData) => ({ ...prevData, mtPartners: true }));
        });
    };

    const getMtProductsAvail = () => {
      const toastBox = toast;
      const getMBAvailableLocationPromise = getMtProductsList({
        mt_id: connectionData?.mt_id,
      });
      getMBAvailableLocationPromise
        .then(
          (data) => {
            if (data) {
              setMtProducts(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        })
        .finally(() => {
          setDataLoader((prevData) => ({ ...prevData, mtProducts: true }));
        });
    };
    getMtProductsAvail();
    getMtPartnersAvail();
  }, [connectionData]);

  const handlePartnerSelect = (partnerId) => {
    setConnectionData({ ...connectionData, partnerId: partnerId });
  };

  const handleProductSelect = (value) => {
    setConnectionData({ ...connectionData, productId: value });
  };

  return (
    <>
      {isExist === "true" ? <Alert message="Note: Client must be having card details present in MT to purchase the membership" type="warning" /> : <Alert message="Note: Client must be having card details present in MT to purchase the membership" type="warning" />}
      <div className="grid grid-flow-col mb-6">
        <div className="grid lg:grid-cols-1 grid-cols-1 mt-2 gap-2">
          <div className="min-w-[20rem] text-start lg:text-center flex items-center gap-4 border-b pb-2">
            <Text className="text-base">Select Partner : </Text>
            <Spin spinning={!dataLoader.mtPartners} className="max-w-[12rem] w-full">
              <Select placeholder="Select a partner" className="min-w-[12rem] w-full" defaultValue={connectionData?.partnerId || null} onChange={(e) => handlePartnerSelect(e)} disabled={isExist === "true" ? true : false}>
                {mtPartners.length !== 0 &&
                  mtPartners.map((element) => {
                    return (
                      <Select.Option key={element.id} value={element.id}>
                        {element.attributes.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Spin>
          </div>
          <div className="min-w-[20rem] text-start lg:text-center flex items-center gap-4 pb-2">
            <Text className="text-base">Select Product : </Text>
            <Spin spinning={!dataLoader.mtProducts} className="max-w-[12rem] w-full">
              <Select placeholder="Select a product" className="min-w-[12rem] w-full" defaultValue={connectionData?.productId || null} onChange={(e) => handleProductSelect(e)} disabled={isExist === "true" ? true : false}>
                {mtProducts.length !== 0 &&
                  mtProducts.map((element) => {
                    return (
                      <Select.Option key={element.id} value={element.id}>
                        {element.attributes.title}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Spin>
          </div>

          {/* <div className="grid 3xl:grid-cols-2 grid-cols-1 gap-2 pb-2">
            <div className="min-w-[20rem] text-start lg:text-center flex items-center gap-4">
              <Text className="text-base">Promocode : </Text>
              <Input placeholder="Enter a name" className="max-w-[12rem] w-full" value={connectionData?.promocode || null} onChange={(e) => handlePromocodeChange(e)} disabled={connectionData?.partnerId === "" || connectionData?.partnerId === null ? true : isExist === "true" ? true : false} />
            </div>
            <div className="min-w-[20rem] text-start lg:text-center flex items-center gap-4">
              <Text className="text-base">Discount : </Text>
            
              <InputNumber
                defaultValue={100}
                min={0}
                max={100}
                value={Number(connectionData?.offerPercent) || 0}
                formatter={(value) => `${value}%`}
                parser={(value) => value?.replace("%", "")}
                onChange={(e) => handleDiscountChange(e)}
                disabled={connectionData?.partnerId === "" || connectionData?.partnerId === null ? true : isExist === "true" ? true : false}
              />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default LinkMembershipGhlToMtConnection;
