import React from "react";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Homepage from "./homepage/Homepage";
import Connections from "./connections/Connections";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import Sidebar from "./components/Sidebar";
import { useStateContext } from "../../contexts/ContextProvider";
import Navbar from "./components/Navbar";
import ActivityLog from "./activityLog/ActivityLog";
import RequireAuth from "../../components/RequireAuth";
import ManageUsers from "./users/ManageUsers";
import Webhook from "./webhook/Webhook";
import Profile from "./profile/Profile";
import { Toaster } from "react-hot-toast";

const Dashboard = () => {
  const { activeMenu } = useStateContext();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="flex relative dark:bg-main-dark-bg">
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
        <TooltipComponent content="Settings" position="Top">
          {/* <button type="button" onClick={() => setThemeSettings(true)} style={{ background: currentColor, borderRadius: "50%" }} className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray">
          <FiSettings />
        </button> */}
        </TooltipComponent>
      </div>
      {activeMenu ? (
        <div className="w-64 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
          <Sidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">{<Sidebar />}</div>
      )}
      <div className={activeMenu ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-64 w-full  " : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "}>
        <div className={`${activeMenu ? `fixed md:static` : `static`} bg-main-bg dark:bg-main-dark-bg navbar w-full `}>
          <Navbar />

          <div>
            {/* {themeSettings && (<ThemeSettings />)} */}
            {(location.pathname === "/dashboard" || location.pathname === "/dashboard/") && navigate("/dashboard/overview")}
            <Routes>
              <Route path="overview" element={<Homepage />} />
              <Route element={<RequireAuth allowedRoles={["user"]} />}>
                <Route path="connections" element={<Connections />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={["admin"]} />}>
                <Route path="manageusers" element={<ManageUsers />} />
                <Route path="webhooks" element={<Webhook />} />
              </Route>
              <Route path="settings" element={<Profile />} />
              <Route path="activity" element={<ActivityLog />} />
            </Routes>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Dashboard;
