import { Alert, Button, Divider, Input, InputNumber, Modal, Select, Space, Typography } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { getAllGHLAccountsSpecificUser, getCustomFieldDetails } from "../../../../../../helpers/GhlApi";
const { Text } = Typography;

const ServicesClientUpdate = ({ open, setOpen, connectionData, setConnectionData, selectedGhlAccount }) => {
  const [localUpdatedClientCreatedData, setlocalUpdatedClientCreatedData] = useState(connectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0] ? connectionData?.otherEvents.filter((element) => element.ghl_id === selectedGhlAccount)[0] : null);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSaveButton = () => {
    let letSave = true;
    if (letSave) {
      //  setConnectionData((prevData) => ({ ...prevData, classCalendar: localUpdatedClientCreatedData }))
      setConnectionData((prevData) => {
        let foundMatchingItem = false;

        let updatedClientCreatedData = prevData.otherEvents.map((item) => {
          if (item.ghl_id === selectedGhlAccount) {
            foundMatchingItem = true;
            return {
              ...item,
              clientUpdate: "true",
              ...localUpdatedClientCreatedData,
            };
          }

          return item;
        });

        if (!foundMatchingItem) {
          setlocalUpdatedClientCreatedData({
            ghl_id: selectedGhlAccount,
            clientUpdate: "true",
            ...localUpdatedClientCreatedData,
          });
          updatedClientCreatedData.push({
            ghl_id: selectedGhlAccount,
            clientUpdate: "true",
            ...localUpdatedClientCreatedData,
          });
        }
        return {
          ...prevData,
          otherEvents: updatedClientCreatedData,
        };
      });
    } else toast.error(`Check your selection!`);
  };

  const handleCustomTagsInput = (e) => {
    setlocalUpdatedClientCreatedData({ ...localUpdatedClientCreatedData, contactCreationTags: e.target.value });
  };

  const handleTypeFieldSelect = (customFieldID) => {
    setlocalUpdatedClientCreatedData({ ...localUpdatedClientCreatedData, clientFieldID: customFieldID });
  };

  const handleActiveSelect = () => {
    setlocalUpdatedClientCreatedData((prevData) => {
      return { ...localUpdatedClientCreatedData, clientUpdate: prevData?.clientUpdate === "true" ? "false" : "true" };
    });
  };

  return (
    <Modal
      title={"Manage contact creation event"}
      className="max-w-3xl"
      width={"70vw"}
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Return
        </Button>,
        <Fragment key="save">
          {localUpdatedClientCreatedData && JSON.stringify(localUpdatedClientCreatedData) !== JSON.stringify(connectionData?.otherEvents.filter((element) => element.ghl_id === selectedGhlAccount)[0]) ? (
            <Button key="save" type="default" className="bg-red-300" onClick={handleSaveButton}>
              Save & continue
            </Button>
          ) : (
            <Button key="save" type="default" className="bg-red-300" disabled>
              Save & continue
            </Button>
          )}
        </Fragment>,
      ]}
    >
      <Divider className="my-0.5" />

      <div className="flex justify-between items-center my-auto">
        <div className="pt-2 my-auto">
          <Typography.Title level={5} type="secondary">
            This event will update GHL client details
          </Typography.Title>
        </div>

        <Button key="save" type="dashed" className={`${localUpdatedClientCreatedData?.clientUpdate === "true" ? `bg-green-300` : `bg-gray-100`} `} onClick={() => handleActiveSelect()}>
          {localUpdatedClientCreatedData?.clientUpdate === "true" ? `Active` : `Inactive`}
        </Button>
      </div>

      <Divider className="my-0.5" />
      <div className="w-full">
        {/* <div className="grid lg:grid-cols-2 grid-cols-1 mt-2 gap-4">
          <div className="grid grid-cols-2 mx-auto text-end lg:text-end justify-start ">
            <Text className="grid-cols-1 text-base min-w-fit">Select name field : </Text>
            <Select
              placeholder="Select a field"
              className="grid-cols-1 max-w-[12rem] text-start justify-start ml-4"
              defaultValue={connectionData?.clientSaleEvent?.length !== 0 ? connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.itemNameFieldID : null}
              onChange={(e) => handleNameFieldSelect(e)}
            >
              {customFields.length !== 0 &&
                customFields.map((element) => {
                  return (
                    <Select.Option key={element.id} value={element.id}>
                      {element.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          <div className="grid grid-cols-2 mx-auto text-end lg:text-end justify-start ">
            <Text className="text-base min-w-fit">Select type field : </Text>
            <Select
              placeholder="Select a field"
              className="grid-cols-1 max-w-[12rem] text-start justify-start ml-4"
              defaultValue={connectionData?.clientSaleEvent?.length !== 0 ? connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.itemTypeFieldID : null}
              onChange={(e) => handleTypeFieldSelect(e)}
            >
              {customFields.length !== 0 &&
                customFields.map((element) => {
                  return (
                    <Select.Option key={element.id} value={element.id}>
                      {element.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          <div className="grid grid-cols-2 mx-auto text-end lg:text-end justify-start ">
            <Text className="text-base min-w-fit">
              Delay
              <Text> (for multiple items) :</Text>
            </Text>
            <InputNumber
              className="grid-cols-1 max-w-[12rem] text-start justify-start ml-4"
              defaultValue={connectionData?.clientSaleEvent?.length !== 0 ? connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.delay : 10}
              min={0}
              max={120}
              formatter={(value) => `${value} sec`}
              parser={(value) => value.replace(" sec", "")}
              onChange={onDelayInputChange}
            />
          </div>
        </div> */}
        <div className="mx-auto w-full py-10">
          <Typography.Title className="text-center min-w-fit" type="secondary" level={5}>
            No other customizations available
          </Typography.Title>
        </div>
      </div>
    </Modal>
  );
};

export default ServicesClientUpdate;
