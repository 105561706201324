import React, { useContext, useState } from "react";

import { Menu, MenuHandler, MenuList, MenuItem, IconButton } from "@material-tailwind/react";
import { FiMoreHorizontal } from "react-icons/fi";
import ShowUserDataModal from "./ShowUserDataModal";
import DeleteUserConfirmModal from "./DeleteUserConfirmModal";
import EditUserModal from "./EditUserModal";
import { subAccountLoginUser } from "../../../../helpers/AppUserApi";
import { toast } from "react-hot-toast";
import AuthContext from "../../../../contexts/AuthContextProvider";

const CustomMenuHandler = ({ userData }) => {
  const [showUserModal, setShowUserModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const { auth, setAuth } = useContext(AuthContext);

  const switchAccountHandler = (data) => {
    //console.log(data);
    const bodyData = {
      email: data.email,
    };
    let toastBox = toast;
    const subAccountLoginPromise = subAccountLoginUser(bodyData);
    subAccountLoginPromise
      .then(
        (data) => {
          const userActive = data?.active;
          const userType = Array.isArray(data?.usertype) ? data?.usertype : [data?.usertype];
          const subLogin = data?.subLogin || false;
          const subEmail = data?.email;
          setAuth({ userType, userActive, subLogin, subEmail });
          window.location.replace("/dashboard");
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  return (
    <Menu>
      <ShowUserDataModal open={showUserModal} setOpen={(bool) => setShowUserModal(bool)} userData={userData} />
      <DeleteUserConfirmModal open={showDeleteModal} setOpen={(bool) => setShowDeleteModal(bool)} userData={userData} />
      <EditUserModal open={editUserModal} setOpen={(bool) => setEditUserModal(bool)} userData={userData} />
      <MenuHandler>
        <IconButton variant="text" color="gray" className="text-black">
          {" "}
          <FiMoreHorizontal size={20} />{" "}
        </IconButton>
      </MenuHandler>
      <MenuList>
        <MenuItem
          className="mx-auto text-center"
          onClick={() => {
            setShowUserModal(true);
          }}
        >
          Show usage
        </MenuItem>
        <hr className="my-2 border-blue-gray-50" />
        <MenuItem
          className="mx-auto  text-center"
          onClick={() => {
            switchAccountHandler(userData[0]);
          }}
        >
          switch account
        </MenuItem>
        <MenuItem
          className="mx-auto text-center focus:bg-amber-800 focus:text-white"
          onClick={() => {
            setEditUserModal(true);
          }}
        >
          edit details
        </MenuItem>
        <MenuItem
          className="mx-auto focus:bg-red-600 focus:text-white text-center text-red-500"
          onClick={() => {
            setShowDeleteModal(true);
          }}
        >
          delete account
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default CustomMenuHandler;
