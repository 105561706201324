import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { MdDashboard, MdOutlineCancel, MdWebhook } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import synxLogo from "../../../data/images/synx_logo.png";
import { TiFlowMerge } from "react-icons/ti";
import { AiOutlineApi } from "react-icons/ai";
import { IoIosLogOut, IoMdContacts } from "react-icons/io";
import { HiOutlineDatabase } from "react-icons/hi";
import { IoLogOut, IoSettingsSharp } from "react-icons/io5";
import { useStateContext } from "../../../contexts/ContextProvider";
import { logoutUser, showTotalApiUsageStatistics } from "../../../helpers/AppUserApi";
import AuthContext from "../../../contexts/AuthContextProvider";
import AddMindbodyConnection from "../connections/subComponents/connectionAddition/AddMindbodyConnection";
import AddGHLContactConnection from "../connections/subComponents/connectionAddition/AddGHLContactConnection";
import AddGHLClassConnection from "../connections/subComponents/connectionAddition/AddGHLClassConnection";
import AddGHLAppointmentConnection from "../connections/subComponents/connectionAddition/AddGHLAppointmentConnection";
import { ConfigProvider, Dropdown, Popover, Typography } from "antd";
import AddGHLMembershipConnection from "../connections/subComponents/connectionAddition/AddGHLMembershipConnection";
import AddGHLToMtContactConnection from "../connections/subComponents/connectionAddition/AddGHLToMtContactConnection";
import AddGHLToMtClassConnection from "../connections/subComponents/connectionAddition/AddGHLToMtClassConnection";
import AddGHLToMtMembershipConnection from "../connections/subComponents/connectionAddition/AddGHLToMtMembershipConnection";
import AddMarianatekConnection from "../connections/subComponents/connectionAddition/AddMarianatekConnection";

const Sidebar = () => {
  const { activeMenu, setActiveMenu, screenSize } = useStateContext();
  const { auth, setAuth } = useContext(AuthContext);
  const isAdmin = auth?.userType?.includes("admin");
  const connectedWith = auth?.connectedWith;
  console.log(connectedWith);
  const [connectionStatsData, setConnectionStatsData] = useState(false);
  const handleAddConnectionButton = () => {};

  const handleLogoutButton = async () => {
    try {
      const logoutResult = await logoutUser();
      setAuth({});
    } catch (error) {
      console.log(error);
    }
  };

  const links = [
    {
      title: "Dashboard",
      links: [
        {
          name: "overview",
          nav: "overview",
          icon: <MdDashboard />,
        },
        // {
        //   name: "configuration",
        //   nav: "configuration",
        //   icon: <FiShoppingBag />,
        // },
      ],
    },
    {
      title: "Connection",
      links: [
        !isAdmin && {
          name: "view connections",
          nav: "connections",
          icon: <HiOutlineDatabase />,
        },
        !isAdmin && {
          name: "add connection",
          type: "button",
          handler: handleAddConnectionButton,
          icon: <AiOutlineApi />,
        },
        isAdmin && {
          name: "webhooks",
          nav: "webhooks",
          icon: <MdWebhook />,
        },
        {
          name: "activity log",
          nav: "activity",
          icon: <TiFlowMerge />,
        },
      ].filter(Boolean),
    },
    isAdmin && {
      title: "Manage users",
      links: [
        {
          name: "users",
          nav: "manageusers",
          icon: <IoMdContacts />,
        },
      ],
    },
    {
      title: "Profile",
      links: [
        {
          name: "settings",
          nav: "settings",
          icon: <IoSettingsSharp />,
        },
        {
          name: "logout",
          type: "button",
          icon: <IoLogOut />,
          handler: handleLogoutButton,
        },
      ],
    },
  ].filter(Boolean);

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const [mindbodyConnectionModal, setMindbodyConnectionModal] = useState(false);
  const [marianatekConnectionModal, setMarianatekConnectionModal] = useState(false);
  const [ghlContactConnectionModal, setGhlContactConnectionModal] = useState(false);
  const [ghlClassConnectionModal, setGhlClassConnectionModal] = useState(false);
  const [ghlAppointmentConnectionModal, setGhlAppointmentConnectionModal] = useState(false);
  const [ghlMembershipConnectionModal, setGhlMembershipConnectionModal] = useState(false);
  const [ghlToMtContactConnectionModal, setGhlToMtContactConnectionModal] = useState(false);
  const [ghlToMtClassBookingConnectionModal, setGhlToMtClassBookingConnectionModal] = useState(false);
  const [ghlToMtMembershipConnectionModal, setGhlToMtMembershipConnectionModal] = useState(false);

  let items = [];

  const mindbodySidebar = {
    type: "group",
    label: "GHL & Mindbody",
    children: [
      {
        key: "1-1",
        label: "GoHighLevel to Mindbody",
        children: [
          {
            key: "1-1-1",
            label: "Contact creation",
            onClick: () => {
              setGhlContactConnectionModal(true);
            },
          },
          {
            key: "1-1-2",
            label: "Manage classes",
            onClick: () => {
              setGhlClassConnectionModal(true);
            },
          },
          {
            key: "1-1-3",
            label: "Manage appointments",
            onClick: () => {
              setGhlAppointmentConnectionModal(true);
            },
          },
          {
            key: "1-1-4",
            label: "Purchase membership",
            onClick: () => {
              setGhlMembershipConnectionModal(true);
            },
          },
        ],
      },
      {
        key: "1-2",
        label: "Mindbody to GoHighLevel",
        onClick: () => {
          setMindbodyConnectionModal(true);
        },
      },
    ],
  };

  const marianatekSidebar = {
    type: "group",
    label: "GHL & MarianaTek",
    children: [
      {
        key: "2-1",
        label: "GoHighLevel to MarianaTek",
        children: [
          {
            key: "2-1-1",
            label: "Contact creation",
            onClick: () => {
              setGhlToMtContactConnectionModal(true);
            },
          },
          {
            key: "2-1-2",
            label: "Manage classes",
            onClick: () => {
              setGhlToMtClassBookingConnectionModal(true);
            },
          },
          {
            key: "2-1-3",
            label: "Purchase membership",
            onClick: () => {
              setGhlToMtMembershipConnectionModal(true);
            },
          },
        ],
      },
      {
        key: "2-2",
        label: "MarianaTek to GoHighLevel",
        onClick: () => {
          setMarianatekConnectionModal(true);
        },
      },
    ],
  };

  // Assuming connectedWith is an array of connection options like ['MT', 'MB']
  console.log(connectedWith);

  // Conditionally add items based on connections
  if (connectedWith?.includes("MT")) {
    items = items.concat(marianatekSidebar);
  }

  if (connectedWith?.includes("MB")) {
    items = items.concat(mindbodySidebar);
  }

  console.log(items);

  // Ensure each item has a unique key
  items.forEach((element, index) => {
    element.key = `sidebar-item-${index}`;
  });

  const activeLink = "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-black font-bold text-base m-2";
  const normalLink = "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-base text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  useEffect(() => {
    const showTotalApiUsageStatisticsFunc = async () => {
      try {
        const result = await showTotalApiUsageStatistics();
        // const ghlApiUsage = result?.ghlApiUsage || 0;
        // const mbApiUsage = result?.mbApiUsage || 0;
        const ghlApiUsage = result.reduce((count, item) => {
          return count + Number(item?.ghlApiUsage);
        }, 0);

        const mbApiUsage = result.reduce((count, item) => {
          return count + Number(item?.mbApiUsage);
        }, 0);

        const totalUsage = ghlApiUsage + mbApiUsage;

        setConnectionStatsData({ totalUsage, ghlApiUsage, mbApiUsage });
      } catch (error) {
        console.log(error);
      }
    };
    showTotalApiUsageStatisticsFunc();
  }, []);

  return (
    <div className="flex flex-col h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      <AddMindbodyConnection open={mindbodyConnectionModal} setOpen={(bool) => setMindbodyConnectionModal(bool)} />
      <AddMarianatekConnection open={marianatekConnectionModal} setOpen={(bool) => setMarianatekConnectionModal(bool)} />
      <AddGHLContactConnection open={ghlContactConnectionModal} setOpen={(bool) => setGhlContactConnectionModal(bool)} />
      <AddGHLClassConnection open={ghlClassConnectionModal} setOpen={(bool) => setGhlClassConnectionModal(bool)} />
      <AddGHLAppointmentConnection open={ghlAppointmentConnectionModal} setOpen={(bool) => setGhlAppointmentConnectionModal(bool)} />
      <AddGHLMembershipConnection open={ghlMembershipConnectionModal} setOpen={(bool) => setGhlMembershipConnectionModal(bool)} />
      <AddGHLToMtContactConnection open={ghlToMtContactConnectionModal} setOpen={(bool) => setGhlToMtContactConnectionModal(bool)} />
      <AddGHLToMtClassConnection open={ghlToMtClassBookingConnectionModal} setOpen={(bool) => setGhlToMtClassBookingConnectionModal(bool)} />
      <AddGHLToMtMembershipConnection open={ghlToMtMembershipConnectionModal} setOpen={(bool) => setGhlToMtMembershipConnectionModal(bool)} />
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link to="/dashboard" onClick={handleCloseSideBar} className="w-5/6  items-center mx-auto mt-4 flex text-xl font-extrabold tracking-tight border-b pb-3 border-slate-300 dark:text-white text-slate-900">
              <img src={synxLogo} width={130} alt="logo" className="mx-auto" />
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                // style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className="mt-10 ml-3 flex-grow">
            {links.map((item) => (
              <div key={item.title}>
                <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">{item.title}</p>
                {item.links.map((link) => (
                  <Fragment key={link.name}>
                    {link?.type !== "button" ? (
                      <NavLink
                        to={`${link.nav}`}
                        onClick={handleCloseSideBar}
                        // style={({ isActive }) => ({
                        //   backgroundColor: isActive ? currentColor : "",
                        // })}
                        className={({ isActive }) => (isActive ? activeLink : normalLink)}
                      >
                        {link.icon}
                        <span className="capitalize ">{link.name}</span>
                      </NavLink>
                    ) : (
                      <>
                        {link?.name === "add connection" ? (
                          <Dropdown
                            menu={{
                              items,
                            }}
                            trigger={["click"]}
                          >
                            <NavLink
                              // style={({ isActive }) => ({
                              //   backgroundColor: isActive ? currentColor : "",
                              // })}
                              className={normalLink}
                            >
                              {link.icon}
                              <span className="capitalize ">{link.name}</span>
                            </NavLink>
                          </Dropdown>
                        ) : (
                          <NavLink
                            onClick={link?.handler}
                            // style={({ isActive }) => ({
                            //   backgroundColor: isActive ? currentColor : "",
                            // })}
                            className={normalLink}
                          >
                            {link.icon}
                            <span className="capitalize ">{link.name}</span>
                          </NavLink>
                        )}
                      </>
                    )}
                  </Fragment>
                ))}
              </div>
            ))}
          </div>
          <div className="mt-auto mx-3 mb-5 border-t border-slate-300">
            <div
              // type="button"
              onClick={() => {
                // Handle button click logic here
              }}
            >
              <Typography.Title level={5} className="mt-2">
                TOTAL API USAGE
              </Typography.Title>
              <ConfigProvider
                theme={{
                  components: {
                    Popover: {
                      titleMinWidth: 250,
                    },
                  },
                }}
              >
                <Popover
                  content={
                    <div className="border-t border-slate-300">
                      <Typography.Title level={5} className="mt-2">
                        Go High Level :
                      </Typography.Title>
                      <div className="w-full mt-2 text-white rounded-md py-0 text-center px-4 text-xs bg-gradient-to-r from-purple-200 to-purple-400 hover:from-purple-400 hover:to-purple-500 focus:outline-none focus:ring focus:border-blue-300">{connectionStatsData?.ghlApiUsage || 0}</div>

                      <Typography.Title level={5} className="mt-2">
                        {connectedWith?.includes("MT") ? "Marianatek" : "Mindbody"} :
                      </Typography.Title>
                      <div className="w-full mt-2 text-white rounded-md py-0 text-center px-4 text-xs bg-gradient-to-r from-cyan-400 to-blue-400 hover:from-cyan-500 hover:to-blue-600 focus:outline-none focus:ring focus:border-blue-300">{connectionStatsData?.mbApiUsage || 0}</div>
                    </div>
                  }
                  title="API Usage details"
                  trigger="hover"
                  placement="rightBottom"
                >
                  <div className="w-full mt-4 text-white rounded-md py-0 text-center px-4 text-xs bg-gradient-to-r from-yellow-400 to-orange-400 hover:from-yellow-500 hover:to-orange-600 focus:outline-none focus:ring focus:border-blue-300">{connectionStatsData?.totalUsage || 0}</div>
                </Popover>
              </ConfigProvider>
              {/* <div className="w-full mt-4 bg-blue-500 text-white rounded-md text-center py-0 px-4 text-sm hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300">10</div>

              <div className="w-full mt-4 bg-orange-500 text-white rounded-md py-0 text-center px-4 text-sm hover:bg-orange-600 focus:outline-none focus:ring focus:border-blue-300">10</div>
              <div className="w-full mt-4 bg-cyan-500 text-white rounded-md py-0 text-center px-4 text-sm hover:bg-cyan-600 focus:outline-none focus:ring focus:border-blue-300">20</div> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
