import React, { useState } from "react";
import { Button } from "@material-tailwind/react";
import AddNewUserModal from "./subComponents/AddNewUserModal";
import UserTable from "./subComponents/UserTable";

const ManageUsers = () => {
  const [addUserModal, setAddUserModal] = useState(false);

  const handleAddNewUserModal = (e) => {
    setAddUserModal(true);
  };

  return (
    <div className="md:w-full w-11/12">
      <AddNewUserModal
        open={addUserModal}
        setOpen={(bool) => setAddUserModal(bool)}
      />
      <div className="w-full sm:pl-14 pt-10 sm:text-start mx-auto text-center">
        <h1 className="text-2xl">Manage users</h1>
      </div>
      <div className="w-11/12 mt-2.5 mx-auto justify-center">
        <hr />
      </div>
      <div className="w-full sm:pl-14 pt-5 sm:text-start mx-auto text-center">
        <Button color="green" onClick={handleAddNewUserModal}>
          Add New User
        </Button>
      </div>
        <UserTable />
    </div>
  );
};

export default ManageUsers;
