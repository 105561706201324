import { Switch } from "@material-tailwind/react";
import React, { useState } from "react";

const ActiveSwitchHandler = ({ userData, newUserData, setNewUserData }) => {
  const [localUserData, setLocalUserData] = useState(userData);

  const handleChange = (val) => {
    let localArray = [localUserData];
    let normalArray = newUserData;
    let tempArray = localArray.filter((obj) => {
      if (newUserData.length !== 0) {
        return newUserData.some((item) => {
          return item.id !== obj.id;
        });
      }
      return obj;
    })[0];

    if (tempArray) {
      normalArray = [...normalArray, tempArray];
    } else {
      normalArray = [...normalArray];
    }

    const updatedArray = normalArray.map((obj) => {
      if (obj.id === localUserData.id) {
        return { ...obj, active: val };
      }
      return obj;
    });
    setLocalUserData({ ...localUserData, active: val });
    if (updatedArray) setNewUserData(updatedArray);
  };

  return (
    <div className="w-full mx-auto justify-center">
      <div className="max-w-max mx-auto justify-center text-start items-start">
        {userData.active === "true" ? (
          <Switch
            id={localUserData.email}
            color="green"
            defaultChecked
            onChange={() => {
              setNewUserData(localUserData.active === "true" ? handleChange("false") : handleChange("true"));
            }}
          />
        ) : (
          <Switch
            id={userData.email}
            color="green"
            onChange={() => {
              setNewUserData(localUserData.active === "true" ? handleChange("false") : handleChange("true"));
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ActiveSwitchHandler;
