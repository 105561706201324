import React, { useEffect, useState } from "react";
import { Input, Select, Spin, Typography } from "antd";
import { getGHLAvailAccounts, getMBContactAvailAccounts } from "../../../../../../helpers/AppUserApi";
import { toast } from "react-hot-toast";
const { Option } = Select;

const LinkAccountGhlConnection = ({ data, setData }) => {
  const [ghlAvailAccounts, setGhlAvailAccounts] = useState([]);
  const [mbAvailAccounts, setMbAvailAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataLoader, setDataLoader] = useState({
    ghlAccounts: false,
    mbAccounts: false,
  });

  const handleGhlAccountSelect = (e) => {
    setData({ ...data, ghl_id: e });
  };

  const handleMBAccountSelect = (e) => {
    const apikey = e.split(",")[0];
    const siteid = e.split(",")[1];
    setData({ ...data, mb_apikey: apikey, mb_siteid: siteid });
  };

  const getGHLContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getGHLAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setGhlAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, ghlAccounts: true }));
      });
  };

  const getMBContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getMBContactAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setMbAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, mbAccounts: true }));
      });
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setData((prevData) => ({
      ...prevData,
      connectionName: value,
    }));
  };

  useEffect(() => {
    getGHLContactAvailAccFunc();
    getMBContactAvailAccFunc();
  }, []);

  useEffect(() => {
    if (dataLoader.ghlAccounts && dataLoader.mbAccounts) setLoading(false);
  }, [dataLoader]);

  return (
    <Spin spinning={loading} className="my-6">
      <div className="w-10/12 mx-auto mt-2">
        <Input addonBefore="Connection name" onChange={handleInputChange} value={data?.connectionName} placeholder="Enter Name" className="max-w-lg mb-4" />
        <div>
          <Typography.Text>GoHighLevel account : </Typography.Text>
          <Select placeholder="Select an account" className="ml-2 lg:max-w-xs lg:w-1/2 xl:w-full" onChange={handleGhlAccountSelect} defaultValue={data?.ghl_id !== "" ? data?.ghl_id : null}>
            {ghlAvailAccounts.length !== 0 &&
              ghlAvailAccounts.map((data, index) => {
                return (
                  <Option key={index} value={data.id}>
                    {data.name}
                  </Option>
                );
              })}
          </Select>
        </div>

        <div className="mt-4">
          <Typography.Text>Mindbody account : </Typography.Text>
          <Select placeholder="Select an account" className="ml-6 lg:max-w-xs lg:w-1/2 xl:w-full" onChange={handleMBAccountSelect} defaultValue={data?.mb_apikey !== "" ? `${data.mb_apikey},${data.mb_siteid}` : null}>
            {mbAvailAccounts.length !== 0 &&
              mbAvailAccounts.map((data, index) => {
                return (
                  <Option key={index} value={`${data.apikey},${data.siteid}`}>
                    {data?.name}
                  </Option>
                );
              })}
          </Select>
        </div>
      </div>
    </Spin>
  );
};
export default LinkAccountGhlConnection;
