import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Card, Typography, Input, CardBody, Select, Option, Alert } from "@material-tailwind/react";
import { useFormik } from "formik";
import { activeUser, getGHLAvailAccounts, getMBContactAvailAccounts } from "../../../../../helpers/AppUserApi";
import { toast } from "react-hot-toast";
import { useMediaQuery } from "react-responsive";

const SyncModal = ({ open, setOpen, handleSyncSubmit }) => {
  const [userId, setUserId] = useState(null);

  const [ghlAvailAccounts, setGhlAvailAccounts] = useState([]);
  const [mbAvailAccounts, setMbAvailAccounts] = useState([]);
  const [contactGhlAcc, setContactGhlAcc] = useState(null);
  const [contactMbAcc, setContactMbAcc] = useState(null);
  const [mbSelectValue, setMbSelectValue] = useState(null);
  const [transferLimit, setTransferLimit] = useState("all");
  const [contactMbAccSiteid, setContactMbAccSiteid] = useState(null);

  const isMobileScreen = useMediaQuery({ query: "(max-width: 1045px)" });
  const isMobileScreenShorter = useMediaQuery({ query: "(max-width: 487px)" });
  const excessModalHeight = useMediaQuery({ query: "(min-height: 600px)" });

  if (open) {
    let toastBox = toast;
    const activeUserPromise = activeUser();
    activeUserPromise
      .then((data) => {
        if (data.type === "admin") {
        } else if (data.type === "user") {
          setUserId(data.userid);
        }
      })
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  }

  const formik = useFormik({
    initialValues: {},
    validate: false,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      handleSyncSubmit(contactMbAcc, contactMbAccSiteid, contactGhlAcc, transferLimit);
    },
  });

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleConfirmButton = () => {
    setOpen(!open);
    formik.handleSubmit();
  };

  const handleMbContactAcc = (e) => {
    const apikey = e.split(",")[0];
    const siteid = e.split(",")[1];
    setContactMbAcc(apikey);
    setContactMbAccSiteid(siteid);
    setMbSelectValue(e);
  };

  const handleTransferLimit = (e) => {
    setTransferLimit(e);
  };

  const handleGhlContactAcc = (e) => {
    setContactGhlAcc(e);
  };

  const getMBContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getMBContactAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setMbAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const getGHLContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getGHLAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setGhlAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  useEffect(() => {
    getGHLContactAvailAccFunc();
    getMBContactAvailAccFunc();
  }, []);

  return (
    <Fragment>
      <Dialog open={open} handler={handleOpen} size={isMobileScreen ? (isMobileScreenShorter ? "xxl" : "xl") : "md"} className={!excessModalHeight ? "overflow-y-scroll" : "overflow-y-visible"} style={{ maxHeight: "90vh" }}>
        <DialogHeader>Sync your accounts</DialogHeader>
        <DialogBody divider>
          <Card className="w-96 mx-auto justify-center loginCard">
            <form>
              <div className="mx-auto text-center w-11/12">
                <div className="w-full mx-auto mt-4 text-sm text-red-500 text-center border-red-500 border-2 rounded-md p-2">⚠️Warning! Changes made will be permanent. This will add contacts from mindbody to GoHighLevel.</div>
              </div>

              {open && (
                <CardBody className="flex flex-col gap-4">
                  {ghlAvailAccounts.length !== 0 && (
                    <Select variant="outlined" label="Select GoHighLevel account" onChange={handleGhlContactAcc} value={contactGhlAcc}>
                      {ghlAvailAccounts.map((data, index) => {
                        return (
                          <Option key={index} value={data.apikey}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  {mbAvailAccounts.length !== 0 && (
                    <Select variant="outlined" label="Select MindBody account" onChange={handleMbContactAcc} value={mbSelectValue}>
                      {mbAvailAccounts.map((data, index) => {
                        return (
                          <Option key={index} value={`${data.apikey},${data.siteid}`}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  <Select variant="outlined" label="Transfer limit" onChange={handleTransferLimit} value={transferLimit}>
                    <Option value="all">No limit</Option>
                    <Option value="10">10</Option>
                    <Option value="20">20</Option>
                    <Option value="50">50</Option>
                    <Option value="100">100</Option>
                    <Option value="500">500</Option>
                    <Option value="1000">1000</Option>
                  </Select>
                </CardBody>
              )}
            </form>
          </Card>
        </DialogBody>
        <DialogFooter>
          <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="green" onClick={handleConfirmButton}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default SyncModal;
