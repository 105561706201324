import { Alert, Button, Checkbox, Select, Spin, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { getMBAppointmentAvailableCalendar, getMBAvailableLocation, getMBAvailableSession } from "../../../../../../helpers/MbApi";
import { getMindbodyProgramDetails, getMindbodyStaffDetails } from "../../../../../../helpers/AppUserApi";
const { Text } = Typography;

const LinkAppointmentCalendarGhlConnection = ({ connectionData, setConnectionData }) => {
  const [calendarAvailDetails, setCalendarAvailDetails] = useState([]);
  const [locationAvailDetails, setLocationAvailDetails] = useState([]);
  const [sessionAvailDetails, setSessionAvailDetails] = useState([]);
  const [programDetails, setProgramDetails] = useState([]);
  const [staffDetails, setStaffDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataLoader, setDataLoader] = useState({
    mbLocations: false,
    mbSessions: false,
    ghlCalendar: false,
    mbProgram: false,
    mbStaff: false,
  });

  useEffect(() => {
    const getMindbodyCalendarAvail = () => {
      const toastBox = toast;
      const getLocationPromise = getMBAppointmentAvailableCalendar({
        ghl_id: connectionData?.ghl_id,
      });
      getLocationPromise
        .then(
          (data) => {
            if (data) {
              setCalendarAvailDetails(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        })
        .finally(() => {
          setDataLoader((prevData) => ({ ...prevData, ghlCalendar: true }));
        });
    };

    const getMindbodyLocationAvail = () => {
      const toastBox = toast;
      const getMBAvailableLocationPromise = getMBAvailableLocation({
        mb_apikey: connectionData?.mb_apikey,
        mb_siteid: connectionData?.mb_siteid,
      });
      getMBAvailableLocationPromise
        .then(
          (data) => {
            if (data) {
              setLocationAvailDetails(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        })
        .finally(() => {
          setDataLoader((prevData) => ({ ...prevData, mbLocations: true }));
        });
    };

    const getMindbodySessionAvail = () => {
      const toastBox = toast;
      const getMBAvailableSessionPromise = getMBAvailableSession({
        mb_apikey: connectionData?.mb_apikey,
        mb_siteid: connectionData?.mb_siteid,
      });
      getMBAvailableSessionPromise
        .then(
          (data) => {
            if (data) {
              setSessionAvailDetails(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        })
        .finally(() => {
          setDataLoader((prevData) => ({ ...prevData, mbSessions: true }));
        });
    };
    getMindbodyCalendarAvail();
    getMindbodyLocationAvail();
    getMindbodySessionAvail();
  }, [connectionData]);

  useEffect(() => {
    const getProgramDetailsFunc = async () => {
      const toastBox = toast;
      try {
        const getMindbodyProgramDetailsResult = await getMindbodyProgramDetails({
          mb_apikey: connectionData?.mb_apikey,
          mb_siteid: connectionData?.mb_siteid,
        });
        setProgramDetails(getMindbodyProgramDetailsResult?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setDataLoader((prevData) => ({ ...prevData, mbProgram: true }));
      }
    };

    const getStaffDetailsFunc = async () => {
      const toastBox = toast;
      try {
        const getMindbodyProgramDetailsResult = await getMindbodyStaffDetails({
          mb_apikey: connectionData?.mb_apikey,
          mb_siteid: connectionData?.mb_siteid,
        });
        setStaffDetails(getMindbodyProgramDetailsResult?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setDataLoader((prevData) => ({ ...prevData, mbProgram: true }));
      }
    };
    getStaffDetailsFunc();
    getProgramDetailsFunc();
  }, [connectionData]);

  useEffect(() => {
    if (dataLoader.ghlCalendar && dataLoader.mbLocations && dataLoader.mbSessions) {
      setLoading(false);
    } else setLoading(true);
  }, [dataLoader]);

  const handleConnectionRemove = (index) => {
    setConnectionData((prevConnectionData) => {
      const updatedAppointmentCalendar = [...prevConnectionData.appointmentCalendar];
      const updatedObjects = updatedAppointmentCalendar.filter((obj) => obj.index !== index);
      return {
        ...prevConnectionData,
        appointmentCalendar: updatedObjects,
      };
    });
  };

  const handleCalendarSelect = (calendarId, index) => {
    setConnectionData((prevConnectionData) => {
      const updatedAppointmentCalendar = [...prevConnectionData.appointmentCalendar];

      const objectIndex = updatedAppointmentCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedAppointmentCalendar[objectIndex],
          calendarId: calendarId,
        };

        updatedAppointmentCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        appointmentCalendar: updatedAppointmentCalendar,
      };
    });
  };

  const handleLocationSelect = (locationId, index) => {
    setConnectionData((prevConnectionData) => {
      const updatedAppointmentCalendar = [...prevConnectionData.appointmentCalendar];

      const objectIndex = updatedAppointmentCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedAppointmentCalendar[objectIndex],
          locationId: locationId,
        };

        updatedAppointmentCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        appointmentCalendar: updatedAppointmentCalendar,
      };
    });
  };

  const handleSessionSelect = (sessionId, index) => {
    setConnectionData((prevConnectionData) => {
      const updatedAppointmentCalendar = [...prevConnectionData.appointmentCalendar];

      const objectIndex = updatedAppointmentCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedAppointmentCalendar[objectIndex],
          sessionId: sessionId,
        };

        updatedAppointmentCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        appointmentCalendar: updatedAppointmentCalendar,
      };
    });
  };

  const handleProgramSelect = (programId, index) => {
    setConnectionData((prevConnectionData) => {
      const updatedAppointmentCalendar = [...prevConnectionData.appointmentCalendar];

      const objectIndex = updatedAppointmentCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedAppointmentCalendar[objectIndex],
          programId: programId,
        };

        updatedAppointmentCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        appointmentCalendar: updatedAppointmentCalendar,
      };
    });
  };

  const handleStaffSelect = (staffId, index) => {
    setConnectionData((prevConnectionData) => {
      const updatedAppointmentCalendar = [...prevConnectionData.appointmentCalendar];

      const objectIndex = updatedAppointmentCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedAppointmentCalendar[objectIndex],
          staffId: staffId,
        };

        updatedAppointmentCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        appointmentCalendar: updatedAppointmentCalendar,
      };
    });
  };

  const handleAppointmentBookingSelect = (index) => {
    const bookingCurrentStatus = connectionData?.appointmentCalendar.filter((element) => element.index === index)[0]?.bookingActive;

    setConnectionData((prevConnectionData) => {
      const updatedAppointmentCalendar = [...prevConnectionData.appointmentCalendar];

      const objectIndex = updatedAppointmentCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedAppointmentCalendar[objectIndex],
          bookingActive: bookingCurrentStatus === "true" ? "false" : "true",
        };

        updatedAppointmentCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        appointmentCalendar: updatedAppointmentCalendar,
      };
    });
  };

  const handleAppointmentCancellingSelect = (index) => {
    const cancelCurrentStatus = connectionData?.appointmentCalendar.filter((element) => element.index === index)[0]?.cancelActive;

    setConnectionData((prevConnectionData) => {
      const updatedAppointmentCalendar = [...prevConnectionData.appointmentCalendar];

      const objectIndex = updatedAppointmentCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedAppointmentCalendar[objectIndex],
          cancelActive: cancelCurrentStatus === "true" ? "false" : "true",
        };

        updatedAppointmentCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        appointmentCalendar: updatedAppointmentCalendar,
      };
    });
  };

  const onForcefulBookingChange = (index) => {
    const forcefulBookingStatus = connectionData?.appointmentCalendar.filter((element) => element.index === index)[0]?.forcefulBooking;

    setConnectionData((prevConnectionData) => {
      const updatedAppointmentCalendar = [...prevConnectionData.appointmentCalendar];

      const objectIndex = updatedAppointmentCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedAppointmentCalendar[objectIndex],
          forcefulBooking: forcefulBookingStatus === "true" ? "false" : "true",
        };

        updatedAppointmentCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        appointmentCalendar: updatedAppointmentCalendar,
      };
    });
  };

  return (
    <>
      <Spin spinning={loading} className="my-6">
        <Alert message="Note: Both appointment booking and cancellation share this connection" type="warning" />
        {connectionData?.appointmentCalendar.length !== 0 &&
          connectionData?.appointmentCalendar.map(({ index, calendarId, name, sessionId, locationId, programId, staffId }) => {
            return (
              <div key={index} className="grid grid-flow-col mb-6">
                <div className="grid lg:grid-cols-2 grid-cols-1 mt-2 gap-2">
                  <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                    <Text className="text-base">Select Calendar : </Text>
                    <Select placeholder="Select a calendar" className="max-w-[12rem] w-full" defaultValue={calendarId ? calendarId : null} onChange={(e) => handleCalendarSelect(e, index)}>
                      {calendarAvailDetails.length !== 0 &&
                        calendarAvailDetails.map((element) => {
                          return (
                            <Select.Option key={element.id} value={element.id}>
                              {element.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </div>
                  <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                    <Text className="text-base">Select location : </Text>
                    <Select placeholder="Select a location" className="max-w-[12rem] w-full" defaultValue={locationId ? locationId : null} onChange={(e) => handleLocationSelect(e, index)}>
                      {locationAvailDetails.length !== 0 &&
                        locationAvailDetails.map((element) => {
                          return (
                            <Select.Option key={element.locationId} value={element.locationId}>
                              {element.locationName}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </div>
                  <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                    <Text className="text-base">Select session : </Text>
                    <Select placeholder="Select a session" className="max-w-[12rem] w-full" defaultValue={sessionId ? sessionId : null} onChange={(e) => handleSessionSelect(e, index)}>
                      {sessionAvailDetails.length !== 0 &&
                        sessionAvailDetails.map((element) => {
                          return (
                            <Select.Option key={element.sessionId} value={element.sessionId}>
                              {element.sessionName}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </div>
                  <div className=" pr-2">
                    <Checkbox checked={connectionData?.appointmentCalendar.filter((element) => element.index === index)[0]?.forcefulBooking === "true"} onChange={() => onForcefulBookingChange(index)}>
                      Forceful-booking?
                    </Checkbox>
                  </div>
                  {connectionData?.appointmentCalendar.filter((element) => element.index === index)[0]?.forcefulBooking === "true" && (
                    <>
                      <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                        <Typography.Text className="text-base">Select program : </Typography.Text>
                        <Select placeholder="Select a program" className="max-w-[12rem] w-full" defaultValue={programId ? programId : null} onChange={(e) => handleProgramSelect(e, index)}>
                          {programDetails.length !== 0 &&
                            programDetails.map((element) => {
                              return (
                                <Select.Option key={element.Id} value={`${element.Id}`}>
                                  {element.Name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                        <Typography.Text className="text-base">Select staff : </Typography.Text>
                        <Select placeholder="Select a staff" className="max-w-[12rem] w-full" defaultValue={staffId ? staffId : null} onChange={(e) => handleStaffSelect(e, index)}>
                          {staffDetails.length !== 0 &&
                            staffDetails.map((element) => {
                              return (
                                <Select.Option key={element.Id} value={`${element.Id}`}>
                                  {element.DisplayName}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </div>
                    </>
                  )}
                  <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                    <Button type="dashed" className={connectionData?.appointmentCalendar.filter((element) => element.index === index)[0]?.bookingActive === "true" ? "w-11/12 bg-green-300" : "w-11/12"} onClick={() => handleAppointmentBookingSelect(index)}>
                      Booking
                    </Button>
                  </div>
                  <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                    <Button type="dashed" className={connectionData?.appointmentCalendar.filter((element) => element.index === index)[0]?.cancelActive === "true" ? "w-11/12 bg-green-300" : "w-11/12"} onClick={() => handleAppointmentCancellingSelect(index)}>
                      Cancellation
                    </Button>
                  </div>
                </div>
                <div className="w-full place-items-center m-auto justify-center text-center">
                  <MinusCircleOutlined
                    onClick={() => {
                      handleConnectionRemove(index);
                    }}
                  />
                </div>
              </div>
            );
          })}

        <div className="w-11/12 mx-auto mt-4">
          <Button
            type="dashed"
            onClick={() => {
              const length = Number(connectionData?.appointmentCalendar.length + 1);
              const newValue = {
                index: length + Math.random(),
                calendarId: "",
                sessionId: "",
                locationId: "",
                type: "new",
                bookingActive: "false",
                cancelActive: "false",
                forcefulBooking: "false",
                programId: null,
                staffId: null,
              };
              setConnectionData((prevConnectionData) => {
                return {
                  ...prevConnectionData,
                  appointmentCalendar: [...prevConnectionData.appointmentCalendar, newValue],
                };
              });
            }}
            block
            icon={<PlusOutlined />}
          >
            Add connection
          </Button>
        </div>
      </Spin>
    </>
  );
};

export default LinkAppointmentCalendarGhlConnection;
