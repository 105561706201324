import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, Typography, Input, Select, Option, IconButton, Switch, Button } from "@material-tailwind/react";
import { motion } from "framer-motion";
import { useFormik } from "formik";
import logo from "../../data/images/mindbody_logo.png";
import ghllogo from "../../data/images/ghl_logo.png";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { MdDoneAll } from "react-icons/md";
import { toast } from "react-hot-toast";
import { getActivationCode, invokeMBWebhook } from "../../helpers/WebhookApi";
import { useNavigate } from "react-router-dom";
import { activeUser, getUserAuthDetails } from "../../helpers/AppUserApi";
import { addGHLData, getAllCalendarDetails, getCustomFieldDetails } from "../../helpers/GhlApi";
import { Spinner } from "@material-tailwind/react";

const Register = () => {
  const navigate = useNavigate();
  const [nextPage, setNextPage] = useState(false);
  const [ghlPage, setGhlPage] = useState(1);
  const [mbPage, setMbPage] = useState(1);
  const [userId, setUserId] = useState();
  const [calendarDetails, setCalendarDetails] = useState([]);
  const [fieldDetails, setFieldDetails] = useState([]);
  const [memberfieldDetails, setMemberFieldDetails] = useState([]);
  const [calendarFetch, setCalendarFetch] = useState(true);
  const [fieldFetch, setFieldFetch] = useState(true);
  let toastBox = toast;
  const [selectCalendar, setSelectCalendar] = useState(null);
  const [selectClientFieldID, setSelectClientFieldID] = useState(null);
  const [selectMemberFieldID, setSelectMemberFieldID] = useState(null);
  const [showPage, setShowPage] = useState(false);
  const [activationCode, setActivationCode] = useState(null);

  const [calendarFieldActive, setCalendarFieldActive] = useState(false);
  const [memberFieldActive, setMemberFieldActive] = useState(false);

  const MBPromise = getUserAuthDetails({ userid: userId });
  MBPromise.then(
    (msg) => {
      if (msg === "success") {
        navigate("/");
      }
    },
    (msg) => {
      if (msg === "pending") {
        setNextPage(true);
      }
    }
  );

  const activeUserPromise = activeUser();
  activeUserPromise
    .then((data) => {
      if (data.type === "admin") {
        navigate("/");
      } else if (data.type === "user") {
        setUserId(data.userid);
        setTimeout(() => {
          setShowPage(true);
        }, 2000);
      } else {
        navigate("/login");
      }
    })
    .catch((err) => {
      navigate("/login");
    });

  const formik = useFormik({
    initialValues: {
      siteId: "",
      email: "",
      password: "",
      name: "",
    },
    validate: false,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      const servicesId = ["addclient"];
      const newValues = { ...values, servicesId, userId };
      toast.loading("Loading...", {
        id: toastBox,
      });
      let invokePromise = invokeMBWebhook(newValues);
      invokePromise.then(
        (data) => {
          toast.success("MindBody Integration Successful", {
            id: toastBox,
          });
          // setMbApiKey(formik.values.apikey);
          setNextPage(true);
          // navigate('/ghlregister')
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      );
    },
  });

  const ghlformik = useFormik({
    initialValues: {
      apikey: "",
      name: "",
      calendarId: "",
      calendartags: "",
      clientFieldId: "",
      clientTags: "",
      membershiptags: "",
      membershipFieldId: "",
      memberCancelTags: "",
    },
    validate: false,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values.calendarId = selectCalendar;
      values.clientFieldId = selectClientFieldID;
      values.membershipFieldId = selectMemberFieldID;
      const calendarName = calendarDetails.filter((element) => element.id === selectCalendar)[0].name;
      const newValues = { ...values, userId, calendarName };
      toast.loading("Loading...", {
        id: toastBox,
      });
      let invokePromise = addGHLData(newValues);
      invokePromise.then(
        (data) => {
          toast.success("GoHighLevel Integration Successful", {
            id: toastBox,
          });
          // setMbApiKey(formik.values.apikey);
          setNextPage(true);
          navigate("/");
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      );
    },
  });

  const getCalendarFunc = () => {
    const getCalendarDetailsPromise = getAllCalendarDetails({
      ghl_apikey: ghlformik.values.apikey,
    });
    getCalendarDetailsPromise
      .then(
        (data) => {
          setCalendarDetails(data);
          setCalendarFetch(false);
        },
        (msg) => {
          if (msg.includes("401")) {
            toast.error("Invalid Api-key entered", {
              id: toastBox,
            });
          } else {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const getCustomFieldFunc = () => {
    const getFieldDetailsPromise = getCustomFieldDetails({
      ghl_apikey: ghlformik.values.apikey,
    });
    getFieldDetailsPromise
      .then(
        (data) => {
          setFieldDetails(data);
          setFieldFetch(false);
        },
        (msg) => {
          if (msg.includes("401")) {
            toast.error("Invalid Api-key entered", {
              id: toastBox,
            });
          } else {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  useEffect(() => {
    setSelectCalendar(null);
    setSelectClientFieldID(null);
    setSelectMemberFieldID(null);
    setCalendarDetails([]);
    setFieldDetails([]);
    setMemberFieldDetails([]);
    setCalendarFieldActive(false);
    setMemberFieldActive(false);
  }, [ghlformik.values.apikey]);

  useEffect(() => {
    if (!calendarFieldActive) {
      setSelectCalendar(null);
      setCalendarDetails([]);
      ghlformik.values.calendartags = "";
    }
  }, [calendarFieldActive, ghlformik.values]);

  useEffect(() => {
    if (!memberFieldActive) {
      setSelectMemberFieldID(null);
      ghlformik.values.membershiptags = "";
    }
  }, [memberFieldActive, ghlformik.values]);

  const handleCalendarChange = (event) => {
    setSelectCalendar(event);
  };

  const handleClientFieldIDChange = (event) => {
    setSelectClientFieldID(event);
    setMemberFieldDetails(fieldDetails.filter((element) => element.id !== event));
  };

  const handleMemberFieldIDChange = (event) => {
    setSelectMemberFieldID(event);
  };

  const handleActivationCodeFetch = () => {
    const apikey = formik.values.apikey;
    const siteid = formik.values.siteId;
    const values = {
      apikey: apikey,
      siteid: siteid,
    };
    const getActivationCodePromise = getActivationCode(values);
    getActivationCodePromise.then(
      (data) => {
        setActivationCode(data);
        toast.success("Activation code fetched", {
          id: toastBox,
        });
      },
      ({ msg }) => {
        toast.error(`${msg}`, {
          id: toastBox,
        });
      }
    );
  };

  const handleMbPages = () => {
    handleActivationCodeFetch();
    setMbPage(2);
  };

  return (
    <>
      {!showPage && (
        <div className="flex place-items-center w-full h-screen m-auto justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      )}
      {!nextPage && showPage && mbPage === 1 && (
        <motion.div
          className="w-full h-screen flex items-center justify-center"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{
            duration: 1.0,
            delay: 0.2,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <div className="flex flex-col items-center">
            <Card className="w-10/12 sm:w-96 mx-auto justify-center loginCard">
              <form>
                <img className="w-2/4 mx-auto my-3 mt-4 place-items-center" src={logo} alt="logo" />
                <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
                  Fill all the details below
                </Typography>
                <CardBody className="flex flex-col gap-4">
                  <Input label="Set a nickname for your account" size="lg" {...formik.getFieldProps("name")} />
                  <Input label="Site Id" size="lg" {...formik.getFieldProps("siteId")} />
                  <Input label="Username/Email" size="lg" {...formik.getFieldProps("email")} />
                  <Input label="Password" size="lg" type="password" {...formik.getFieldProps("password")} />
                </CardBody>
                <div className="grid grid-flow-col">
                  <Typography color="gray" className="pt-4 mx-auto text-end pl-6 w-full text-xs flex">
                    Need help?
                    <Typography as="a" href="#signup" variant="small" className="ml-1 font-bold text-yellow-800 text-xs">
                      Contact support
                    </Typography>
                  </Typography>
                  <CardFooter className="pt-0 mx-auto text-end w-full">
                    {formik.values.apikey !== "" && formik.values.siteId !== "" && formik.values.name !== "" && formik.values.email !== "" && formik.values.password !== "" ? (
                      <IconButton variant="gradient" size="lg" className="rounded-full text-lg" onClick={handleMbPages}>
                        <AiOutlineArrowRight />
                      </IconButton>
                    ) : (
                      <IconButton
                        variant="gradient"
                        color="gray"
                        size="lg"
                        className="rounded-full text-lg"
                        onClick={() => {
                          const toastBox = toast;
                          toast("Please fill all details!", {
                            id: toastBox,
                            icon: "⚠️",
                          });
                        }}
                      >
                        <AiOutlineArrowRight />
                      </IconButton>
                    )}
                  </CardFooter>
                </div>
              </form>
            </Card>
            <div className="mt-4 w-10/12 sm:w-96 mx-auto text-center">
              <Button
                type="button"
                color="green"
                onClick={() => {
                  navigate("/");
                }}
              >
                Do it later
              </Button>
            </div>
          </div>
        </motion.div>
      )}
      {!nextPage && showPage && mbPage === 2 && (
        <motion.div
          className="w-full mx-auto h-screen flex place-items-center"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{
            duration: 1.0,
            delay: 0.2,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <Card className="w-10/12 sm:w-96 mx-auto justify-center loginCard">
            <form>
              <img className="w-2/4 mx-auto my-3 mt-4 place-items-center" src={logo} alt="logo" />
              <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
                Please add activation key to your mindbody account
              </Typography>
              <CardBody className="flex flex-col gap-4">
                {activationCode ? (
                  <Input
                    label="Activation code (tap to copy)"
                    size="lg"
                    readOnly
                    value={activationCode}
                    className="hover:cursor-pointer"
                    onClick={(e) => {
                      let toastBox = toast;
                      toast.success("Copied", {
                        id: toastBox,
                      });
                      return navigator.clipboard.writeText(e.target.value);
                    }}
                  />
                ) : (
                  <div className="flex place-items-center w-full m-auto justify-center">
                    <Spinner className="h-6 w-6" />
                  </div>
                )}
                <Typography color="amber" className="mx-auto text-center mt-2 text-md">
                  Follow these steps to add activation code:
                </Typography>
                <div className="mx-auto text-center">
                  <Typography color="red" className="mx-auto text-center mt-2 text-sm">
                    Step 1: go to
                  </Typography>
                  <a href="https://clients.mindbodyonline.com/appshell/shuttle/staff/managecredentials" className="text-purple-400" target="_blank" rel="noreferrer">
                    https://clients.mindbodyonline.com/launch
                  </a>
                </div>
                <Typography color="red" className="mx-auto text-center mt-1 text-sm">
                  Step 2: Search with your Site ID and log in
                </Typography>
                <Typography color="red" className="mx-auto text-center mt-1 text-sm">
                  Step 3: Go to Setting Tab
                </Typography>
                <Typography color="red" className="mx-auto text-center mt-1 text-sm">
                  Step 4: Under MINDBODY Add-ons, click on API Integrations
                </Typography>
                <Typography color="red" className="mx-auto text-center mt-1 text-sm">
                  Step 5: On the right-side, under "Have an Activation Code?" box, enter your code and submit.
                </Typography>
              </CardBody>
              <div className="flex flex-wrap">
                <div className="pt-0 mx-auto text-end w-fit">
                  <IconButton
                    size="lg"
                    className="rounded-full text-lg bg-yellow-800"
                    onClick={() => {
                      setMbPage(1);
                    }}
                  >
                    <AiOutlineArrowLeft />
                  </IconButton>
                </div>
                <Typography color="gray" className="pt-4 mx-auto text-center justify-center w-fit text-xs flex">
                  Need help?
                  <Typography as="a" href="#signup" variant="small" className="ml-1 font-bold text-yellow-800 text-xs">
                    Contact support
                  </Typography>
                </Typography>
                <CardFooter className="p-0 py-6 pt-0 mx-auto text-end w-fit">
                  {formik.values.apikey !== "" && formik.values.siteId !== "" && formik.values.name !== "" && formik.values.email !== "" && formik.values.password !== "" ? (
                    <IconButton variant="gradient" size="lg" className="rounded-full text-lg" onClick={formik.handleSubmit}>
                      <AiOutlineArrowRight />
                    </IconButton>
                  ) : (
                    <IconButton
                      variant="gradient"
                      color="gray"
                      size="lg"
                      className="rounded-full text-lg"
                      onClick={() => {
                        const toastBox = toast;
                        toast("Please fill all details!", {
                          id: toastBox,
                          icon: "⚠️",
                        });
                      }}
                    >
                      <AiOutlineArrowRight />
                    </IconButton>
                  )}
                </CardFooter>
              </div>
            </form>
          </Card>
        </motion.div>
      )}
      {nextPage && ghlPage === 1 && showPage && (
        <motion.div
          className="w-full h-screen flex items-center justify-center"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{
            duration: 1.0,
            delay: 0.2,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <div className="flex flex-col items-center">
            <Card className="w-10/12 sm:w-96 mx-auto justify-center loginCard">
              <form>
                <img className="w-2/4 mx-auto my-3 mt-4 place-items-center" src={ghllogo} alt="logo" />
                <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
                  Fill all the details below
                </Typography>
                <CardBody className="flex flex-col gap-4">
                  <Input label="API Key" size="lg" {...ghlformik.getFieldProps("apikey")} />
                  <Input label="Account name" size="lg" {...ghlformik.getFieldProps("name")} />
                </CardBody>
                <div className="grid grid-flow-col">
                  <Typography color="gray" className="pt-4 mx-auto text-end pl-6 w-full text-xs flex">
                    Need help?
                    <Typography as="a" href="#signup" variant="small" className="ml-1 font-bold text-yellow-800 text-xs">
                      Contact support
                    </Typography>
                  </Typography>
                  <CardFooter className="pt-0 mx-auto text-end w-full">
                    {ghlformik.values.apikey !== "" && ghlformik.values.name !== "" ? (
                      <IconButton
                        variant="gradient"
                        size="lg"
                        className="rounded-full text-lg"
                        onClick={() => {
                          setGhlPage(ghlPage + 1);
                        }}
                      >
                        <AiOutlineArrowRight />
                      </IconButton>
                    ) : (
                      <IconButton
                        variant="gradient"
                        color="gray"
                        size="lg"
                        className="rounded-full text-lg"
                        onClick={() => {
                          const toastBox = toast;
                          toast("Please fill all details!", {
                            id: toastBox,
                            icon: "⚠️",
                          });
                        }}
                      >
                        <AiOutlineArrowRight />
                      </IconButton>
                    )}
                  </CardFooter>
                </div>
              </form>
            </Card>
            <div className="mt-4 w-10/12 sm:w-96 mx-auto text-center">
              <Button
                type="button"
                color="green"
                onClick={() => {
                  navigate("/");
                }}
              >
                Do it later
              </Button>
            </div>
          </div>
        </motion.div>
      )}
      {nextPage && ghlPage === 2 && showPage && (
        <motion.div
          className="w-full mx-auto h-screen flex place-items-center"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{
            duration: 1.0,
            delay: 0.2,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          {fieldFetch && nextPage && ghlPage === 2 && getCustomFieldFunc()}
          <Card className="w-10/12 sm:w-96 mx-auto justify-center loginCard">
            <img className="w-2/4 mx-auto my-3 mt-4 place-items-center" src={ghllogo} alt="logo" />
            <Typography color="gray" className="mx-auto text-center mt-4 text-lg w-11/12">
              Select your custom field for storing Client ID
            </Typography>
            <CardBody className="flex flex-col gap-4">
              {fieldDetails.length !== 0 && (
                <Select variant="outlined" label="Select custom field" onChange={handleClientFieldIDChange} value={selectClientFieldID}>
                  {fieldDetails.map((data) => {
                    return (
                      <Option key={data.id} value={data.id}>
                        {data.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
              <Input label="Custom tags (if any)" size="lg" {...ghlformik.getFieldProps("clientTags")} />
            </CardBody>
            <div className="flex flex-wrap">
              <div className="pt-0 mx-auto text-end w-fit">
                <IconButton
                  size="lg"
                  className="rounded-full text-lg bg-yellow-800"
                  onClick={() => {
                    setGhlPage(ghlPage - 1);
                    setFieldFetch(true);
                  }}
                >
                  <AiOutlineArrowLeft />
                </IconButton>
              </div>

              <Typography color="gray" className="pt-4 mx-auto text-center justify-center w-fit text-xs flex">
                Need help?
                <Typography as="a" href="#signup" variant="small" className="ml-1 font-bold text-yellow-800 text-xs">
                  Contact support
                </Typography>
              </Typography>
              <CardFooter className="p-0 py-6 pt-0 mx-auto text-end w-fit">
                {selectClientFieldID ? (
                  <IconButton
                    variant="gradient"
                    size="lg"
                    className="rounded-full text-lg"
                    onClick={() => {
                      setGhlPage(ghlPage + 1);
                    }}
                  >
                    <AiOutlineArrowRight />
                  </IconButton>
                ) : (
                  <IconButton
                    variant="gradient"
                    color="gray"
                    size="lg"
                    className="rounded-full text-lg"
                    onClick={() => {
                      const toastBox = toast;
                      toast("Please fill all details!", {
                        id: toastBox,
                        icon: "⚠️",
                      });
                    }}
                  >
                    <AiOutlineArrowRight />
                  </IconButton>
                )}
              </CardFooter>
            </div>
          </Card>
        </motion.div>
      )}
      {nextPage && ghlPage === 3 && showPage && (
        <motion.div
          className="w-full mx-auto h-screen flex place-items-center"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{
            duration: 1.0,
            delay: 0.2,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <Card className="w-10/12 sm:w-96 mx-auto justify-center loginCard">
            <img className="w-2/4 mx-auto my-3 mt-4 place-items-center" src={ghllogo} alt="logo" />
            <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
              Select additional features (if required)
            </Typography>
            <CardBody className="flex flex-col gap-4">
              <div className="pl-4">
                <Switch
                  id="calendar"
                  onChange={(e) => setCalendarFieldActive(e.target.checked)}
                  checked={calendarFieldActive}
                  containerProps={{
                    className: "-mt-10",
                  }}
                  label={
                    <div>
                      <Typography color="blue-gray" className="font-medium ml-2">
                        Add Calendar
                      </Typography>
                      <Typography variant="small" color="gray" className="font-normal ml-2">
                        By enabling it, mindbody will be able to send class booking data.
                      </Typography>
                    </div>
                  }
                />
              </div>
              <div className="pl-4">
                <Switch
                  id="membership"
                  onChange={(e) => setMemberFieldActive(e.target.checked)}
                  checked={memberFieldActive}
                  containerProps={{
                    className: "-mt-10",
                  }}
                  label={
                    <div>
                      <Typography color="blue-gray" className="font-medium ml-2">
                        Add Membership
                      </Typography>
                      <Typography variant="small" color="gray" className="font-normal ml-2">
                        By enabling it, mindbody will be able to send membership data.
                      </Typography>
                    </div>
                  }
                />
              </div>
            </CardBody>
            <div className="flex flex-wrap">
              <div className="pt-0 mx-auto text-end w-fit">
                <IconButton
                  size="lg"
                  className="rounded-full text-lg bg-yellow-800"
                  onClick={() => {
                    setGhlPage(ghlPage - 1);
                  }}
                >
                  <AiOutlineArrowLeft />
                </IconButton>
              </div>

              <Typography color="gray" className="pt-4 mx-auto text-center justify-center w-fit text-xs flex">
                Need help?
                <Typography as="a" href="#signup" variant="small" className="ml-1 font-bold text-yellow-800 text-xs">
                  Contact support
                </Typography>
              </Typography>
              <CardFooter className="p-0 py-6 pt-0 mx-auto text-end w-fit">
                <IconButton
                  variant="gradient"
                  size="lg"
                  color={!calendarFieldActive && !memberFieldActive ? "green" : "blue"}
                  className="rounded-full text-lg"
                  onClick={() => {
                    if (calendarFieldActive) setGhlPage(ghlPage + 1);
                    else if (memberFieldActive) setGhlPage(ghlPage + 2);
                    else ghlformik.handleSubmit();
                  }}
                >
                  {!calendarFieldActive && !memberFieldActive ? <MdDoneAll /> : <AiOutlineArrowRight />}
                </IconButton>
              </CardFooter>
            </div>
          </Card>
        </motion.div>
      )}
      {nextPage && ghlPage === 4 && showPage && (
        <motion.div
          className="w-full mx-auto h-screen flex place-items-center"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{
            duration: 1.0,
            delay: 0.2,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          {calendarFetch && nextPage && ghlPage === 4 && getCalendarFunc()}
          <Card className="w-10/12 sm:w-96 mx-auto justify-center loginCard">
            <img className="w-2/4 mx-auto my-3 mt-4 place-items-center" src={ghllogo} alt="logo" />
            <Typography color="gray" className="mx-auto text-center mt-4 text-lg w-11/12">
              Select calendar where class booking will be stored from mindbody
            </Typography>
            <CardBody className="flex flex-col gap-4">
              {calendarDetails.length !== 0 && (
                <Select variant="outlined" label="Select calendar" onChange={handleCalendarChange} value={selectCalendar}>
                  {calendarDetails.map((data) => {
                    return (
                      <Option key={data.id} value={data.id}>
                        {data.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
              <Input label="Custom tags (if any)" size="lg" {...ghlformik.getFieldProps("calendartags")} />
            </CardBody>
            <div className="flex flex-wrap">
              <div className="pt-0 mx-auto text-end w-fit">
                <IconButton
                  size="lg"
                  className="rounded-full text-lg bg-yellow-800"
                  onClick={() => {
                    setGhlPage(ghlPage - 1);
                    setCalendarFetch(true);
                  }}
                >
                  <AiOutlineArrowLeft />
                </IconButton>
              </div>

              <Typography color="gray" className="pt-4 mx-auto text-center justify-center w-fit text-xs flex">
                Need help?
                <Typography as="a" href="#signup" variant="small" className="ml-1 font-bold text-yellow-800 text-xs">
                  Contact support
                </Typography>
              </Typography>
              <CardFooter className="p-0 py-6 pt-0 mx-auto text-end w-fit">
                {selectCalendar ? (
                  <IconButton
                    variant="gradient"
                    size="lg"
                    color={memberFieldActive ? "blue" : "green"}
                    className="rounded-full text-lg"
                    onClick={() => {
                      if (memberFieldActive) setGhlPage(ghlPage + 1);
                      else ghlformik.handleSubmit();
                    }}
                  >
                    {memberFieldActive ? <AiOutlineArrowRight /> : <MdDoneAll />}
                  </IconButton>
                ) : (
                  <IconButton
                    variant="gradient"
                    color="gray"
                    size="lg"
                    className="rounded-full text-lg"
                    onClick={() => {
                      const toastBox = toast;
                      toast("Please fill all details!", {
                        id: toastBox,
                        icon: "⚠️",
                      });
                    }}
                  >
                    <AiOutlineArrowRight />
                  </IconButton>
                )}
              </CardFooter>
            </div>
          </Card>
        </motion.div>
      )}
      {nextPage && ghlPage === 5 && showPage && (
        <motion.div
          className="w-full mx-auto h-screen flex place-items-center"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{
            duration: 1.0,
            delay: 0.2,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <Card className="w-10/12 sm:w-96 mx-auto justify-center loginCard">
            <img className="w-2/4 mx-auto my-3 mt-4 place-items-center" src={ghllogo} alt="logo" />
            <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
              Select your custom field for storing membership name
            </Typography>
            <CardBody className="flex flex-col gap-4">
              {memberfieldDetails.length !== 0 && (
                <Select variant="outlined" label="Select custom field" onChange={handleMemberFieldIDChange} value={selectMemberFieldID}>
                  {memberfieldDetails.map((data) => {
                    return (
                      <Option key={data.id} value={data.id}>
                        {data.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
              <Input label="Membership purchase tags (if any)" size="lg" {...ghlformik.getFieldProps("membershiptags")} />
              <Input label="Membership cancel tags (if any)" size="lg" {...ghlformik.getFieldProps("memberCancelTags")} />
            </CardBody>
            <div className="flex flex-wrap">
              <div className="pt-0 mx-auto text-end w-fit">
                <IconButton
                  size="lg"
                  className="rounded-full text-lg bg-yellow-800"
                  onClick={() => {
                    if (calendarFieldActive) setGhlPage(ghlPage - 1);
                    else if (memberFieldActive) setGhlPage(ghlPage - 2);
                  }}
                >
                  <AiOutlineArrowLeft />
                </IconButton>
              </div>

              <Typography color="gray" className="pt-4 mx-auto text-center justify-center w-fit text-xs flex">
                Need help?
                <Typography as="a" href="#signup" variant="small" className="ml-1 font-bold text-yellow-800 text-xs">
                  Contact support
                </Typography>
              </Typography>
              <CardFooter className="p-0 py-6 pt-0 mx-auto text-end w-fit">
                {selectMemberFieldID ? (
                  <IconButton variant="gradient" size="lg" color="green" className="rounded-full text-lg" onClick={ghlformik.handleSubmit}>
                    <MdDoneAll />
                  </IconButton>
                ) : (
                  <IconButton
                    variant="gradient"
                    color="gray"
                    size="lg"
                    className="rounded-full text-lg"
                    onClick={() => {
                      const toastBox = toast;
                      toast("Please fill all details!", {
                        id: toastBox,
                        icon: "⚠️",
                      });
                    }}
                  >
                    <MdDoneAll />
                  </IconButton>
                )}
              </CardFooter>
            </div>
          </Card>
        </motion.div>
      )}
    </>
  );
};

export default Register;
