import React, { useState } from "react";
import "./LinkCalendarForm.css";
import { Button, Checkbox, Divider, Form, Select, Space, Spin } from "antd";
import { getAppointmentCalendarDetailsForGhl, getCalendarDetailsForGhl, getClassDetailsForGhl, getLocationDetailsForGhl, getMindbodyProgramDetails, getMindbodyStaffDetails, getSessionDetailsForGhl } from "../../../../../../helpers/AppUserApi";
import { toast } from "react-hot-toast";

const LinkCalendarForm = ({ data, setData, manageType, webhook_type }) => {
  const [ghlCalendarDetails, setGhlCalendarDetails] = useState([]);
  const [classDetails, setClassDetails] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [locationDetails, setLocationDetails] = useState([]);
  const [sessionDetails, setSessionDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [programDetails, setProgramDetails] = useState([]);
  const [staffDetails, setStaffDetails] = useState([]);

  // const onFinish = (values) => {
  //   console.log("Received values of form:", values);
  // };

  const [bookingButtons, setBookingButtons] = useState(data?.bookingActive === "true" ? "true" : "false");
  const [cancellingButtons, setCancellingButtons] = useState(data?.cancelActive === "true" ? "true" : "false");
  const [forcefulBooking, setForcefulBooking] = useState(data?.forcefulBooking === "true" ? "true" : "false");

  const handleBookingButtonChange = (data) => {
    const newData = data === "true" ? "false" : "true";
    setBookingButtons(newData);
    setData((prevData) => ({
      ...prevData,
      bookingActive: newData,
    }));
  };

  const handleCancellingButtonChange = (data) => {
    const newData = data === "true" ? "false" : "true";
    setCancellingButtons(newData);
    setData((prevData) => ({
      ...prevData,
      cancelActive: newData,
    }));
  };

  const getCalendarAvailFunc = (ghl_id, mbAccountApiKey, mbAccountSiteId) => {
    let toastBox = toast;
    const checkCalendarAvailPromise = getCalendarDetailsForGhl({
      ghl_id: ghl_id,
    });
    checkCalendarAvailPromise
      .then(
        (data) => {
          if (data) {
            if (manageType === "exist") {
              // let newObj = { id: webData.calendarId, name: webData.calendarName };
              // const newArray = [...data, newObj];
              setGhlCalendarDetails(data);
            } else setGhlCalendarDetails(data);
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });

    const getClassNamePromise = getClassDetailsForGhl({
      apikey: mbAccountApiKey,
      siteid: mbAccountSiteId,
    });
    getClassNamePromise
      .then(
        (data) => {
          if (Array.isArray(data)) {
            // if (manageType === "exist") {
            //   let newObj = { name: webData?.className };
            //   const newArray = [...data, newObj];
            //   setClassDetails(newArray);
            // } else setClassDetails(data);
            setClassDetails(data);
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const getAppointmentCalendarAvailFunc = (ghl_id, mbAccountApiKey, mbAccountSiteId) => {
    let toastBox = toast;
    setLoading(true);
    const checkCalendarAvailPromise = getAppointmentCalendarDetailsForGhl({
      ghl_id: ghl_id,
    });
    checkCalendarAvailPromise
      .then(
        (data) => {
          if (data) {
            if (manageType === "exist") {
              // let newObj = { id: webData.calendarId, name: webData.calendarName };
              // const newArray = [...data, newObj];
              setGhlCalendarDetails(data);
            } else setGhlCalendarDetails(data);
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        // setLoading(false);
      });

    const getLocationPromise = getLocationDetailsForGhl({
      apikey: mbAccountApiKey,
      siteid: mbAccountSiteId,
    });
    getLocationPromise
      .then(
        (data) => {
          if (data) {
            if (data) {
              setLocationDetails(data);
            }
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setLoading(false);
      });

    const getSessionPromise = getSessionDetailsForGhl({
      apikey: mbAccountApiKey,
      siteid: mbAccountSiteId,
    });
    getSessionPromise
      .then(
        (data) => {
          if (data) {
            if (data) {
              setSessionDetails(data);
            }
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setLoading(false);
      });

    const getProgramDetailsFunc = async () => {
      const toastBox = toast;
      try {
        const getMindbodyProgramDetailsResult = await getMindbodyProgramDetails({
          mb_apikey: mbAccountApiKey,
          mb_siteid: mbAccountSiteId,
        });
        setProgramDetails(getMindbodyProgramDetailsResult?.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getStaffDetailsFunc = async () => {
      const toastBox = toast;
      try {
        const getMindbodyProgramDetailsResult = await getMindbodyStaffDetails({
          mb_apikey: mbAccountApiKey,
          mb_siteid: mbAccountSiteId,
        });
        setStaffDetails(getMindbodyProgramDetailsResult?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getStaffDetailsFunc();
    getProgramDetailsFunc();
  };
  (() => {
    if (!hasLoaded) {
      if (manageType === "exist") {
        if (webhook_type === "manage_class") getCalendarAvailFunc(data.ghl_id, data.mb_apikey, data.mb_siteid);
        else if (webhook_type === "manage_appointment") getAppointmentCalendarAvailFunc(data.ghl_id, data.mb_apikey, data.mb_siteid);
      }
      setHasLoaded(true); // Set the flag to true after executing the code
    }
  })();

  const handleClassSelect = (e) => {
    setData((prevData) => ({
      ...prevData,
      className: e,
    }));
  };

  const handleLocationSelect = (e) => {
    setData((prevData) => ({
      ...prevData,
      locationKey: e,
    }));
  };

  const handleSessionSelect = (e) => {
    setData((prevData) => ({
      ...prevData,
      sessionKey: e,
    }));
  };

  const handleProgramSelect = (e) => {
    setData((prevData) => ({
      ...prevData,
      programId: e,
    }));
  };

  const handleStaffSelect = (e) => {
    setData((prevData) => ({
      ...prevData,
      staffId: e,
    }));
  };

  const handleCalendarSelect = (calendarId) => {
    setData((prevData) => ({
      ...prevData,
      calendarId: calendarId,
      calendarName: ghlCalendarDetails.filter((e) => e.id === calendarId)[0]?.name,
    }));
  };

  const onForcefulBookingChange = (data) => {
    const newData = data === "true" ? "false" : "true";
    setForcefulBooking(newData);
    setData((prevData) => ({
      ...prevData,
      forcefulBooking: newData,
    }));
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Form
          name="classCalendar"
          style={{
            maxWidth: "70vw",
          }}
          // onFinish={onFinish}
          autoComplete="off"
        >
          <div className="w-full mx-auto">
            {webhook_type === "manage_class" ? (
              <Space align="baseline" style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div className="form-item-wrapper min-w-max ml-4">
                  <Form.Item
                    label="Calendar"
                    name="calendar"
                    rules={[
                      {
                        required: true,
                        message: "Missing calendar",
                      },
                    ]}
                  >
                    <Select style={{ width: "10vw" }} placeholder="Select a calendar" defaultValue={manageType === "exist" && data?.calendarId} onChange={(e) => handleCalendarSelect(e)}>
                      {ghlCalendarDetails.length !== 0 &&
                        ghlCalendarDetails.map((data, index) => {
                          return (
                            <Select.Option key={index} value={data.id}>
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Class"
                    name="class"
                    rules={[
                      {
                        required: true,
                        message: "Missing class",
                      },
                    ]}
                  >
                    <Select style={{ width: "10vw" }} placeholder="Select a class" className="ml-6 2xl:ml-0" defaultValue={manageType === "exist" && data?.className} onChange={(e) => handleClassSelect(e)}>
                      {classDetails.length !== 0 &&
                        classDetails.map((data, index) => {
                          return (
                            <Select.Option key={index} value={data.name}>
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="form-item-wrapper min-w-max">
                  <Form.Item name={"booking"}>
                    <Button type="dashed" onClick={() => handleBookingButtonChange(bookingButtons)} className={bookingButtons === "true" ? "bg-green-400" : null}>
                      Booking
                    </Button>
                  </Form.Item>
                  <Form.Item name={"cancelling"}>
                    <Button type="dashed" onClick={() => handleCancellingButtonChange(cancellingButtons)} className={cancellingButtons === "true" ? "bg-green-400" : null}>
                      Cancelling
                    </Button>
                  </Form.Item>
                </div>
                {/* <div className="form-item-wrapper">
              <MinusCircleOutlined />
            </div> */}
              </Space>
            ) : webhook_type === "manage_appointment" ? (
              <>
                <div className="w-full mx-auto flex place-content-between">
                  <div className="w-full text-base place-content-start my-auto text-gray-600 lg:min-w-max">Enable atleast one event </div>
                  <div className="w-full text-base place-content-start my-auto">{/* <img src={rightArrowImg} alt="->" width={100} />{" "} */}</div>
                  <div className="w-full mx-auto flex place-content-end place-items-center gap-5 my-auto">
                    <Form.Item name={"booking"} className="my-0">
                      <Button type="dashed" onClick={() => handleBookingButtonChange(bookingButtons)} className={bookingButtons === "true" ? "bg-green-400" : null}>
                        Booking
                      </Button>
                    </Form.Item>
                    <Form.Item name={"cancelling"} className="my-0">
                      <Button type="dashed" onClick={() => handleCancellingButtonChange(cancellingButtons)} className={cancellingButtons === "true" ? "bg-green-400" : null}>
                        Cancelling
                      </Button>
                    </Form.Item>
                  </div>
                </div>
                <Divider className="my-2" />
                <div className="grid grid-cols-2">
                  <Form.Item
                    label="Calendar"
                    name="calendar"
                    rules={[
                      {
                        required: true,
                        message: "Missing calendar",
                      },
                    ]}
                    className="grid-cols-1"
                    initialValue={manageType === "exist" && data?.calendarId}
                  >
                    <Select style={{ width: "10vw" }} placeholder="Select a calendar" defaultValue={manageType === "exist" && data?.calendarId} onChange={(e) => handleCalendarSelect(e)}>
                      {ghlCalendarDetails.length !== 0 &&
                        ghlCalendarDetails.map((data, index) => {
                          return (
                            <Select.Option key={index} value={data.id}>
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Location"
                    name="location"
                    rules={[
                      {
                        required: true,
                        message: "Missing location",
                      },
                    ]}
                    initialValue={manageType === "exist" && data?.locationKey}
                  >
                    <Select style={{ width: "10vw" }} placeholder="Select a location" className="ml-6 2xl:ml-0" onChange={(e) => handleLocationSelect(e)}>
                      {locationDetails.length !== 0 &&
                        locationDetails.map((data, index) => {
                          return (
                            <Select.Option key={index} value={data.id}>
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Session"
                    name="session"
                    rules={[
                      {
                        required: true,
                        message: "Missing session",
                      },
                    ]}
                    initialValue={manageType === "exist" && data?.sessionKey}
                  >
                    <Select style={{ width: "10vw" }} placeholder="Select a session" className="ml-2 2xl:ml-2" onChange={(e) => handleSessionSelect(e)}>
                      {sessionDetails.length !== 0 &&
                        sessionDetails.map((data, index) => {
                          return (
                            <Select.Option key={index} value={data.id}>
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Forceful booking"
                    name="Forceful booking"
                    rules={[
                      {
                        required: false,
                        message: "Missing forceful booking",
                      },
                    ]}
                    initialValue={manageType === "exist" && data?.forcefulBooking}
                  >
                    <Checkbox style={{ width: "10vw" }} className="ml-2 2xl:ml-2" onChange={() => onForcefulBookingChange(forcefulBooking)} checked={forcefulBooking === "true"}></Checkbox>
                  </Form.Item>
                  {forcefulBooking === "true" && (
                    <>
                      <Form.Item
                        label="Program"
                        name="programId"
                        rules={[
                          {
                            required: true,
                            message: "Missing session",
                          },
                        ]}
                        initialValue={manageType === "exist" && data?.programId}
                      >
                        <Select style={{ width: "10vw" }} placeholder="Select a program" className="ml-2 2xl:ml-2" onChange={(e) => handleProgramSelect(e)}>
                          {programDetails.length !== 0 &&
                            programDetails.map((data, index) => {
                              return (
                                <Select.Option key={index} value={`${data.Id}`}>
                                  {data.Name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Staff"
                        name="staffId"
                        rules={[
                          {
                            required: true,
                            message: "Missing session",
                          },
                        ]}
                        initialValue={manageType === "exist" && data?.staffId}
                      >
                        <Select style={{ width: "10vw" }} placeholder="Select a staff" className="ml-2 2xl:ml-2" onChange={(e) => handleStaffSelect(e)}>
                          {staffDetails.length !== 0 &&
                            staffDetails.map((data, index) => {
                              return (
                                <Select.Option key={index} value={`${data.Id}`}>
                                  {data.DisplayName}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </>
                  )}
                </div>

                {/* <div className="form-item-wrapper">
              <MinusCircleOutlined />
            </div> */}
              </>
            ) : null}
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default LinkCalendarForm;
