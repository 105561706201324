import { Spinner } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LoadingMainPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/dashboard");
    // eslint-disable-next-line
  }, []);
  return (
    <div className="h-screen flex items-center w-full">
      <Spinner />
    </div>
  );
};

export default LoadingMainPage;
