import axios from "axios";
import { API_URL, APP_MB_BASE_URL } from "./AppApiUrls";

//const BASE_URL = "https://www.webhook.synx.ai/mindbody";
const BASE_URL = `${API_URL}${APP_MB_BASE_URL}`;
//const BASE_URL = "http://13.232.11.86:8000/app/api/user";
//const BASE_URL = "http://192.168.0.109:8000/app/api/user";

export async function getMBClassCalendarDetails(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/class/connection/connections`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMBAppointmentCalendarDetails(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/appointment/connection/connections`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMBAvailableClass(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/class/class/available`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMBAvailableLocation(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/appointment/location/available`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMindbodyCustomTags(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.get(`${BASE_URL}/webhook/customtags`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      params: apidetails,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMindbodyCalendarTags(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.get(`${BASE_URL}/webhook/calendartags`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      params: apidetails,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateMindbodyCustomTags(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/webhook/customtags`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMBAvailableSession(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/appointment/session/available`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMBClassAvailableCalendar(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/class/calendar/available`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMBAppointmentAvailableCalendar(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/appointment/calendar/available`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

// export async function mbToGhlContactSync({ mb_apikey, siteid, ghl_apikey, newAbortController }) {
//   try {
//     const signalData = {
//       aborted: newAbortController.signal.aborted,
//       reason: newAbortController.signal.reason,
//       onabort: newAbortController.signal.onabort,
//     };
//     const jsonController = JSON.stringify(signalData);
//     console.log(newAbortController);
//     const { data } = await axios.post(
//       `${BASE_URL}/sync/contacts`,
//       { mb_apikey, siteid, ghl_apikey, signal: newAbortController.signal },
//       {
//         headers: { "Content-Type": "application/json" },
//         withCredentials: true,
//         signal: newAbortController.signal,
//       }
//     );
//     return Promise.resolve(data);
//   } catch (error) {
//     if (axios.isCancel(error)) {
//       return Promise.reject("Operation aborted by user"); // Add 'return' here
//     } else {
//       return Promise.reject(error); // Add 'return' here
//     }
//   }
// }

export async function mbToGhlContactSyncStart({ mb_apikey, siteid, ghl_apikey, transferLimit }) {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/sync/contacts`,
      { mb_apikey, siteid, ghl_apikey, transferLimit },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function mbToGhlContactSyncStop() {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/sync/contacts/stop`,
      {},
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function mbToGhlSyncStatus() {
  try {
    const { data } = await axios.get(`${BASE_URL}/sync/contacts/status`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
}
