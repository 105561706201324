import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Input,
  //   Checkbox,
  Button,
  Alert,
} from "@material-tailwind/react";
import { useFormik } from "formik";
import { Toaster, toast } from "react-hot-toast";
import { activeUser, loginUser } from "../../helpers/AppUserApi";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import logo from "../../data/images/synx_logo.png";
import Register from "../register/Register.jsx";
import AuthContext from "../../contexts/AuthContextProvider.js";

export const Login = () => {
  const navigate = useNavigate();
  const [authVal, setAuthVal] = useState(false);
  const { auth, setAuth } = useContext(AuthContext);
  const [isDeactivated, setIsDeactivated] = useState(false);

  useEffect(() => {
    async function checkUserActive() {
      try {
        const activeUserPromise = await activeUser();
        const userType = Array.isArray(activeUserPromise?.type) ? activeUserPromise?.type : [activeUserPromise?.type];
        const subLogin = activeUserPromise?.subLogin || false;
        setAuth({ ...auth, userActive: activeUserPromise?.msg, userType: userType, subLogin: subLogin });
        if (userType) navigate("/dashboard/overview");
      } catch (error) {
        const status = error?.response?.status;
      }
    }
    checkUserActive();
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: false,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      let toastBox = toast;
      toast.loading("Loading...", {
        id: toastBox,
      });
      try {
        const loginPromise = await loginUser(values);
        const userActive = loginPromise?.data?.active;
        const userType = Array.isArray(loginPromise?.data?.usertype) ? loginPromise?.data?.usertype : [loginPromise?.data?.usertype];
        const subLogin = loginPromise?.data?.subLogin || false;
        if (userActive === "active") {
          // setAuthVal(true);
          setAuth({ userType, userActive, subLogin });
          // localStorage.setItem("token", loginPromise?.data?.token);
          toast.success("Logged in Successfully!", {
            id: toastBox,
          });
          navigate("/dashboard/overview");
        } else {
          setIsDeactivated(true);
          toast.success("Authorization denied! Account de-activated", {
            id: toastBox,
          });
        }
      } catch (error) {
        const status = error?.response?.status;
        if (status === 401)
          toast.error("Incorrect email or password!", {
            id: toastBox,
          });
        else
          toast.error("Something went wrong!", {
            id: toastBox,
          });
      }

      // loginPromise.then(
      //   (data) => {
      //     console.log(data);
      //     if (data.active === "active") {
      //       toast.success("Logged in Successfully!", {
      //         id: toastBox,
      //       });
      //       navigate("/onetimeregister");
      //       setAuthVal(true);
      //       localStorage.setItem("token", data.token);
      //     } else if (data.active === "deactivated") {
      //       setIsDeactivated(true);
      //       toast.error("Authorization denied!", {
      //         id: toastBox,
      //       });
      //     }
      //   },
      //   (msg) => {
      //     toast.error(`${msg}`, {
      //       id: toastBox,
      //     });
      //   }
      // );
    },
  });

  return (
    <div className="w-screen mx-auto h-screen place-items-center bg-gray-100">
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      {isDeactivated && (
        <div className="w-full mx-auto absolute justify-center text-center items-center">
          <Alert color="red" variant="ghost" className="w-2/4 mx-auto text-center justify-center mt-8">
            <span>Your account has been deactivated! Re-new our plan to continue.</span>
          </Alert>
        </div>
      )}
      {!authVal && (
        <motion.div
          className="w-full mx-auto h-screen flex place-items-center"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 1.0,
            delay: 0.2,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <Card className="w-10/12 sm:w-96 mx-auto justify-center loginCard">
            <form onSubmit={formik.handleSubmit}>
              <img className="w-2/4 mx-auto my-3 mt-4 place-items-center" src={logo} alt="logo" />
              <CardBody className="flex flex-col gap-4">
                <Input label="Email" size="lg" required {...formik.getFieldProps("email")} />
                <Input label="Password" size="lg" type="password" required {...formik.getFieldProps("password")} />
                <div className="-ml-2.5">
                  <Typography variant="small" as="a" href="#signup" className="flex justify-end font-semibold text-gray-600">
                    Forgot password?
                  </Typography>
                </div>
              </CardBody>
              <CardFooter className="pt-0">
                <Button variant="gradient" fullWidth color="amber" type="submit">
                  Sign In
                </Button>
                <Typography variant="small" className="mt-6 flex justify-center">
                  Don't have an account?
                  <Typography
                    as="a"
                    href="#signup"
                    variant="small"
                    //   color=""
                    className="ml-1 font-bold text-yellow-800"
                  >
                    Buy now
                  </Typography>
                </Typography>
              </CardFooter>
            </form>
          </Card>
        </motion.div>
      )}
      {authVal && <Register />}
    </div>
  );
};
