import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { getAppointmentGHLWebhooks, getClassBookingGHLWebhooks, getContactWebhooks, getMembershipWebhooks } from "../../../../helpers/GhlApi";
import { toast } from "react-hot-toast";
import { getMindbodyWebhooks } from "../../../../helpers/WebhookApi";
import { NestedClassBookingDrawerNewUI } from "./NestedClassBookingDrawerNewUI";
import ConnectionManage from "./connectionManagement/ConnectionManage";
import ConnectionDelete from "./connectionDelete/ConnectionDelete";
import { getGhlToMtClassWebhooks, getGhlToMtContactWebhooks, getGhlToMtMembershipWebhooks, getMarianatekWebhooks } from "../../../../helpers/MtApi";

const columns = [
  {
    title: "Connection name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "From account",
    dataIndex: "From",
    key: "From",
  },
  {
    title: "Type",
    width: "30%",
    key: "type",
    render: (_, record) => {
      // Check if the current row is a parent object
      if (record.webhook_type) {
        // If it's a parent object, render an empty cell (or you can return null)
        return (
          <>
            <p className="">
              {record.webhook_type === "contact_creation"
                ? "(GHL To MB) Contact creation"
                : record.webhook_type === "manage_class"
                ? "(GHL To MB) Manage classes"
                : record.webhook_type === "manage_appointment"
                ? "(GHL To MB) Manage appointments"
                : record.webhook_type === "ghl_purchase_membership"
                ? "(GHL To MB) Purchase membership"
                : record.webhook_type === "mindbody_webhook"
                ? "(MB To GHL) webhook connections"
                : record.webhook_type === "marianatek_webhook"
                ? "(MT To GHL) webhook connections"
                : record.webhook_type === "ghl_to_mt_manage_class"
                ? "(GHL To MT) Manage classes"
                : record.webhook_type === "ghl_to_mt_contact_creation"
                ? "(GHL To MT) Contact creation"
                : record.webhook_type === "ghl_to_mt_manage_membership"
                ? "(GHL To MT) Purchase membership"
                : record.webhook_type}
            </p>
          </>
        );
      }
    },
  },
  {
    title: "Options",
    width: "30%",
    render: (_, record) => {
      // Check if the current row is a parent object
      if (record.isParent) {
        // If it's a parent object, render an empty cell (or you can return null)
        return (
          <>
            <button className="text-red-700 delete-link">Delete</button>
          </>
        );
      }

      // If it's a child object, render the options links
      return (
        <>
          <button className="edit-link">Edit</button>
          <button className="ml-3 text-red-700 delete-link">Delete</button>
        </>
      );
    },
  },
];

// rowSelection objects indicates the need for row selection
const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
  //   },
  //   onSelect: (record, selected, selectedRows) => {
  //     console.log(record, selected, selectedRows);
  //   },
  //   onSelectAll: (selected, selectedRows, changeRows) => {
  //     console.log(selected, selectedRows, changeRows);
  //   },
};
const ConnectionTableNewUI = () => {
  const [contactWebhooks, setContactWebhooks] = useState([]);
  const [ghlToMtContactWebhooks, setGhlToMtContactWebhooks] = useState([]);
  const [appointmentWebhooks, setAppointmentWebhooks] = useState([]);
  const [membershipWebhooks, setMembershipWebhooks] = useState([]);
  const [classBookingWebhooks, setClassBookingWebhooks] = useState([]);
  const [ghlToMtClassWebhooks, setGhlToMtClassWebhooks] = useState([]);
  const [ghlToMtMembershipWebhooks, setGhlToMtMembershipWebhooks] = useState([]);
  const [mindbodyWebhooks, setMindbodyWebhooks] = useState([]);
  const [marianatekWebhooks, setMarianatekWebhooks] = useState([]);
  const [classBookingNestedWebhooks, setClassBookingNestedWebhooks] = useState([]);

  const [openClassDrawer, setOpenClassDrawer] = useState(false);
  const [connectionManageData, setConnectionManageData] = useState(null);
  const [openConnectionManage, setOpenConnectionManage] = useState(false);

  const [connectionDeleteData, setConnectionDeleteData] = useState(null);
  const [openConnectionDelete, setOpenConnectionDelete] = useState(false);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [dataLoader, setDataLoader] = useState({
    ghl_contact: false,
    ghl_class: false,
    ghl_appointment: false,
    mindbody_webhook: false,
    ghl_membership: false,
    ghl_to_mt_contact: false,
    ghl_to_mt_class: false,
    ghl_to_mt_membership: false,
    marianatek_webhook: false,
  });

  const getContactWebhooksFunc = () => {
    let toastBox = toast;
    const getContactWebhookPromise = getContactWebhooks();
    getContactWebhookPromise
      .then(
        (data) => {
          if (data) {
            setContactWebhooks(data);
          }
        },
        (msg) => {}
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, ghl_contact: true }));
      });
  };

  const getGhlToMtContactWebhooksFunc = () => {
    let toastBox = toast;
    const getContactWebhookPromise = getGhlToMtContactWebhooks();
    getContactWebhookPromise
      .then(
        (data) => {
          if (data) {
            setGhlToMtContactWebhooks(data);
          }
        },
        (msg) => {}
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, ghl_to_mt_contact: true }));
      });
  };

  const getMembershipWebhooksFunc = () => {
    let toastBox = toast;
    const getMembershipWebhookPromise = getMembershipWebhooks();
    getMembershipWebhookPromise
      .then(
        (data) => {
          if (data) {
            setMembershipWebhooks(data);
          }
        },
        (msg) => {}
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, ghl_membership: true }));
      });
  };

  const getClassBookingGHLWebhooksFunc = () => {
    let toastBox = toast;
    const getClassBookingWebhookPromise = getClassBookingGHLWebhooks();
    getClassBookingWebhookPromise
      .then(
        (data) => {
          if (data) {
            setClassBookingWebhooks(data);
          }
        },
        (msg) => {}
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, ghl_class: true }));
      });
  };

  const getClassBookingGhlToMtWebhooksFunc = () => {
    let toastBox = toast;
    const getClassBookingWebhookPromise = getGhlToMtClassWebhooks();
    getClassBookingWebhookPromise
      .then(
        (data) => {
          if (data) {
            setGhlToMtClassWebhooks(data);
          }
        },
        (msg) => {}
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, ghl_to_mt_class: true }));
      });
  };

  const getMembershipGhlToMtWebhooksFunc = () => {
    let toastBox = toast;
    const getClassBookingWebhookPromise = getGhlToMtMembershipWebhooks();
    getClassBookingWebhookPromise
      .then(
        (data) => {
          if (data) {
            setGhlToMtMembershipWebhooks(data);
          }
        },
        (msg) => {}
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, ghl_to_mt_membership: true }));
      });
  };

  const getAppointmentsGHLWebhooksFunc = () => {
    let toastBox = toast;
    const getAppointmentWebhookPromise = getAppointmentGHLWebhooks();
    getAppointmentWebhookPromise
      .then(
        (data) => {
          if (data) {
            setAppointmentWebhooks(data);
          }
        },
        (msg) => {}
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, ghl_appointment: true }));
      });
  };

  const getMindbodyWebhooksFunc = () => {
    let toastBox = toast;
    const getMindbodyWebhookPromise = getMindbodyWebhooks();
    getMindbodyWebhookPromise
      .then(
        (data) => {
          if (data) {
            setMindbodyWebhooks(data);
          }
        },
        (msg) => {}
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, mindbody_webhook: true }));
      });
  };

  const getMarianatekWebhooksFunc = () => {
    let toastBox = toast;
    const getMindbodyWebhookPromise = getMarianatekWebhooks();
    getMindbodyWebhookPromise
      .then(
        (data) => {
          if (data) {
            setMarianatekWebhooks(data);
          }
        },
        (msg) => {}
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, marianatek_webhook: true }));
      });
  };

  useEffect(() => {
    getContactWebhooksFunc();
    getMembershipWebhooksFunc();
    getClassBookingGHLWebhooksFunc();
    getMindbodyWebhooksFunc();
    getAppointmentsGHLWebhooksFunc();
    getGhlToMtContactWebhooksFunc();
    getClassBookingGhlToMtWebhooksFunc();
    getMembershipGhlToMtWebhooksFunc();
    getMarianatekWebhooksFunc();
  }, []);

  const handleNestedClassBookingModal = (data) => {
    setClassBookingNestedWebhooks(data);
    setOpenClassDrawer(true);
  };

  const handleConnectionManageModal = (data) => {
    setConnectionManageData(data);
    setOpenConnectionManage(true);
  };

  const handleConnectionDeleteModal = (data) => {
    setConnectionDeleteData(data);
    setOpenConnectionDelete(true);
  };

  const reloadWebhookData = () => {
    getContactWebhooksFunc();
    getMembershipWebhooksFunc();
    getClassBookingGHLWebhooksFunc();
    getMindbodyWebhooksFunc();
    getAppointmentsGHLWebhooksFunc();
    getClassBookingGhlToMtWebhooksFunc();
    getGhlToMtContactWebhooksFunc();
    getMembershipGhlToMtWebhooksFunc();
    getMarianatekWebhooksFunc();
  };

  useEffect(() => {
    if (connectionManageData) {
      if (mindbodyWebhooks.filter((element) => element.secret_key === connectionManageData.secret_key)?.length !== 0) setConnectionManageData(mindbodyWebhooks.find((element) => element.secret_key === connectionManageData.secret_key));
    }
  }, [mindbodyWebhooks]);

  const groupTotalData = (...data) => {
    let newArray = [];
    data.forEach((element, index) => {
      const newData = groupByApiKey(element);
      newArray = [...newArray, ...newData];
    });
    // return groupedData;
    return newArray;
  };
  const groupByApiKey = (data) => {
    const groupedData = data.reduce((result, item) => {
      const { ghl_id, ghlName, mbName, mb_id, webhook_type, From, To, mt_id, ...rest } = item;
      const existingGroup = result.find((group) => group.ghl_id === ghl_id);

      if (webhook_type === "mindbody_webhook") {
        result.push({
          isParent: true,
          From,
          To,
          name: item.name,
          ghl_id,
          webhook_type,
          key: `${mb_id},${webhook_type}`,
          mbName,
          ghlName,
          mb_id,
          children: [{ key: item.secret_key, ghl_id, ghlName, mbName, mb_id, mt_id, webhook_type, From, To, ...rest }],
        });
      } else {
        if (existingGroup) {
          existingGroup.children.push({ key: item.secret_key, ghl_id, ghlName, mbName, mb_id, mt_id, webhook_type, From, To, ...rest });
          const splittedNames = existingGroup.name.split(",");
          if (splittedNames && splittedNames.length > 0) {
            if (splittedNames && splittedNames.length < 2) existingGroup.name += `, ...`;
          } else existingGroup.name += `, ${item.name}`;
        } else {
          result.push({
            isParent: true,
            From,
            To,
            name: item.name,
            ghl_id,
            webhook_type,
            key: `${ghl_id},${webhook_type}`,
            mbName,
            ghlName,
            mb_id,
            mt_id,
            children: [{ key: item.secret_key, ghl_id, ghlName, mbName, mb_id, mt_id, webhook_type, From, To, ...rest }],
          });
        }
      }

      return result;
    }, []);

    return groupedData;
  };

  const expandRow = (rowKey) => {
    setExpandedRowKeys([...expandedRowKeys, rowKey]);
  };

  // Programmatically collapse a row
  const collapseRow = (rowKey) => {
    setExpandedRowKeys(expandedRowKeys.filter((key) => key !== rowKey));
  };

  return (
    <>
      {openClassDrawer && <NestedClassBookingDrawerNewUI open={openClassDrawer} setOpen={(bool) => setOpenClassDrawer(bool)} data={classBookingNestedWebhooks} type={classBookingNestedWebhooks?.webhook_type} />}
      {openConnectionManage && <ConnectionManage open={openConnectionManage} setOpen={(bool) => setOpenConnectionManage(bool)} existingData={connectionManageData} type={connectionManageData?.webhook_type} reloadWebhookData={reloadWebhookData} />}
      {openConnectionDelete && <ConnectionDelete open={openConnectionDelete} setOpen={(bool) => setOpenConnectionDelete(bool)} webhookData={connectionDeleteData} type={connectionDeleteData?.webhook_type} reloadWebhookData={reloadWebhookData} />}
      <Table
        columns={columns}
        rowSelection={{
          ...rowSelection,
          checkStrictly: false,
        }}
        loading={dataLoader.ghl_appointment && dataLoader.ghl_class && dataLoader.ghl_contact && dataLoader.mindbody_webhook && dataLoader.ghl_to_mt_contact && dataLoader.ghl_to_mt_class && dataLoader.ghl_to_mt_membership && dataLoader.marianatek_webhook ? false : true}
        scroll={{ y: "80vh" }}
        expandable={{
          expandedRowKeys,
          onExpand: (expanded, record) => {
            if (expanded) {
              setExpandedRowKeys([...expandedRowKeys, record.key]);
            } else {
              setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key));
            }
          },
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              const isEditClicked = event.target.classList.contains("edit-link");
              const isDeleteClicked = event.target.classList.contains("delete-link");
              if (record?.isParent) {
                if (isDeleteClicked) {
                  handleConnectionDeleteModal(record);
                } else if (expandedRowKeys.includes(record.key)) {
                  // Row is already expanded, so collapse it
                  collapseRow(record.key);
                } else {
                  // Row is not expanded, so expand it
                  expandRow(record.key);
                }
              } else if (isEditClicked) {
                handleConnectionManageModal(record);
              } else if (isDeleteClicked) {
                handleConnectionDeleteModal(record);
              } else {
                handleNestedClassBookingModal(record);
              }
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {
              if (record?.isParent) {
                event.target.style.cursor = "pointer";
              } else {
                event.target.style.cursor = "pointer";
              } // Change cursor to pointer on mouse enter
            },
            onMouseLeave: (event) => {
              //   event.target.style.cursor = "default"; // Change cursor back to default on mouse leave
            },
          };
        }}
        dataSource={groupTotalData(appointmentWebhooks, contactWebhooks, classBookingWebhooks, mindbodyWebhooks, membershipWebhooks, ghlToMtContactWebhooks, ghlToMtClassWebhooks, ghlToMtMembershipWebhooks, marianatekWebhooks)}
      />
    </>
  );
};
export default ConnectionTableNewUI;
