import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GHLAuthTokenGenCallback } from "../../helpers/GhlApi";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Toaster, toast } from "react-hot-toast";

const OAuth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const oauthCallbackFunc = async () => {
    let toastBox = toast;
    try {
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get("code");
      const ghlAuthTokenGenCallbackResult = await GHLAuthTokenGenCallback(code);
      console.log(ghlAuthTokenGenCallbackResult);
      toast.success("Account added!", {
        id: toastBox,
      });
      navigate("/dashboard/connections");
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
      console.log(error);
      navigate("/dashboard/connections");
    }
  };

  useEffect(() => {
    oauthCallbackFunc();
  }, []);
  return (
    <div>
      <Toaster position="top-center" reverseOrder={false}></Toaster>{" "}
      <Spin
        fullscreen
        tip={"integrating..."}
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 36,
            }}
            spin
          />
        }
      />
    </div>
  );
};

export default OAuth;
