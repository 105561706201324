import { createRef, forwardRef, useImperativeHandle, useState } from "react";
import { Modal, Button } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

export const confirmModalRef = createRef();

const ConfirmModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(() => {});
  const [desc, setDesc] = useState("");
  const [title, setTitle] = useState("");
  const handleClose = () => setOpen(false);

  useImperativeHandle(ref, () => ({
    open: (desc, title, onOk) => {
      setDesc(desc);
      setTitle(title);
      setOpen(true);
      setConfirm(() => onOk);
    },
    close: handleClose,
  }));

  return (
    <Modal
      zIndex={10000}
      title={
        <div className="space-x-4 flex justify-start items-center mx-auto">
          <ExclamationCircleFilled className="text-red-600 text-2xl" />
          <span className="text-xl font-manrope font-extrabold ">{title}</span>
        </div>
      }
      open={open}
      // onOk={() => {
      //   confirm();
      //   handleClose();
      // }}
      // onCancel={handleClose}
      style={{
        padding: "18px",
      }}
      footer={[
        <Button type="default" onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          type="default"
          danger
          onClick={() => {
            confirm();
            handleClose();
          }}
        >
          Confirm
        </Button>,
      ]}
    >
      <div className="flex justify-start items-center mx-auto pl-10 pb-8">
        <span className="text-base font-manrope">{desc}</span>
      </div>
    </Modal>
  );
});

ConfirmModal.displayName = "ConfirmModal";

export default ConfirmModal;
