import React, { useEffect, useState } from "react";
import { Alert, Form, Input, Select, Spin } from "antd";
import { getGHLAvailAccounts, getMBContactAvailAccounts } from "../../../../../../helpers/AppUserApi";
import { toast } from "react-hot-toast";
const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const LinkAccountsForm = ({ data, setData, webhook_type, setupDifficulty }) => {
  const [ghlAvailAccounts, setGhlAvailAccounts] = useState([]);
  const [mbAvailAccounts, setMbAvailAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const formRef = React.useRef(null);

  const onFinish = (values) => {};

  const handleGhlAccountSelect = (e) => {
    setData({ ...data, ghl_id: e, ghlName: ghlAvailAccounts.filter((element) => element.id === e)?.[0].name });
  };

  const handleMBAccountSelect = (e) => {
    const apikey = e.split(",")[0];
    const siteid = e.split(",")[1];
    setData({ ...data, mb_apikey: apikey, mb_siteid: siteid, mbName: mbAvailAccounts.filter((element) => element.apikey === apikey && element.siteid === siteid)[0]?.name });
  };

  const getGHLContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getGHLAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setGhlAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const getMBContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getMBContactAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setMbAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setData((prevData) => ({
      ...prevData,
      name: value,
    }));
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    getGHLContactAvailAccFunc();
    getMBContactAvailAccFunc();
  }, []);
  // (() => {
  //   formRef.current?.setFieldsValue({
  //     ghlAccount: data?.ghl_id ? data.ghl_id : null,
  //     mbAccount: data?.mb_apikey ? `${data.mb_apikey},${data.mb_siteid}` : null,
  //   });
  // })();

  useEffect(() => {
    if (!hasLoaded) {
      formRef.current?.setFieldsValue({
        ghlAccount: data?.ghl_id ? data.ghl_id : null,
        mbAccount: data?.mb_apikey ? `${data.mb_apikey},${data.mb_siteid}` : null,
      });
      setHasLoaded(true); // Set the flag to true after executing the code
    }
  }, [data, hasLoaded]);

  return (
    <Spin spinning={loading} className="my-6">
      <Form
        {...layout}
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: 600,
        }}
      >
        {!setupDifficulty ? (
          <>
            <Input addonBefore="Connection name" onChange={handleInputChange} defaultValue={data?.name || ""} placeholder="Enter Name" className="min-w-max mb-4" />
            <Form.Item
              name="ghlAccount"
              label="GoHighLevel account"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Select an account" onChange={handleGhlAccountSelect} disabled>
                {ghlAvailAccounts.length !== 0 &&
                  ghlAvailAccounts.map((data, index) => {
                    return (
                      <Option key={index} value={data.id}>
                        {data.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </>
        ) : (
          <>
            <div className="mb-4">
              <Alert type="info" className="bg-gray-100 text-gray-700 border-gray-500" message={"Select GoHighLevel account separately in Manage Services tab"} />
            </div>
            <Input addonBefore="Connection name" onChange={handleInputChange} defaultValue={data?.name || ""} placeholder="Enter Name" className="min-w-max mb-4" />
          </>
        )}
        <Form.Item
          name="mbAccount"
          label="Mindbody account"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="Select an account" onChange={handleMBAccountSelect} disabled>
            {mbAvailAccounts.length !== 0 &&
              mbAvailAccounts.map((data, index) => {
                return (
                  <Option key={index} value={`${data.apikey},${data.siteid}`}>
                    {data?.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
      </Form>
    </Spin>
  );
};
export default LinkAccountsForm;
