import {
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import React from "react";
import { BsFillQuestionCircleFill } from "react-icons/bs";

const HintHandler = ({ headName }) => {
  return (
    <Popover>
      <PopoverHandler>
        <button variant="text" type="button" className="pl-1 relative">
          <BsFillQuestionCircleFill className="pt-0" />
        </button>
      </PopoverHandler>
      <PopoverContent>
        {headName === "Status"
          ? "Account status can be activated or deactivated"
          : headName === "Override"
          ? "Enabling it will disable any operation by which account status can be changed. Account Status can be changed manually only!"
          : ""}
      </PopoverContent>
    </Popover>
  );
};

export default HintHandler;
