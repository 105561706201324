import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Card, Typography, Input, CardBody, Select, Option } from "@material-tailwind/react";
import { useFormik } from "formik";
import { Toaster, toast } from "react-hot-toast";
import { GHLDataUpdate, addNewCalendar, getAllCalendarDetails } from "../../../../../helpers/GhlApi";
import { useMediaQuery } from "react-responsive";

const EditModalGhl = ({ open, setOpen, ghlDetails }) => {
  const [name, setName] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  const [calendarDetails, setCalendarDetails] = useState([]);
  const [calendarFetched, setCalendarFetched] = useState(false);
  const [selectCalendar, setSelectCalendar] = useState(null);

  const isMobileScreen = useMediaQuery({ query: "(max-width: 1045px)" });
  const isMobileScreenShorter = useMediaQuery({ query: "(max-width: 487px)" });
  const excessModalHeight = useMediaQuery({ query: "(min-height: 600px)" });

  const ghlformik = useFormik({
    initialValues: {
      apikey: "",
      name: "",
      calendartags: "",
      id: "",
    },
    validate: false,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      let toastBox = toast;
      values.id = ghlDetails[0].id;
      if (selectValue === "name") {
        if (name) {
          values.name = name;
        } else values.name = ghlDetails[0].name;
        toast.loading("Loading...", {
          id: toastBox,
        });
        let invokePromise = GHLDataUpdate(values);
        invokePromise.then(
          (data) => {
            toast.success("Account Name Changed", {
              id: toastBox,
            });
            window.location.reload();
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        );
      } else if (selectValue === "calendar") {
        values.id = ghlDetails[0].id;
        const calendarId = selectCalendar;
        const calendarName = calendarDetails.filter((element) => element.id === calendarId)[0].name;
        const newValues = { ...values, calendarId, calendarName };
        toast.loading("Loading...", {
          id: toastBox,
        });
        let invokePromise = addNewCalendar(newValues);
        invokePromise.then(
          (data) => {
            toast.success("New Calendar Added", {
              id: toastBox,
            });
            window.location.reload();
          },
          (msg) => {
            if (msg.includes("Duplicate")) {
              toast.error(`Calendar already exists`, {
                id: toastBox,
              });
            } else {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
            setSelectValue(null);
            setCalendarDetails([]);
          }
        );
      }
    },
  });

  const getCalendarFunc = () => {
    let toastBox = toast;
    const getCalendarDetailsPromise = getAllCalendarDetails({
      ghl_id: ghlDetails[0].id,
    });
    getCalendarDetailsPromise
      .then(
        (data) => {
          setCalendarDetails(data);
          setCalendarFetched(true);
        },
        (msg) => {
          if (msg.includes("401")) {
            toast.error("Invalid Api-key entered", {
              id: toastBox,
            });
          } else {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  useEffect(() => {
    setCalendarFetched(false);
  }, [selectValue]);

  const handleOpen = () => {
    setSelectValue(null);
    setCalendarDetails([]);
    setOpen(!open);
  };

  const handleConfirmButton = () => {
    setOpen(!open);
    ghlformik.handleSubmit();
  };

  const handleNameField = (e) => {
    setName(e.target.value);
  };

  const handleSelectChange = (e) => {
    setSelectValue(e);
    setSelectCalendar(null);
  };

  const handleCalendarChange = (event) => {
    setSelectCalendar(event);
  };

  return (
    <Fragment>
      <Dialog open={open} handler={handleOpen} size={isMobileScreen ? (isMobileScreenShorter ? "xxl" : "xl") : "md"} className={!excessModalHeight ? "overflow-y-scroll" : "overflow-y-visible"} style={{ maxHeight: "90vh" }}>
        <DialogHeader>Go High Level Integration Details</DialogHeader>
        <DialogBody divider>
          <Card className="w-96 mx-auto justify-center loginCard">
            <form>
              <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
                Account details are listed below
              </Typography>
              {open && (
                <CardBody className="flex flex-col gap-4">
                  {ghlDetails[0]?.accessToken ? <Input label="Location Id" size="lg" defaultValue={`Location Id: ${ghlDetails[0].locationId}`} disabled /> : <Input label="Api key" size="lg" defaultValue={`API Key: ${ghlDetails[0].apikey}`} disabled />}
                  <Select label="Select edit type" value={selectValue} onChange={handleSelectChange}>
                    <Option value="name">Account Name</Option>
                    <Option value="calendar">Add Calendar</Option>
                  </Select>

                  {selectValue === "name" && <Input label="Account name" size="lg" defaultValue={name === null ? ghlDetails[0].name : name} onChange={handleNameField} />}
                  {selectValue === "calendar" && !calendarFetched && getCalendarFunc()}
                  {selectValue === "calendar" && calendarDetails.length !== 0 && (
                    <>
                      <Select variant="outlined" label="Select calendar" onChange={handleCalendarChange} value={selectCalendar ? selectCalendar : ""}>
                        {calendarDetails.map((data) => {
                          return (
                            <Option key={data.id} value={data.id}>
                              {data.name}
                            </Option>
                          );
                        })}
                      </Select>

                      <Input label="Custom tags (if any)" size="lg" {...ghlformik.getFieldProps("calendartags")} />
                    </>
                  )}
                </CardBody>
              )}
            </form>
          </Card>
        </DialogBody>
        <DialogFooter>
          <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="green" onClick={handleConfirmButton}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default EditModalGhl;
