import React, { useContext, useEffect, useState } from "react";
import { Tabs, TabsHeader, Tab, Spinner, IconButton } from "@material-tailwind/react";
import "./ActivityLog.css";
import { VscError } from "react-icons/vsc";
import { BsCheck2Circle } from "react-icons/bs";
import { GrRefresh } from "react-icons/gr";
import { AiFillAppstore, AiOutlineStop } from "react-icons/ai";
import TransactionTable from "./subComponents/TransactionTable";

import { toast } from "react-hot-toast";
import { fetchAllTransactionsSpecificUser, fetchFilteredTransactionsSpecificUser } from "../../../helpers/AppTransactionApi";
import AuthContext from "../../../contexts/AuthContextProvider";
import { Button, Checkbox, Col, ConfigProvider, DatePicker, Popover, Row, Space, Typography } from "antd";
import Search from "antd/es/input/Search";
import moment from "moment/moment";

const { RangePicker } = DatePicker;
const ActivityLog = () => {
  const { auth } = useContext(AuthContext);
  const isAdmin = auth?.userType?.includes("admin");
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedTab, setSelectedTab] = useState("listall");
  const [nestedTransactionData, setNestedTransactionData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [loadedData, setLoadedData] = useState([
    {
      index: "transactionData",
      status: false,
    },
    {
      index: "fetchTransactionExec",
      status: false,
    },
    {
      index: "processingTransactionFetch",
      status: false,
    },
  ]);

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [dateFilterSelectedRaw, setDateFilterSelectedRaw] = useState([]);
  const [dateFilterSelected, setDateFilterSelected] = useState([]);
  const [eventFilterSelected, setEventFilterSelected] = useState([]);

  const handleLoadedStatus = (status, index) => {
    // console.log(e, index)
    setLoadedData((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          status: status,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  // const transactionData = [
  //   {
  //     transactionId: "1",
  //     from: "GoHighLevel",
  //     to: "Mindbody",
  //     eventType: "Contact Create",
  //     status: "completed",
  //     created_at: "2023-06-22 18:36:11",
  //     updated_at: "2023-06-22 18:36:12",
  //     firstName: "Jared",
  //     lastName: "Gobler",
  //     email: "test2@gmail.com",
  //     phone: "+18592938593"
  //   },
  //   {
  //     transactionId: "2",
  //     from: "Mindbody",
  //     to: "GoHighLevel",
  //     eventType: "Class booking",
  //     status: "error",
  //     created_at: "2023-06-22 18:36:11",
  //     updated_at: "2023-06-22 18:36:12",
  //     firstName: "Jared",
  //     lastName: "Gobler",
  //     email: "test2@gmail.com",
  //     phone: "+18592938593",
  //     className: "Reset Rotation",
  //     clientId: "109432",
  //     startDate: "2023-06-25T15:00:00Z",
  //     endDate: "2023-06-25T16:00:00Z",
  //     siteid: "5730721"
  //   },
  //   {
  //     transactionId: "3",
  //     from: "GoHighLevel",
  //     to: "Mindbody",
  //     eventType: "Contact Create",
  //     status: "completed",
  //     created_at: "2023-06-22 18:36:11",
  //     updated_at: "2023-06-22 18:36:12",
  //     firstName: "Soham",
  //     lastName: "Sarkar",
  //     email: "test@gmail.com",
  //     phone: "+918599385939"
  //   },
  //   {
  //     transactionId: "4",
  //     from: "GoHighLevel",
  //     to: "Mindbody",
  //     eventType: "Contact Create",
  //     status: "error",
  //     created_at: "2023-06-22 18:36:11",
  //     updated_at: "2023-06-22 18:36:12",
  //     firstName: "John",
  //     lastName: "Doe",
  //     email: "test3@gmail.com",
  //     phone: "+18592938593"
  //   },
  //   {
  //     transactionId: "5",
  //     from: "GoHighLevel",
  //     to: "Mindbody",
  //     eventType: "Contact Create",
  //     status: "completed",
  //     created_at: "2023-06-22 18:36:11",
  //     updated_at: "2023-06-22 18:36:12",
  //   },
  //   {
  //     transactionId: "6",
  //     from: "GoHighLevel",
  //     to: "Mindbody",
  //     eventType: "Contact Create",
  //     status: "completed",
  //     created_at: "2023-06-22 18:36:11",
  //     updated_at: "2023-06-22 18:36:12",
  //   },
  //   {
  //     transactionId: "7",
  //     from: "GoHighLevel",
  //     to: "Mindbody",
  //     eventType: "Contact Create",
  //     status: "completed",
  //     created_at: "2023-06-22 18:36:11",
  //     updated_at: "2023-06-22 18:36:12",
  //   },
  // ];

  const handleTabChange = (value) => {
    setSelectedTab(value);
  };

  const fetchAllTransactionsSpecificUserFunc = () => {
    let toastBox = toast;
    setPageNumber(1);
    setEventFilterSelected([]);
    setDateFilterSelected([]);
    setDateFilterSelectedRaw([]);
    handleLoadedStatus(true, "processingTransactionFetch");
    const fetchPromise = fetchFilteredTransactionsSpecificUser();
    fetchPromise
      .then(
        (data) => {
          if (data) {
            const formattedData = data.map((item) => {
              const date = new Date(item.updated_at);
              const create_date = new Date(item.created_at);
              const updated_from = item?.senderApp === "ghl" ? "Go High Level" : item?.senderApp === "mb" ? "Mindbody" : item?.senderApp;
              const updated_to = item?.receiverApp === "ghl" ? "Go High Level" : item?.receiverApp === "mb" ? "Mindbody" : item?.receiverApp;
              const formattedDate = new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }).format(date);
              const formattedCreateDate = new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }).format(create_date);
              return {
                ...item,
                updated_at: formattedDate,
                created_at: formattedCreateDate,
                // senderApp: updated_from,
                // receiverApp: updated_to,
              };
            });
            setTransactionData(formattedData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)));
          }
        },
        (msg) => {}
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        handleLoadedStatus(true, "transactionData");
        handleLoadedStatus(false, "processingTransactionFetch");
      });
  };
  useEffect(() => {
    const fetchAllTransactionsSpecificUserFunc = () => {
      let toastBox = toast;
      const fetchPromise = fetchFilteredTransactionsSpecificUser(pageNumber);
      fetchPromise
        .then(
          (data) => {
            if (data) {
              console.log(data);
              const formattedData = data.map((item) => {
                const date = new Date(item.updated_at);
                const create_date = new Date(item.created_at);
                const updated_from = item?.senderApp === "ghl" ? "Go High Level" : item?.senderApp === "mb" ? "Mindbody" : item?.senderApp;
                const updated_to = item?.receiverApp === "ghl" ? "Go High Level" : item?.receiverApp === "mb" ? "Mindbody" : item?.receiverApp;
                const formattedDate = new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }).format(date);
                const formattedCreateDate = new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }).format(create_date);
                return {
                  ...item,
                  updated_at: formattedDate,
                  created_at: formattedCreateDate,
                  // senderApp: updated_from,
                  // receiverApp: updated_to,
                };
              });
              setTransactionData(formattedData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)));
            }
          },
          (msg) => {}
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        })
        .finally(() => {
          handleLoadedStatus(true, "transactionData");
        });
    };
    if (!loadedData.filter((element) => element.index === "transactionData")[0].status) fetchAllTransactionsSpecificUserFunc();
    if (selectedTab === "listall") {
      setNestedTransactionData(transactionData);
    } else setNestedTransactionData(transactionData.filter((element) => element.status === selectedTab));
  }, [selectedTab, transactionData, loadedData]);

  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const options = [
    {
      label: "Class booked",
      value: "classBooking",
    },
    {
      label: "Class cancelled",
      value: "classCancel",
    },
    {
      label: "Appointment booked",
      value: "appointmentBooking",
    },
    {
      label: "Appointment cancelled",
      value: "appointmentCancel",
    },
    {
      label: "Client updated",
      value: "clientUpdate",
    },
    {
      label: "Contract purchased",
      value: "membershipAdd",
    },
    {
      label: "Contract updated / cancelled",
      value: "contractUpdate",
    },
    {
      label: "Membership declined",
      value: "membershipCancel",
    },
    {
      label: "Client created",
      value: "clientCreated",
    },
    {
      label: "Class updated",
      value: "classUpdate",
    },
  ];

  const onChange = (checkedValues) => {
    setEventFilterSelected(checkedValues);
  };

  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const handleTableChange = (pagination) => {
    // pagination.current is the new page number
    const newPage = pagination.current;
    const pageSize = pagination.pageSize;

    const totalData = newPage * pageSize;

    if (totalData === pageNumber * 100) handleLoadMoreTransactions(newPage);
  };
  const handleLoadMoreTransactions = async (newPage) => {
    // Your custom logic when the page changes
    setPageNumber(pageNumber + 1);
    try {
      let filterOptions = {
        filterDate: dateFilterSelected,
        filterEvent: eventFilterSelected,
      };
      if (dateFilterSelected?.length !== 0) {
        const startOfDay = new Date(dateFilterSelected[0]);
        startOfDay.setHours(0, 0, 0, 0);
        // Manually set the time to the last millisecond of the day for the end date
        const endOfDay = new Date(dateFilterSelected[1]);
        endOfDay.setHours(23, 59, 59, 999);
        filterOptions = {
          filterDate: [startOfDay.toISOString(), endOfDay.toISOString()],
          filterEvent: eventFilterSelected,
        };
      }
      const transactionResult = await fetchFilteredTransactionsSpecificUser(pageNumber + 1, filterOptions);
      // setTransactionData((prevTransactionData) => ({
      //   ...prevTransactionData,
      //   transactionResult: [...prevTransactionData, ...transactionResult].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)),
      // }));
      const formattedData = transactionResult.map((item) => {
        const date = new Date(item.updated_at);
        const create_date = new Date(item.created_at);
        const updated_from = item?.senderApp === "ghl" ? "Go High Level" : item?.senderApp === "mb" ? "Mindbody" : item?.senderApp;
        const updated_to = item?.receiverApp === "ghl" ? "Go High Level" : item?.receiverApp === "mb" ? "Mindbody" : item?.receiverApp;
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(date);
        const formattedCreateDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(create_date);
        return {
          ...item,
          updated_at: formattedDate,
          created_at: formattedCreateDate,
          // senderApp: updated_from,
          // receiverApp: updated_to,
        };
      });
      setTransactionData((prevState) => [...prevState, ...formattedData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))]);
      // console.log("transactionResult" + transactionResult);
    } catch (error) {
      console.log(error);
    }

    // Add your own logic or API calls here
  };

  useEffect(() => {
    if (selectedTab === "listall") {
      setNestedTransactionData(transactionData);
    } else setNestedTransactionData(transactionData.filter((element) => element.status === selectedTab));
    // eslint-disable-next-line
  }, [transactionData]);

  const handleSearch = (e) => {
    const searchText = e.target.value.toLowerCase();
    setSearchText(searchText);

    const filtered = nestedTransactionData.filter((record) => {
      // console.log(Object.values(record).join(""));
      return Object.values(record)
        .join("") // concatenate all values to a single string
        .toLowerCase()
        .includes(searchText);
    });

    setFilteredData(filtered);
  };

  const handleRangeChange = (dates, dateStrings) => {
    // dates is an array containing the start and end dates
    // dateStrings is an array containing the formatted start and end date strings
    //   console.log(dateStrings.map((dateString) => moment(dateString).toISOString()));
    // const startOfDay = new Date(dateStrings[0]);
    // startOfDay.setHours(0, 0, 0, 0);

    // // Manually set the time to the last millisecond of the day for the end date
    // const endOfDay = new Date(dateStrings[1]);
    // endOfDay.setHours(23, 59, 59, 999);
    setDateFilterSelectedRaw(dates);
    if (dateStrings?.includes("")) setDateFilterSelected([]);
    else setDateFilterSelected(dateStrings);
  };

  const filterDataByQuery = async () => {
    try {
      let filterOptions = {
        filterDate: dateFilterSelected,
        filterEvent: eventFilterSelected,
      };
      if (dateFilterSelected?.length !== 0) {
        const startOfDay = new Date(dateFilterSelected[0]);
        startOfDay.setHours(0, 0, 0, 0);
        // Manually set the time to the last millisecond of the day for the end date
        const endOfDay = new Date(dateFilterSelected[1]);
        endOfDay.setHours(23, 59, 59, 999);
        filterOptions = {
          filterDate: [startOfDay.toISOString(), endOfDay.toISOString()],
          filterEvent: eventFilterSelected,
        };
      }

      setPageNumber(1);
      const fetchFilteredTransactionsSpecificUserResult = await fetchFilteredTransactionsSpecificUser(1, filterOptions);

      if (fetchFilteredTransactionsSpecificUserResult?.length === 0) {
        setTransactionData([]);
      } else {
        const formattedData = fetchFilteredTransactionsSpecificUserResult.map((item) => {
          const date = new Date(item.updated_at);
          const create_date = new Date(item.created_at);
          const formattedDate = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(date);
          const formattedCreateDate = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(create_date);
          return {
            ...item,
            updated_at: formattedDate,
            created_at: formattedCreateDate,
            // senderApp: updated_from,
            // receiverApp: updated_to,
          };
        });
        setTransactionData((prevState) => [...formattedData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="md:w-full w-11/12">
      {/* <div
        className="overflow-y-visible bg-red-300"
        style={{ maxHeight: "90vh" }}
      >
        <AddConnectionModal
          open={addConnectionModal}
          setOpen={(bool) => setAddConnectionModal(bool)}
        />
      </div> */}

      <div className="w-full sm:pl-[4.5rem] sm:text-start mx-auto text-center">
        <h1 className="text-2xl">Activity Log</h1>
      </div>
      <div className="w-11/12 mt-0.5 mx-auto justify-center">
        <hr />
      </div>
      {loadedData.filter((element) => element.index === "transactionData")[0].status && (
        <div className="lg:flex justify-between items-center  max-w-[90%] mx-auto">
          <div className="w-full pt-5 mx-auto text-center justify-center items-center col-span-5 xl:ml-28 2xl:ml-80">
            <Tabs value="listall">
              <div className="max-w-screen-sm mx-auto justify-end text-end items-end">
                <TabsHeader>
                  <Tab value="listall" onClick={() => handleTabChange("listall")}>
                    <div className="flex items-center gap-2 text-sm sm:text-base">
                      <AiFillAppstore />
                      All
                    </div>
                  </Tab>
                  <Tab value="error" className="text-red-600" onClick={() => handleTabChange("error")}>
                    <div className="flex items-center gap-2  text-sm sm:text-base">
                      <VscError />
                      Errors
                    </div>
                  </Tab>
                  <Tab value="completed" className="text-green-600" onClick={() => handleTabChange("finished")}>
                    <div className="flex items-center gap-2  text-sm sm:text-base">
                      <BsCheck2Circle />
                      Finished
                    </div>
                  </Tab>
                  <Tab value="skipped" className="text-yellow-800" onClick={() => handleTabChange("skipped")}>
                    <div className="flex items-center gap-2  text-sm sm:text-base">
                      <AiOutlineStop />
                      Skipped
                    </div>
                  </Tab>
                </TabsHeader>
              </div>
            </Tabs>
          </div>

          <div className="pr-1 ml-2 mx-auto pt-5 relative lg:justify-end lg:text-end text-center">
            <Space>
              <Search
                allowClear
                size="middle"
                style={{
                  width: 200,
                }}
                id="search-input"
                placeholder="Search..."
                onChange={handleSearch}
                value={searchText}
                // onSearch={onSearch}
              />
              <ConfigProvider
                theme={{
                  components: {
                    Popover: {
                      titleMinWidth: 250,
                    },
                  },
                }}
              >
                <Popover
                  placement="leftTop"
                  arrow={false}
                  content={
                    <div className="">
                      <div>
                        <Typography.Text color="green" type="secondary">
                          Filter by events
                        </Typography.Text>
                        <hr className="my-2" />
                        <Checkbox.Group onChange={onChange} value={eventFilterSelected}>
                          <div className="max-w-[660px]">
                            <Row>
                              {options.map((element) => (
                                <Col span={8}>
                                  <Checkbox value={element.value}>{element.label}</Checkbox>
                                </Col>
                              ))}
                            </Row>
                          </div>
                        </Checkbox.Group>
                      </div>

                      <div className="mt-6">
                        <Typography.Text color="green" type="secondary">
                          Filter by Date
                        </Typography.Text>
                        <hr className="my-2" />
                        <RangePicker picker="range" value={dateFilterSelectedRaw} onChange={handleRangeChange} />
                      </div>

                      <div className="mx-auto justify-end mt-6 text-end">
                        <Space>
                          <Button
                            type="default"
                            className="bg-green-300"
                            onClick={() => {
                              filterDataByQuery();
                            }}
                          >
                            Filter
                          </Button>
                          <Button onClick={hide}>Close</Button>
                        </Space>
                      </div>
                    </div>
                  }
                  title={<h1 className="text-base">Choose filter options</h1>}
                  trigger="click"
                  open={open}
                  onOpenChange={handleOpenChange}
                >
                  <Button variant="outlined" color="gray">
                    Filter
                  </Button>
                </Popover>
              </ConfigProvider>
              <Button
                variant="outlined"
                color="gray"
                onClick={() => {
                  fetchAllTransactionsSpecificUserFunc(pageNumber);
                }}
              >
                {loadedData.filter((element) => element.index === "processingTransactionFetch")[0].status ? <Spinner width={15} /> : <GrRefresh className="hover:cursor-pointer" />}
              </Button>
            </Space>
          </div>
        </div>
      )}

      <div className="w-10/12 xl:w-full pt-5 mx-auto justify-center md:flex">
        {loadedData.filter((element) => element.index === "transactionData")[0].status ? (
          <>
            <TransactionTable transactionData={searchText !== "" ? filteredData : nestedTransactionData} isAdmin={isAdmin} setTransactionData={(val) => setTransactionData(val)} handleTableChange={handleTableChange} setSearchText={(val) => setSearchText(val)} searchText={searchText} />
          </>
        ) : (
          <div className="flex items-center" style={{ height: "calc(100vh - 250px)" }}>
            <Spinner className="h-12 w-12" />
          </div>
        )}
        {/* <ConnectionTable/> */}
      </div>
    </div>
  );
};

export default ActivityLog;
