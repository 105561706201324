import React, { useState } from "react";
import { Fragment } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Card,
  Typography,
  Input,
  CardBody,
} from "@material-tailwind/react";
import { useFormik } from "formik";
import { activeUser } from "../../../../../helpers/AppUserApi";
import { toast } from "react-hot-toast";
import { invokeMBWebhookUpdate } from "../../../../../helpers/WebhookApi";
import { useMediaQuery } from "react-responsive";

const EditModalMb = ({ open, setOpen, mbDetails }) => {
  const [name, setName] = useState(null);
  const [userId, setUserId] = useState(null);

  const isMobileScreen = useMediaQuery({ query: "(max-width: 1045px)" });
  const isMobileScreenShorter = useMediaQuery({ query: "(max-width: 487px)" });
  const excessModalHeight = useMediaQuery({ query: "(min-height: 600px)" });

  if (open) {
    let toastBox = toast;
    const activeUserPromise = activeUser();
    activeUserPromise
      .then((data) => {
        if (data.type === "admin") {
        } else if (data.type === "user") {
          setUserId(data.userid);
        }
      })
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  }

  const formik = useFormik({
    initialValues: {
      apikey: "",
      name: "",
      siteId: "",
      authorization: "",
    },
    validate: false,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      let toastBox = toast;
      values.apikey = mbDetails[0].apikey;
      if (name) {
        values.name = name;
      } else values.name = mbDetails[0].name;
      values.siteId = mbDetails[0].siteid;
      values.authorization = mbDetails[0].authorization;
      const oldApikey = mbDetails[0].apikey;
      const servicesId = ["addclient"];
      const newValues = { ...values, servicesId, userId, oldApikey };
      toast.loading("Loading...", {
        id: toastBox,
      });
      let invokePromise = invokeMBWebhookUpdate(newValues);
      invokePromise.then(
        (data) => {
          toast.success("MindBody Integration Changed", {
            id: toastBox,
          });
          window.location.reload();
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      );
    },
  });

  const handleOpen = () => {
    setName(null);
    setOpen(!open);
  };

  const handleConfirmButton = () => {
    setOpen(!open);
    formik.handleSubmit();
  };

  const handleNameField = (e) => {
    setName(e.target.value);
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        handler={handleOpen}
        size={isMobileScreen ? (isMobileScreenShorter ? "xxl" : "xl") : "md"}
        className={!excessModalHeight ? "overflow-y-scroll" : "overflow-y-visible"}
        style={{maxHeight: "90vh"}}
      >
        <DialogHeader>MindBody Integration Details</DialogHeader>
        <DialogBody divider>
          <Card className="w-96 mx-auto justify-center loginCard">
            <form>
              <Typography
                color="gray"
                className="mx-auto text-center mt-4 text-lg"
              >
                Account details are listed below
              </Typography>
              {open && (
                <CardBody className="flex flex-col gap-4">
                  <Input
                    label="Account name"
                    size="lg"
                    defaultValue={name ? name : mbDetails[0].name}
                    onChange={handleNameField}
                  />
                  <Input
                    label="Api key"
                    size="lg"
                    defaultValue={mbDetails[0].apikey}
                    disabled
                  />
                  <Input
                    label="site id"
                    size="lg"
                    defaultValue={mbDetails[0].siteid}
                    disabled
                  />
                </CardBody>
              )}
            </form>
          </Card>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={handleConfirmButton}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default EditModalMb;
