import { Drawer, Input, Space, Typography } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { toast } from "react-hot-toast";
import rightArrow from "../../../../data/images/right_arrow.png";
import { API_URL } from "../../../../helpers/AppApiUrls";

export const NestedClassBookingDrawerNewUI = ({ open, setOpen, data, type }) => {
  const onClose = () => {
    setOpen(false);
  };
  console.log(data);
  return (
    <Drawer
      title="Connection details"
      placement="bottom"
      width={500}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          {type === "mindbody_webhook" ? (
            <div className="mt-0 flex items-center justify-center gap-5 text-xl">
              Mindbody
              <img src={rightArrow} width={90} alt="logo" />
              GoHighLevel
            </div>
          ) : (
            <>
              {data?.mt_id ? (
                <div className="mt-0 flex items-center justify-center gap-5 text-xl">
                  GoHighLevel
                  <img src={rightArrow} width={90} alt="logo" />
                  MarianaTek
                </div>
              ) : (
                <div className="mt-0 flex items-center justify-center gap-5 text-xl">
                  GoHighLevel
                  <img src={rightArrow} width={90} alt="logo" />
                  Mindbody
                </div>
              )}
            </>
          )}
        </Space>
      }
    >
      <Title level={5}>Webhook Url</Title>
      <Input
        readOnly
        defaultValue={
          type === "contact_creation"
            ? `${API_URL}/gohighlevel/contact/create?id=${data?.secret_key}&flow=direct`
            : type === "manage_class"
            ? `${API_URL}/gohighlevel/appointment/book?id=${data?.secret_key}&flow=direct`
            : type === "manage_appointment"
            ? `${API_URL}/gohighlevel/appointments/book?id=${data?.secret_key}&flow=direct`
            : type === "ghl_purchase_membership"
            ? `${API_URL}/gohighlevel/membership/webhook?id=${data?.secret_key}&flow=direct`
            : type === "mindbody_webhook"
            ? `${API_URL}/mindbody/webhook/working?id=${data?.secret_key}&flow=direct`
            : type === "ghl_to_mt_contact_creation"
            ? `${API_URL}/marianatek/contact/create?id=${data?.secret_key}&flow=direct`
            : type === "ghl_to_mt_manage_class"
            ? `${API_URL}/marianatek/reservation/book?id=${data?.secret_key}&flow=direct`
            : type === "ghl_to_mt_manage_membership"
            ? `${API_URL}/marianatek/membership/purchase?id=${data?.secret_key}&flow=direct`
            : type === "marianatek_webhook"
            ? `${API_URL}/marianatek/webhook/event?flow=direct`
            : null
        }
        className="hover:cursor-pointer"
        onClick={(e) => {
          let toastBox = toast;
          toast.success("Copied", {
            id: toastBox,
          });
          return navigator.clipboard.writeText(e.target.value);
        }}
      />
      <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 gap-x-6 mt-3">
        <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
          <div className="mx-auto justify-start w-full">
            <Typography.Text className="text-base font-medium">{data?.mt_id ? `MarianaTek account` : `Mindbody account`}</Typography.Text>
          </div>
          <div className="mx-auto justify-start w-full">
            <div className="max-w-sm">
              <Input readOnly defaultValue={data?.mbName || data?.mtName} />
            </div>
          </div>
        </div>
        {type !== "mindbody_webhook" && type !== "marianatek_webhook" && (
          <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
            <div className="mx-auto justify-start w-full">
              <Typography.Text className="text-base font-medium">GoHighLevel account</Typography.Text>
            </div>
            <div className="mx-auto justify-start w-full">
              <div className="max-w-sm">
                <Input readOnly defaultValue={data?.ghlName} />
              </div>
            </div>
          </div>
        )}
        {type === "manage_appointment" && (
          <>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Calendar</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.calendarName} className="col-span-1" />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Location</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.locationName} className="col-span-1" />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Session</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.sessionName} className="col-span-1" />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Booking event</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.bookingActive === "true" ? "Active" : "Inactive"} className={data?.bookingActive === "true" ? "col-span-1 text-green-500" : "col-span-1 text-red-500"} />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Cancelling event</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.cancelActive === "true" ? "Active" : "Inactive"} className={data?.cancelActive === "true" ? "col-span-1 text-green-500" : "col-span-1 text-red-500"} />
                </div>
              </div>
            </div>
          </>
        )}

        {type === "ghl_purchase_membership" && (
          <>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Promocode</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.promocode} className="col-span-1" />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Discount</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={`${data?.offerPercent} %`} className="col-span-1" />
                </div>
              </div>
            </div>
            {data?.purchaseType === "contract" || data?.purchaseType === "" ? (
              <>
                <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
                  <div className="mx-auto justify-start w-full">
                    <Typography.Text className="text-base font-medium">Contract Id</Typography.Text>
                  </div>
                  <div className="mx-auto justify-start w-full">
                    <div className="max-w-sm">
                      <Input readOnly defaultValue={data?.contractId} className="col-span-1" />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
                  <div className="mx-auto justify-start w-full">
                    <Typography.Text className="text-base font-medium">Contract Name</Typography.Text>
                  </div>
                  <div className="mx-auto justify-start w-full">
                    <div className="max-w-sm">
                      <Input readOnly defaultValue={data?.contractName} className="col-span-1" />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
                  <div className="mx-auto justify-start w-full">
                    <Typography.Text className="text-base font-medium">Service Id</Typography.Text>
                  </div>
                  <div className="mx-auto justify-start w-full">
                    <div className="max-w-sm">
                      <Input readOnly defaultValue={data?.serviceId} className="col-span-1" />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
                  <div className="mx-auto justify-start w-full">
                    <Typography.Text className="text-base font-medium">Service Name</Typography.Text>
                  </div>
                  <div className="mx-auto justify-start w-full">
                    <div className="max-w-sm">
                      <Input readOnly defaultValue={data?.serviceName} className="col-span-1" />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {type === "manage_class" && (
          <>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Calendar</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.calendarName} className="col-span-1" />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Class name</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.className} className="col-span-1" />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Booking event</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.bookingActive === "true" ? "Active" : "Inactive"} className={data?.bookingActive === "true" ? "col-span-1 text-green-500" : "col-span-1 text-red-500"} />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Cancelling event</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.cancelActive === "true" ? "Active" : "Inactive"} className={data?.cancelActive === "true" ? "col-span-1 text-green-500" : "col-span-1 text-red-500"} />
                </div>
              </div>
            </div>
          </>
        )}

        {type === "ghl_to_mt_manage_class" && (
          <>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Calendar</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.calendarName} className="col-span-1" />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Class type Id</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.classTypeId} className="col-span-1" />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Booking event</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.bookingActive === "true" ? "Active" : "Inactive"} className={data?.bookingActive === "true" ? "col-span-1 text-green-500" : "col-span-1 text-red-500"} />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Cancelling event</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.cancelActive === "true" ? "Active" : "Inactive"} className={data?.cancelActive === "true" ? "col-span-1 text-green-500" : "col-span-1 text-red-500"} />
                </div>
              </div>
            </div>
          </>
        )}

        {type === "mindbody_webhook" && (
          <>
            {/* <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Appointment booking</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.appointmentBooking === "true" ? "Active" : "Inactive"} className={data?.appointmentBooking === "true" ? "text-green-500" : "col-span-1 text-red-500"} />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Appointment cancellation</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.appointmentCancel === "true" ? "Active" : "Inactive"} className={data?.appointmentCancel === "true" ? " text-green-500" : "col-span-1 text-red-500"} />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium"> Class booking</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.classBooking === "true" ? "Active" : "Inactive"} className={data?.classBooking === "true" ? " text-green-500" : "col-span-1 text-red-500"} />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium"> Class cancellation</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.classCancel === "true" ? "Active" : "Inactive"} className={data?.classCancel === "true" ? " text-green-500" : "col-span-1 text-red-500"} />
                </div>
              </div>
            </div> */}
            {/* <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Client creation</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.clientCreated === "true" ? "Active" : "Inactive"} className={data?.clientCreated === "true" ? " text-green-500" : "col-span-1 text-red-500"} />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Membership purchase</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.membershipAdd === "true" ? "Active" : "Inactive"} className={data?.membershipAdd === "true" ? " text-green-500" : "col-span-1 text-red-500"} />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 justify-start gap-4 place-items-center">
              <div className="mx-auto justify-start w-full">
                <Typography.Text className="text-base font-medium">Membership cancellation</Typography.Text>
              </div>
              <div className="mx-auto justify-start w-full">
                <div className="max-w-sm">
                  <Input readOnly defaultValue={data?.membershipCancel === "true" ? "Active" : "Inactive"} className={data?.membershipCancel === "true" ? " text-green-500" : "col-span-1 text-red-500"} />
                </div>
              </div>
            </div> */}
            {/* <div className="flex flex-wrap gap-4 place-items-center">
              <div>
                <Typography.Text className="text-base font-medium"> Class booking</Typography.Text>
              </div>
              <div className="max-w-sm text-green-400">
                <Input readOnly defaultValue={data?.classBooking === "true" ? "Active" : "Inactive"} className={data?.classBooking === "true" ? " text-green-500" : "col-span-1 text-red-500"} />
              </div>
            </div> */}

            {/* <div className="grid w-5/6 grid-cols-2 gap-2">
              <div className="mx-auto mt-0.5">
                <Text className="text-base font-medium">Cancelling event</Text>
              </div>
              <div>
                <Input readOnly defaultValue={data?.cancelActive === "true" ? "Active" : "Inactive"} className={data?.cancelActive === "true" ? "col-span-1 text-green-500" : "col-span-1 text-red-500"} />
              </div>
            </div> */}
          </>
        )}
      </div>
    </Drawer>
  );
};
