import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Button, Card, CardBody, IconButton, Input, Option, Select, Spinner, Typography } from "@material-tailwind/react";
import { getCalendarDetails, getClassDetails, getGHLAvailAccounts, getLocationDetailsForGhl, getMBAvailAccounts, getSessionDetailsForGhl } from "../../../../helpers/AppUserApi";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import { addMBWebhook } from "../../../../helpers/WebhookApi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { MdDoneAll } from "react-icons/md";

const MbToGhlForm = ({ userId }) => {
  const [mbAvailAccounts, setMbAvailAccounts] = useState([]);
  const [ghlAvailAccounts, setGhlAvailAccounts] = useState([]);
  const [ghlCalendarDetails, setGhlCalendarDetails] = useState([]);
  const [classDetails, setClassDetails] = useState([]);
  const [sessionDetails, setSessionDetails] = useState([]);
  const [locationDetails, setLocationDetails] = useState([]);
  const [mbAccount, setMbAccount] = useState(null);
  const [ghlAccount, setGhlAccount] = useState(null);
  const [ghlCalendar, setGhlCalendar] = useState(null);
  const [ghlAppointmentCalendar, setGhlAppointmentCalendar] = useState(null);
  const [mbClass, setMbClass] = useState(null);
  const [mbSession, setMbSession] = useState(null);
  const [mbLocation, setMbLocation] = useState(null);
  const [mbSelectClass, setMbSelectClass] = useState(false);
  const [memberFieldID, setMemberFieldID] = useState(null);
  const [mbAccountApiKey, setMbAccountApiKey] = useState(null);
  const [mbAccountSiteId, setMbAccountSiteId] = useState(null);
  const [services, setServices] = useState([]);
  const [calendarRequired, setCalendarRequired] = useState(false);
  const [appointmentCalendarRequired, setAppointmentCalendarRequired] = useState(false);
  const [memberFieldRequired, setMemberFieldRequired] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadedData, setLoadedData] = useState([
    {
      index: "account",
      status: false,
    },
    {
      index: "apptCalendar",
      status: false,
    },
    {
      index: "location",
      status: false,
    },
    {
      index: "session",
      status: false,
    },
  ]);

  const handleLoadedStatus = (status, index) => {
    // console.log(e, index)
    setLoadedData((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          status: status,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const getGHLAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getGHLAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) {
            setGhlAvailAccounts(data);
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const handleMbAcc = (e) => {
    const apikey = e.split(",")[0];
    const siteid = e.split(",")[1];
    setMbAccountApiKey(apikey);
    setMbAccountSiteId(siteid);
    setMbAccount(e);
    setGhlCalendar(null);
    setMbClass(null);
    setMbSession(null);
    setMbLocation(null);
    setMbSelectClass(false);
    setServices([]);
    setGhlCalendarDetails([]);
    setClassDetails([]);
    setSessionDetails([]);
    setLocationDetails([]);
    setGhlAccount(null);
    setMemberFieldID(null);
  };

  const handleGhlAcc = (e) => {
    setGhlCalendarDetails([]);
    setClassDetails([]);
    setLocationDetails([]);
    setSessionDetails([]);
    setGhlCalendar(null);
    setMbClass(null);
    setMbSession(null);
    setMbLocation(null);
    setMbSelectClass(false);
    if (ghlAvailAccounts.filter((element) => element.apikey === e && element.memberFieldID !== "").length !== 0) {
      setMemberFieldID(ghlAvailAccounts.filter((element) => element.apikey === e)[0].memberFieldID);
    } else {
      setMemberFieldID(null);
    }
    setGhlAccount(e);
    selectiveGhlAccountFunc(e);
  };

  const handleCalendarSelect = (e) => {
    setGhlCalendar(e);
  };

  const handleAppointmentCalendarSelect = (e) => {
    setGhlAppointmentCalendar(e);
  };

  const handleClassSelect = (e) => {
    setMbClass(e);
  };

  const handleLocationSelect = (e) => {
    setMbLocation(e);
  };

  const handleSessionSelect = (e) => {
    setMbSession(e);
  };

  const handleServiceSelect = (event) => {
    const eventData = event.split("-");
    let checker = true;
    if (eventData[0] === "classbooking" || eventData[0] === "classcancel") setCalendarRequired(true);
    if (eventData[0] === "appointmentbooking" || eventData[0] === "appointmentcancel") setAppointmentCalendarRequired(true);
    if (eventData[0] === "addmembership" || eventData[0] === "cancelmembership") setMemberFieldRequired(true);
    services.forEach((element) => {
      if (eventData[0] === element.split(":")[0]) checker = false;
    });
    if (checker) setServices((prevValues) => [...prevValues, `${eventData[0]}: ${eventData[1]}`]);
  };

  const handleServiceRemoveItem = (e) => {
    const name = e.target.value;
    services
      .filter((element) => element.split(":")[0] !== name.split(":")[0])
      .forEach((element) => {
        if (element.split(":")[0] !== "addmembership" && element.split(":")[0] !== "cancelmembership") setMemberFieldRequired(false);
        else setMemberFieldRequired(true);
      });
    services
      .filter((element) => element.split(":")[0] !== name.split(":")[0])
      .forEach((element) => {
        if (element.split(":")[0] !== "classbooking" && element.split(":")[0] !== "classcancel") {
          setCalendarRequired(false);
          setGhlCalendar(null);
          setMbClass(null);
        }
      });
    // if (name.split(":")[0] === "classbooking") {
    //   setCalendarRequired(false);
    //   setGhlCalendar(null);
    //   setMbClass(null);
    // }
    services
      .filter((element) => element.split(":")[0] !== name.split(":")[0])
      .forEach((element) => {
        if (element.split(":")[0] !== "appointmentbooking" && element.split(":")[0] !== "appointmentcancel") {
          setAppointmentCalendarRequired(false);
          setGhlAppointmentCalendar(null);
          setMbSession(null);
          setMbLocation(null);
        }
      });
    // if (name.split(":")[0] === "appointmentbooking") {
    //   setAppointmentCalendarRequired(false);
    //   setGhlAppointmentCalendar(null);
    //   setMbSession(null);
    //   setMbLocation(null);
    // }
    setServices(services.filter((item) => item !== name));

    if (services.filter((element) => element.split(":")[0] !== name.split(":")[0]).length === 0) {
      setGhlAccount(null);
      setGhlCalendar(null);
      setMbClass(null);
      setMbSession(null);
      setMbLocation(null);
    }
  };

  useEffect(() => {
    const getMBAvailAccFunc = () => {
      let toastBox = toast;
      const getAvailAccountPromise = getMBAvailAccounts();
      getAvailAccountPromise
        .then(
          (data) => {
            if (data) setMbAvailAccounts(data);
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        })
        .finally(() => {
          handleLoadedStatus(true, "account");
        });
    };
    getGHLAvailAccFunc();
    getMBAvailAccFunc();
  }, []);

  const selectiveGhlAccountFunc = (apikey) => {
    setCalendarRequired(false);
    services.forEach((element) => {
      if (element.split(":")[0] === "classbooking" || element.split(":")[0] === "classcancel") setCalendarRequired(true);
    });
    let toastBox = toast;
    const checkCalendarAvailPromise = getCalendarDetails({ apikey: apikey });
    checkCalendarAvailPromise
      .then(
        (data) => {
          if (data) {
            setGhlCalendarDetails(data);
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });

    const getClassNamePromise = getClassDetails({
      apikey: mbAccountApiKey,
      siteid: mbAccountSiteId,
    });
    getClassNamePromise
      .then(
        (data) => {
          if (data) {
            if (data) {
              setClassDetails(data);
              setMbSelectClass(true);
            }
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });

    const getLocationPromise = getLocationDetailsForGhl({
      apikey: mbAccountApiKey,
      siteid: mbAccountSiteId,
    });
    getLocationPromise
      .then(
        (data) => {
          if (data) {
            if (data) {
              setLocationDetails(data);
            }
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        handleLoadedStatus(true, "location");
      });

    const getSessionPromise = getSessionDetailsForGhl({
      apikey: mbAccountApiKey,
      siteid: mbAccountSiteId,
    });
    getSessionPromise
      .then(
        (data) => {
          if (data) {
            if (data) {
              setSessionDetails(data);
            }
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        handleLoadedStatus(true, "session");
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      mb_apikey: "",
    },
    validate: false,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      let toastBox = toast;
      let classBooking = "false",
        classCancel = "false",
        appointmentBooking = "false",
        appointmentCancel = "false",
        mb_apikey,
        siteid,
        ghl_apikey,
        membershipAdd = "false",
        membershipCancel = "false",
        membershipDeclined = "false",
        clientCreated = "false",
        appointmentCalendarId = null,
        calendarId = null;
      mb_apikey = mbAccountApiKey;
      siteid = mbAccountSiteId;
      ghl_apikey = ghlAccount;
      // if (ghlCalendar) {
      //   calendarId = ghlCalendar;
      //   classBooking = "true";
      // } else {
      //   calendarId = null;
      //   classBooking = "false";
      // }
      // if (ghlAppointmentCalendar) {
      //   appointmentCalendarId = ghlAppointmentCalendar;
      //   appointmentBooking = "true";
      // } else {
      //   appointmentCalendarId = null;
      //   appointmentBooking = "false";
      // }
      services.forEach((element) => {
        if (element.split(":")[0] === "addmembership") membershipAdd = "true";
        if (element.split(":")[0] === "cancelmembership") membershipCancel = "true";
        if (element.split(":")[0] === "addclient") clientCreated = "true";
        if (element.split(":")[0] === "classbooking") {
          classBooking = "true";
          calendarId = ghlCalendar;
        }
        if (element.split(":")[0] === "appointmentbooking") {
          appointmentBooking = "true";
          appointmentCalendarId = ghlAppointmentCalendar;
        }
        if (element.split(":")[0] === "classcancel") {
          classCancel = "true";
          calendarId = ghlCalendar;
        }
        if (element.split(":")[0] === "appointmentcancel") {
          appointmentCancel = "true";
          appointmentCalendarId = ghlAppointmentCalendar;
        }
      });
      const servicesId = services.map((element) => {
        return element.split(":")[0];
      });
      const name = formik.values.name;
      const newValues = {
        classBooking,
        classCancel,
        appointmentBooking,
        appointmentCancel,
        mb_apikey,
        siteid,
        name,
        ghl_apikey,
        membershipAdd,
        membershipCancel,
        clientCreated,
        calendarId,
        appointmentCalendarId,
        servicesId,
        userId,
        mbClass,
        mbSession,
        mbLocation,
      };
      toast.loading("Loading...", {
        id: toastBox,
      });
      let invokePromise = addMBWebhook(newValues);
      invokePromise.then(
        (data) => {
          toast.success("Connection added successfully", {
            id: toastBox,
          });
          window.location.reload();
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      );
    },
  });

  return (
    <motion.div
      className="w-full mx-auto mt-4 flex place-items-center"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
      transition={{
        duration: 1.0,
        delay: 0.2,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <Card className="w-96 mx-auto justify-center loginCard">
        {currentPage === 1 && (
          <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
            Fill up the details to create connection
          </Typography>
        )}
        {currentPage === 2 && (
          <Typography color="gray" className="mx-auto text-center mt-4 text-lg px-4">
            Fill up the details to create connection for class booking or cancellation
          </Typography>
        )}
        {currentPage === 3 && (
          <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
            Fill up the details to create connection for appointment booking or cancellation
          </Typography>
        )}
        <CardBody className="flex flex-col gap-4">
          {currentPage === 1 && (
            <>
              {loadedData.filter((element) => element.index === "account")[0].status === true ? (
                <>
                  {mbAvailAccounts.length !== 0 ? (
                    <>
                      <Input label="Connection name" size="lg" {...formik.getFieldProps("name")} />
                      <Select variant="outlined" label="Select Mindbody account" onChange={handleMbAcc} value={mbAccount}>
                        {mbAvailAccounts.map((data, index) => {
                          return (
                            <Option key={index} value={`${data.apikey},${data.siteid}`}>
                              {data.name}
                            </Option>
                          );
                        })}
                      </Select>
                      {ghlAvailAccounts.length !== 0 && (
                        <Select variant="outlined" label="Select GoHighLevel account" onChange={handleGhlAcc} value={ghlAccount}>
                          {ghlAvailAccounts.map((data, index) => {
                            return (
                              <Option key={index} value={data.apikey}>
                                {data.name}
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </>
                  ) : (
                    <Typography color="red" className="mx-auto text-center text-xs">
                      All Mindbody Accounts are linked!
                    </Typography>
                  )}
                  {mbAccount && (
                    <Select variant="outlined" label="Select services" onChange={handleServiceSelect}>
                      <Option value="addclient-clients add automation">New Client</Option>
                      <Option value="addmembership-membership purchase automation">Membership Purchased</Option>
                      <Option value="cancelmembership-membership cancel automation">Membership Cancelled</Option>
                      <Option value="classbooking-class booking automation">Class Booking</Option>
                      <Option value="classcancel-class cancellation automation">Class Cancellation</Option>
                      <Option value="appointmentbooking-appointment booking automation">Appointment Booking</Option>
                      <Option value="appointmentcancel-appointment cancellation automation">Appointment Cancellation</Option>
                    </Select>
                  )}
                  {services.map((element, index) => {
                    return (
                      <div key={index} className="flex flex-wrap justify-between">
                        <Typography key={index} color="gray" className="text-sm pl-1 mt-2">
                          {element.split(":")[1]}
                        </Typography>
                        <Button value={element} onClick={handleServiceRemoveItem} className="rounded-full text-sm bg-deep-orange-900 px-4 py-1 text-white font-sans font-thin">
                          x
                        </Button>
                      </div>
                    );
                  })}
                  {memberFieldRequired && ghlAccount && !memberFieldID && services.length !== 0 && (
                    <Typography color="red" className="mx-auto text-center mt-4 text-xs">
                      Membership Custom Field not attached in GoHighLevel Account!
                    </Typography>
                  )}
                  <div className="p-0 pt-0 mx-auto text-end w-full">
                    {mbAccount && ghlAccount && formik.values.name && services.length !== 0 ? (
                      <IconButton
                        variant="gradient"
                        size="lg"
                        className="rounded-full text-lg"
                        onClick={() => {
                          services.filter((element) => element.split(":")[0] === "classbooking" || element.split(":")[0] === "appointmentbooking" || element.split(":")[0] === "appointmentcancel" || element.split(":")[0] === "classcancel").length !== 0
                            ? services.filter((element) => element.split(":")[0] === "classbooking" || element.split(":")[0] === "classcancel").length !== 0
                              ? setCurrentPage(2)
                              : setCurrentPage(3)
                            : formik.handleSubmit();
                        }}
                      >
                        {services.filter((element) => element.split(":")[0] === "classbooking" || element.split(":")[0] === "appointmentbooking" || element.split(":")[0] === "appointmentcancel" || element.split(":")[0] === "classcancel").length !== 0 ? <AiOutlineArrowRight /> : <MdDoneAll />}
                      </IconButton>
                    ) : (
                      <IconButton
                        variant="gradient"
                        color="gray"
                        size="lg"
                        className="rounded-full text-lg"
                        onClick={() => {
                          const toastBox = toast;
                          toast("Please fill all details!", {
                            id: toastBox,
                            icon: "⚠️",
                          });
                        }}
                      >
                        <AiOutlineArrowRight />
                      </IconButton>
                    )}
                  </div>
                </>
              ) : (
                <div className="flex place-items-center w-full m-auto justify-center">
                  <Spinner className="h-12 w-12" />
                </div>
              )}
            </>
          )}
          {currentPage === 2 && services.filter((element) => element.split(":")[0] === "classbooking" || element.split(":")[0] === "classcancel").length !== 0 && (
            <>
              {ghlCalendarDetails.length !== 0 && services.length !== 0 && calendarRequired && (
                <Select variant="outlined" label="Select Calendar" onChange={handleCalendarSelect} value={ghlCalendar}>
                  {ghlCalendarDetails.map((data, index) => {
                    return (
                      <Option key={index} value={data.id}>
                        {data.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
              {classDetails.length !== 0 && services.length !== 0 && calendarRequired && (
                <Select variant="outlined" label="Select class name" onChange={handleClassSelect} value={mbClass}>
                  {classDetails.map((data, index) => {
                    return (
                      <Option key={index} value={data.name}>
                        {data.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
              {calendarRequired && ghlCalendarDetails.length === 0 && ghlAccount && services.length !== 0 && (
                <Typography color="red" className="mx-auto text-center mt-4 text-xs">
                  Calendar not attached in GoHighLevel Account!
                </Typography>
              )}
              {ghlAccount && ((memberFieldRequired && memberFieldID !== null) || !memberFieldRequired) && calendarRequired && ghlCalendar !== null && ((calendarRequired && mbSelectClass === false) || !calendarRequired) && (
                <Typography color="red" className="mx-auto text-center text-xs">
                  No class found! Kindly re-integrate your Mindbody account.
                </Typography>
              )}

              <div className="p-0 pt-0 mx-auto justify-between flex flex-wrap w-full">
                <div>
                  <IconButton
                    size="lg"
                    className="rounded-full text-lg bg-yellow-800"
                    onClick={() => {
                      setCurrentPage(1);
                    }}
                  >
                    <AiOutlineArrowLeft />
                  </IconButton>
                </div>
                <div>
                  {ghlAccount && ((memberFieldRequired && memberFieldID !== null) || !memberFieldRequired) && ((calendarRequired && ghlCalendar !== null) || !calendarRequired) && ((calendarRequired && mbClass !== null) || !calendarRequired) ? (
                    <IconButton
                      variant="gradient"
                      size="lg"
                      color="green"
                      className="rounded-full text-lg"
                      onClick={() => {
                        services.filter((element) => element.split(":")[0] === "appointmentbooking" || element.split(":")[0] === "appointmentcancel").length !== 0 ? setCurrentPage(3) : formik.handleSubmit();
                      }}
                    >
                      {services.filter((element) => element.split(":")[0] === "appointmentbooking" || element.split(":")[0] === "appointmentcancel").length !== 0 ? <AiOutlineArrowRight /> : <MdDoneAll />}
                    </IconButton>
                  ) : (
                    <IconButton
                      variant="gradient"
                      color="gray"
                      size="lg"
                      className="rounded-full text-lg"
                      onClick={() => {
                        const toastBox = toast;
                        toast("Please fill all details!", {
                          id: toastBox,
                          icon: "⚠️",
                        });
                      }}
                    >
                      <AiOutlineArrowRight />
                    </IconButton>
                  )}
                </div>
              </div>
            </>
          )}

          {currentPage === 3 && services.filter((element) => element.split(":")[0] === "appointmentbooking" || element.split(":")[0] === "appointmentcancel").length !== 0 && (
            <>
              {loadedData.filter((element) => element.index === "session")[0].status === true && loadedData.filter((element) => element.index === "location")[0].status === true ? (
                <>
                  {ghlCalendarDetails.length !== 0 && services.length !== 0 && appointmentCalendarRequired && (
                    <Select variant="outlined" label="Select Calendar" onChange={handleAppointmentCalendarSelect} value={ghlAppointmentCalendar}>
                      {ghlCalendarDetails.map((data, index) => {
                        return (
                          <Option key={index} value={data.id}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  {locationDetails.length !== 0 && services.length !== 0 && appointmentCalendarRequired && (
                    <Select variant="outlined" label="Select Location" onChange={handleLocationSelect} value={mbLocation}>
                      {locationDetails.map((data, index) => {
                        return (
                          <Option key={index} value={`${data.locationId}`}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  {sessionDetails.length !== 0 && services.length !== 0 && appointmentCalendarRequired && (
                    <Select variant="outlined" label="Select Session" onChange={handleSessionSelect} value={mbSession}>
                      {sessionDetails.map((data, index) => {
                        return (
                          <Option key={index} value={`${data.sessionId}`}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  {calendarRequired && ghlCalendarDetails.length === 0 && ghlAccount && services.length !== 0 && (
                    <Typography color="red" className="mx-auto text-center mt-4 text-xs">
                      Calendar not attached in GoHighLevel Account!
                    </Typography>
                  )}
                </>
              ) : (
                <div className="flex place-items-center w-full m-auto justify-center">
                  <Spinner className="h-12 w-12" />
                </div>
              )}

              <div className="p-0 pt-0 mx-auto justify-between flex flex-wrap w-full">
                <div>
                  <IconButton
                    size="lg"
                    className="rounded-full text-lg bg-yellow-800"
                    onClick={() => {
                      services.filter((element) => element.split(":")[0] === "classbooking" || element.split(":")[0] === "classcancel").length !== 0 ? setCurrentPage(2) : setCurrentPage(1);
                    }}
                  >
                    <AiOutlineArrowLeft />
                  </IconButton>
                </div>
                <div>
                  {ghlAccount &&
                  ((memberFieldRequired && memberFieldID !== null) || !memberFieldRequired) &&
                  ((calendarRequired && ghlCalendar !== null) || !calendarRequired) &&
                  ((appointmentCalendarRequired && ghlAppointmentCalendar !== null) || !appointmentCalendarRequired) &&
                  ((calendarRequired && mbClass !== null) || !calendarRequired) &&
                  mbSession &&
                  mbLocation ? (
                    <IconButton variant="gradient" size="lg" color="green" className="rounded-full text-lg" onClick={formik.handleSubmit}>
                      <MdDoneAll />
                    </IconButton>
                  ) : (
                    <IconButton
                      variant="gradient"
                      color="gray"
                      size="lg"
                      className="rounded-full text-lg"
                      onClick={() => {
                        const toastBox = toast;
                        toast("Please fill all details!", {
                          id: toastBox,
                          icon: "⚠️",
                        });
                      }}
                    >
                      <AiOutlineArrowRight />
                    </IconButton>
                  )}
                </div>
              </div>
            </>
          )}
        </CardBody>
        <div className="w-2/4 mx-auto justify-center text-center mb-4">
          {/* {ghlAccount &&
            ((memberFieldRequired && memberFieldID !== null) ||
              !memberFieldRequired) &&
            ((calendarRequired && ghlCalendar !== null) || !calendarRequired) &&
            ((calendarRequired && mbClass !== null) || !calendarRequired) && (
              <Button
                variant="gradient"
                color="green"
                onClick={formik.handleSubmit}
              >
                <span>Confirm</span>
              </Button>
            )} */}
          {ghlAccount && ((memberFieldRequired && memberFieldID !== null) || !memberFieldRequired) && calendarRequired && ghlCalendar !== null && ((calendarRequired && mbSelectClass === false) || !calendarRequired) && (
            <Typography color="red" className="mx-auto text-center text-xs">
              No class found! Kindly re-integrate your Mindbody account.
            </Typography>
          )}
        </div>
      </Card>
    </motion.div>
  );
};

export default MbToGhlForm;
