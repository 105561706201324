import React, { useContext, useEffect, useState } from "react";
import ClickableCards from "./components/ClickableCards";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useStateContext } from "../../../contexts/ContextProvider";
import "react-multi-carousel/lib/styles.css";
import "./Homepage.css";
import LineChart from "./components/LineChart";
import { Alert, Button, DatePicker, Space, Spin, Typography } from "antd";
import { fetchFilteredTransactionsSpecificUser, showFilteredTransactionsCountsSpecificUser, showTransactionEventsSpecificUser } from "../../../helpers/AppTransactionApi";
import { Spinner } from "@syncfusion/ej2-react-popups";
import AuthContext from "../../../contexts/AuthContextProvider";
import SelectedActivityTable from "./components/SelectedActivityTable";
import { checkMinimumAccounts, showConnectionStatistics } from "../../../helpers/AppUserApi";
import toast from "react-hot-toast";

const { RangePicker } = DatePicker;

const Homepage = () => {
  const { activeMenu } = useStateContext();
  const { auth } = useContext(AuthContext);
  const isAdmin = auth?.userType?.includes("admin");
  const [sliderCardsData, setSliderCardsData] = useState([]);
  const [activeCardIndex, setActiveCardIndex] = useState(false);
  const [dateFilterSelectedRaw, setDateFilterSelectedRaw] = useState([]);
  const [dateFilterSelected, setDateFilterSelected] = useState([]);
  const [dateFilterSelectedType, setDateFilterSelectedType] = useState("30");
  const [graphData, setGraphData] = useState([]);
  const [graphDataPointSelected, setGraphDataPointSelected] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [transactionData, setTransactionData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [connectionStatsData, setConnectionStatsData] = useState(null);
  const [spinning, setSpinning] = useState(true);
  const [checkMinimumAccount, setCheckMinimumAccount] = useState(false);
  const [connectedWith, setConnectedWith] = useState(auth?.connectedWith?.includes("MT") ? "Marianatek" : "Mindbody");

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const [dataLoaderStatus, setDataLoaderStatus] = useState({
    transactionCounts: false,
    connectionStats: false,
    activeCardIndex: false,
    minimumAccount: false,
  });

  const carouselResponsiveSettingsInactiveSidebar = {
    responsive: [
      {
        breakpoint: 1884,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1670,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1524,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1088,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };
  const carouselResponsiveSettingsActiveSidebar = {
    responsive: [
      {
        breakpoint: 1884,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1670,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1524,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1088,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  const handleCardClick = (eventType) => {
    setActiveCardIndex(eventType);
  };

  const handleOpenPicker = () => {
    setVisible(!visible);
  };

  const handleRangeChange = (dates, dateStrings) => {
    setDateFilterSelectedRaw(dates);
    if (dateStrings?.includes("")) setDateFilterSelected([]);
    else setDateFilterSelected(dateStrings);
    setVisible(!visible);
    setDateFilterSelectedType("custom");
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style }} onClick={onClick} />;
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style }} onClick={onClick} />;
  }

  function getCurrentDatetime() {
    let currentDatetime = new Date();
    currentDatetime.setDate(currentDatetime.getDate() + 1);
    const formattedDatetime = currentDatetime.toISOString().split("T")[0];
    return formattedDatetime;
  }

  function generatePastDate(daysAgo) {
    const currentDatetime = new Date();
    const pastDatetime = new Date(currentDatetime);
    pastDatetime.setDate(currentDatetime.getDate() - daysAgo);
    const formattedDatetime = pastDatetime.toISOString().split("T")[0];
    return formattedDatetime;
  }

  useEffect(() => {
    async function transactionEventFetch() {
      try {
        let result = await showTransactionEventsSpecificUser();
        function mergeEventTypes(arr, eventTypeToMerge, newEventType) {
          const mergedArray = arr.reduce((acc, curr) => {
            if (curr.eventType === eventTypeToMerge || curr.eventType === newEventType) {
              const existingEntry = acc.find((entry) => entry.eventType === newEventType);
              if (existingEntry) {
                existingEntry.count += curr.count;
              } else {
                acc.push({ eventType: newEventType, count: curr.count });
              }
            } else {
              acc.push(curr);
            }
            return acc;
          }, []);
          return mergedArray;
        }
        result = mergeEventTypes(result, "appointmentCancel", "appointmentCancelled");
        result = mergeEventTypes(result, "classCancel", "classCancelled");
        result = mergeEventTypes(result, "clientCreate", "clientCreated");
        result = mergeEventTypes(result, "appointmentBooking", "appointmentBooking");
        result = mergeEventTypes(result, "classBooking", "classBooking");
        result = mergeEventTypes(result, "membershipAdd", "membershipAdd");
        if (result?.length !== 0) setActiveCardIndex(result[0]?.eventType);
        else setDataLoaderStatus((prevData) => ({ ...prevData, activeCardIndex: true }));
        setSliderCardsData(result);
      } catch (error) {
        setDataLoaderStatus((prevData) => ({ ...prevData, activeCardIndex: true }));
        console.log(error);
      }
    }
    transactionEventFetch();
  }, []);

  useEffect(() => {
    setDateFilterSelected([generatePastDate(30), getCurrentDatetime()]);
  }, []);

  useEffect(() => {
    if (activeCardIndex) {
      async function transactionCountFetch() {
        try {
          setDataLoaderStatus((prevData) => ({ ...prevData, transactionCounts: false }));
          const result = await showFilteredTransactionsCountsSpecificUser(activeCardIndex, dateFilterSelected);
          setGraphData(result);
        } catch (error) {
          console.log(error);
        } finally {
          setDataLoaderStatus((prevData) => ({ ...prevData, transactionCounts: true }));
        }
      }
      transactionCountFetch();
    }
  }, [activeCardIndex, dateFilterSelected]);

  useEffect(() => {
    if (graphDataPointSelected?.length !== 0) {
      async function transactionCountFetch() {
        try {
          const filterOptions = {
            filterDate: graphDataPointSelected,
            filterEvent: [activeCardIndex],
          };
          const transactionResult = await fetchFilteredTransactionsSpecificUser(1, filterOptions);
          setPageNumber(1);

          if (transactionResult?.length === 0) {
            setTransactionData([]);
          } else {
            const formattedData = transactionResult.map((item) => {
              const date = new Date(item.updated_at);
              const create_date = new Date(item.created_at);
              const formattedDate = new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }).format(date);
              const formattedCreateDate = new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }).format(create_date);
              return {
                ...item,
                updated_at: formattedDate,
                created_at: formattedCreateDate,
                // senderApp: updated_from,
                // receiverApp: updated_to,
              };
            });
            setTransactionData((prevState) => [...formattedData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))]);
          }
        } catch (error) {
          console.log(error);
        }
      }
      transactionCountFetch();
    }
    // eslint-disable-next-line
  }, [graphDataPointSelected]);

  useEffect(() => {
    if (activeCardIndex) {
      const showConnectionStatisticsFunc = async () => {
        try {
          const result = await showConnectionStatistics(activeCardIndex);
          // const ghlApiUsage = result?.ghlApiUsage || 0;
          // const mbApiUsage = result?.mbApiUsage || 0;
          const ghlApiUsage = result.reduce((count, item) => {
            return count + Number(item?.ghlApiUsage);
          }, 0);

          const mbApiUsage = result.reduce((count, item) => {
            return count + Number(item?.mbApiUsage);
          }, 0);

          const totalCount = result.reduce((count, item) => {
            return count + 1;
          }, 0);

          const successCount = result.reduce((count, item) => {
            if (item.status === "finished") {
              return count + 1;
            }
            return count;
          }, 0);

          const errorCount = result.reduce((count, item) => {
            if (item.status === "error" || item.status === "pending") return count + 1;
            return count;
          }, 0);
          setConnectionStatsData({ totalCount, successCount, errorCount, ghlApiUsage, mbApiUsage });
        } catch (error) {
          console.log(error);
        } finally {
          setDataLoaderStatus((prevData) => ({ ...prevData, connectionStats: true }));
        }
      };
      showConnectionStatisticsFunc();
    }
  }, [activeCardIndex]);

  const handleTableChange = (pagination) => {
    // pagination.current is the new page number
    const newPage = pagination.current;
    const pageSize = pagination.pageSize;

    const totalData = newPage * pageSize;

    if (totalData === pageNumber * 100) handleLoadMoreTransactions(newPage);
  };
  const handleLoadMoreTransactions = async (newPage) => {
    // Your custom logic when the page changes

    try {
      let filterOptions = {
        filterDate: graphDataPointSelected,
        filterEvent: [activeCardIndex],
      };
      const transactionResult = await fetchFilteredTransactionsSpecificUser(pageNumber, filterOptions);
      setPageNumber(pageNumber + 1);
      // setTransactionData((prevTransactionData) => ({
      //   ...prevTransactionData,
      //   transactionResult: [...prevTransactionData, ...transactionResult].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)),
      // }));
      const formattedData = transactionResult.map((item) => {
        const date = new Date(item.updated_at);
        const create_date = new Date(item.created_at);
        const updated_from = item?.senderApp === "ghl" ? "Go High Level" : item?.senderApp === "mb" ? "Mindbody" : item?.senderApp;
        const updated_to = item?.receiverApp === "ghl" ? "Go High Level" : item?.receiverApp === "mb" ? "Mindbody" : item?.receiverApp;
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(date);
        const formattedCreateDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(create_date);
        return {
          ...item,
          updated_at: formattedDate,
          created_at: formattedCreateDate,
          // senderApp: updated_from,
          // receiverApp: updated_to,
        };
      });
      setTransactionData((prevState) => [...prevState, ...formattedData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))]);
      // console.log("transactionResult" + transactionResult);
    } catch (error) {
      console.log(error);
    }

    // Add your own logic or API calls here
  };

  useEffect(() => {
    if (isAdmin) {
      setCheckMinimumAccount(true);
      setDataLoaderStatus((prevData) => ({ ...prevData, minimumAccount: true }));
    } else {
      const checkMinimumAccountsFunc = () => {
        let toastBox = toast;
        const checkMinimumAccountsPromise = checkMinimumAccounts();
        checkMinimumAccountsPromise
          .then(() => {
            setCheckMinimumAccount(true);
          })
          .catch((err) => {
            // setConnectedWith(err === "MB" ? "Mindbody" : "Marianatek");
          })
          .finally(() => {
            console.log("exec");
            setDataLoaderStatus((prevData) => ({ ...prevData, minimumAccount: true }));
          });
      };
      checkMinimumAccountsFunc();
    }
  }, [isAdmin]);

  useEffect(() => {
    dataLoaderStatus.minimumAccount && ((dataLoaderStatus.connectionStats && dataLoaderStatus.transactionCounts) || dataLoaderStatus.activeCardIndex) ? setSpinning(false) : setSpinning(true);
    console.log(dataLoaderStatus);
    dataLoaderStatus.minimumAccount && ((dataLoaderStatus.connectionStats && dataLoaderStatus.transactionCounts) || dataLoaderStatus.activeCardIndex) ? console.log(false) : console.log(true);
  }, [dataLoaderStatus]);

  return (
    <div className={`mx-auto container w-[80%] ${activeMenu ? "md:max-w-[628px] lg:max-w-[886px] xl:max-w-[1140px] 2xl:max-w-[1500px] 2xl:w-[90%]" : ""}`}>
      <Spin spinning={spinning} fullscreen />
      {(!spinning || dataLoaderStatus.connectionStats) && (
        <>
          {dataLoaderStatus.minimumAccount && checkMinimumAccount ? (
            <div className={`mt-10 mx-auto w-full px-10 py-4 bg-white rounded-xl shadow-lg`}>
              <div className={`mx-auto ${activeMenu ? "md:max-w-[628px] lg:max-w-[600px] xl:max-w-[800px] 2xl:max-w-[900px] 3xl:max-w-[1100px] 4xl:max-w-[1330px]" : ""}`}>
                {sliderCardsData?.length !== 0 && (
                  <Slider {...settings} {...(activeMenu ? carouselResponsiveSettingsActiveSidebar : carouselResponsiveSettingsInactiveSidebar)}>
                    {sliderCardsData.map((card, index) => (
                      <div key={index} className="m-4 mx-auto">
                        <ClickableCards isActive={card?.eventType === activeCardIndex} index={activeCardIndex} onClick={() => handleCardClick(card?.eventType)} value={card?.count} label={card?.eventType} />
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="w-11/12 border bg-red-50 border-red-200  px-10 py-3 rounded-md mx-auto">
                <span>⚠️ Please add atleast one Go High Level and {connectedWith} account to start using our features. Click on Add Connection in left menu.</span>
              </div>
            </>
          )}
        </>
      )}
      {(!spinning || dataLoaderStatus.connectionStats) && (
        <div className={`mt-10 mx-auto w-full py-4 grid grid-cols-2 4xl:grid-cols-3 gap-6`}>
          <div className="2xl:row-span-2 row-span-1 col-span-2 w-[100%] p-10 pt-6 mx-auto justify-center bg-white rounded-xl shadow-lg">
            <Space direction="vertical" className="w-full">
              <div className="ml-auto w-full text-end xl:flex justify-between">
                <Typography.Title level={3} type="secondary">
                  Graphical Statistics
                </Typography.Title>
                {dataLoaderStatus.connectionStats && (
                  <Space>
                    <Button
                      onClick={() => {
                        setDateFilterSelected([generatePastDate(7), getCurrentDatetime()]);
                        setDateFilterSelectedType("7");
                        setDateFilterSelectedRaw([]);
                      }}
                      className={`${dateFilterSelectedType === "7" ? `border-orange-300` : ""}`}
                    >
                      Last 7 days
                    </Button>
                    <Button
                      onClick={() => {
                        setDateFilterSelected([generatePastDate(30), getCurrentDatetime()]);
                        setDateFilterSelectedType("30");
                        setDateFilterSelectedRaw([]);
                      }}
                      className={`${dateFilterSelectedType === "30" ? `border-orange-300` : ""}`}
                    >
                      Last month
                    </Button>
                    <Button
                      onClick={() => {
                        setDateFilterSelected([generatePastDate(90), getCurrentDatetime()]);
                        setDateFilterSelectedType("90");
                        setDateFilterSelectedRaw([]);
                      }}
                      className={`${dateFilterSelectedType === "90" ? `border-orange-300` : ""}`}
                    >
                      Last 3 months
                    </Button>
                    <RangePicker
                      //renderExtraFooter={renderExtraFooter}
                      picker="range"
                      onChange={handleRangeChange}
                      value={dateFilterSelectedRaw}
                      open={visible}
                      className={`${visible ? `-z-10 fixed w-0` : `hidden`}`}
                      //className={`${visible ? `-z-10 fixed w-0` : `hidden`}`}
                      onOpenChange={(status) => setVisible(status)}
                    />
                    <Button onClick={handleOpenPicker} className={`${dateFilterSelectedType === "custom" ? `border-orange-300` : ``}`}>
                      Custom
                    </Button>
                  </Space>
                )}
              </div>
              {dataLoaderStatus.connectionStats ? (
                <div className="w-[90%] mx-auto">
                  {" "}
                  <LineChart graphData={graphData} setGraphDataPointSelected={(val) => setGraphDataPointSelected(val)} />
                </div>
              ) : (
                <div className="flex items-center text-center justify-center w-full m-auto mt-2" style={{ height: `calc(100vh - 650px)` }}>
                  <Typography.Title level={4} type="secondary">
                    No data available
                  </Typography.Title>
                </div>
              )}
            </Space>
          </div>
          <div className="col-span-1 w-[100%] p-6 mx-auto bg-white rounded-xl shadow-lg h-fit">
            <Space>
              <Typography.Title level={3} type="secondary">
                Connection statistics
              </Typography.Title>
            </Space>
            <div className="mt-0 bg-orange-400 transition-colors duration-150 rounded-md px-4 py-2 text-white">
              <Space direction="vertical" className="w-full">
                <Space direction="horizontal" className="w-full justify-between px-10">
                  <Typography.Text className="text-lg font-medium">Total requests received :</Typography.Text>
                  <Typography.Text className="text-lg font-medium">{connectionStatsData ? connectionStatsData["totalCount"] : 0}</Typography.Text>
                </Space>

                <div className=" w-full mx-auto mb-1">
                  <hr className="h-0.5 flex items-center mx-auto w-full bg-black" />
                </div>
                <Space direction="horizontal" className="w-full justify-between px-10">
                  <Typography.Text className="text-lg font-medium">Successful requests :</Typography.Text>
                  <Typography.Text className="text-lg font-medium">{connectionStatsData ? connectionStatsData["successCount"] : 0}</Typography.Text>
                </Space>

                <div className=" w-full mx-auto mb-1">
                  <hr className="h-0.5 flex items-center mx-auto w-full bg-black" />
                </div>
                <Space direction="horizontal" className="w-full justify-between px-10">
                  <Typography.Text className="text-lg font-medium">Error requests :</Typography.Text>
                  <Typography.Text className="text-lg font-medium">{connectionStatsData ? connectionStatsData["errorCount"] : 0}</Typography.Text>
                </Space>
              </Space>
            </div>
          </div>
          <div className="col-span-1 w-[100%] p-6 mx-auto bg-white rounded-xl shadow-lg h-fit">
            <Space>
              <Typography.Title level={3} type="secondary">
                API Usage
              </Typography.Title>
            </Space>
            <div className="mt-0 bg-orange-400 transition-colors duration-150 rounded-md px-4 py-2 text-white">
              <Space direction="vertical" className="w-full">
                <Space direction="horizontal" className="w-full justify-between px-10">
                  <Typography.Text className="text-lg font-medium">GoHighLevel :</Typography.Text>
                  <Typography.Text className="text-lg font-medium">{connectionStatsData?.ghlApiUsage || 0}</Typography.Text>
                </Space>

                <div className=" w-full mx-auto mb-1">
                  <hr className="h-0.5 flex items-center mx-auto w-full bg-black" />
                </div>
                <Space direction="horizontal" className="w-full justify-between px-10">
                  <Typography.Text className="text-lg font-medium">{connectedWith} :</Typography.Text>
                  <Typography.Text className="text-lg font-medium">{connectionStatsData?.mbApiUsage || 0}</Typography.Text>
                </Space>
              </Space>
            </div>
          </div>
        </div>
      )}

      <div className="w-full pt-5 mx-auto justify-center md:flex">
        {transactionData?.length !== 0 && (
          <>
            <SelectedActivityTable transactionData={transactionData} isAdmin={isAdmin} setTransactionData={(val) => setTransactionData(val)} handleTableChange={handleTableChange} setSearchText={(val) => setSearchText(val)} searchText={searchText} />
          </>
        )}
        {/* <ConnectionTable/> */}
      </div>
    </div>
  );
};

export default Homepage;
