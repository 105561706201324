import { Alert, Button, Checkbox, Divider, Modal, Select, Spin, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React, { Fragment, useEffect, useState } from "react";
import { getMBAppointmentAvailableCalendar, getMBAvailableLocation, getMBAvailableSession } from "../../../../../../helpers/MbApi";
import { toast } from "react-hot-toast";
import { getMindbodyProgramDetails, getMindbodyStaffDetails } from "../../../../../../helpers/AppUserApi";

const ServicesAppointmentCalendar = ({ open, setOpen, connectionData, setConnectionData, selectedGhlAccount }) => {
  const [calendarAvailDetails, setCalendarAvailDetails] = useState([]);
  const [locationAvailDetails, setLocationAvailDetails] = useState([]);
  const [sessionAvailDetails, setSessionAvailDetails] = useState([]);
  // const [programDetails, setProgramDetails] = useState([]);
  // const [staffDetails, setStaffDetails] = useState([]);
  const [localUpdatedAppointmentCalendar, setLocalUpdatedAppointmentCalendar] = useState(
    connectionData?.appointmentCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children
      ? connectionData?.appointmentCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children?.length !== 0
        ? connectionData?.appointmentCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children
        : []
      : []
  );
  const [loading, setLoading] = useState(true);
  const [dataLoader, setDataLoader] = useState({
    ghlCalendar: false,
    mbLocation: false,
    mbSession: false,
    // mbProgram: false,
    // mbStaff: false,
  });

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (dataLoader.ghlCalendar && dataLoader.mbLocation && dataLoader.mbSession)
      setTimeout(() => {
        setLoading(false);
      }, 1000);
  }, [dataLoader]);

  useEffect(() => {
    const getMindbodyCalendarAvail = () => {
      const toastBox = toast;
      const getLocationPromise = getMBAppointmentAvailableCalendar({
        ghl_id: selectedGhlAccount,
      });
      getLocationPromise
        .then(
          (data) => {
            if (data) {
              setCalendarAvailDetails(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        })
        .finally(() => {
          setDataLoader((prevData) => ({ ...prevData, ghlCalendar: true }));
        });
    };

    const getMindbodyLocationAvail = () => {
      const toastBox = toast;
      const getMBAvailableLocationPromise = getMBAvailableLocation({
        mb_apikey: connectionData?.mb_apikey,
        mb_siteid: connectionData?.mb_siteid,
      });
      getMBAvailableLocationPromise
        .then(
          (data) => {
            if (data) {
              setLocationAvailDetails(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        })
        .finally(() => {
          setDataLoader((prevData) => ({ ...prevData, mbLocation: true }));
        });
    };

    const getMindbodySessionAvail = () => {
      const toastBox = toast;
      const getMBAvailableSessionPromise = getMBAvailableSession({
        mb_apikey: connectionData?.mb_apikey,
        mb_siteid: connectionData?.mb_siteid,
      });
      getMBAvailableSessionPromise
        .then(
          (data) => {
            if (data) {
              setSessionAvailDetails(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        })
        .finally(() => {
          setDataLoader((prevData) => ({ ...prevData, mbSession: true }));
        });
    };
    getMindbodyCalendarAvail();
    getMindbodyLocationAvail();
    getMindbodySessionAvail();
  }, [connectionData, selectedGhlAccount]);

  // useEffect(() => {
  //   const getProgramDetailsFunc = async () => {
  //     const toastBox = toast;
  //     try {
  //       const getMindbodyProgramDetailsResult = await getMindbodyProgramDetails({
  //         mb_apikey: connectionData?.mb_apikey,
  //         mb_siteid: connectionData?.mb_siteid,
  //       });
  //       console.log(getMindbodyProgramDetailsResult?.data);
  //       setProgramDetails(getMindbodyProgramDetailsResult?.data);
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       setDataLoader((prevData) => ({ ...prevData, mbProgram: true }));
  //     }

  //     // getMBAvailableSessionPromise
  //     //   .then(
  //     //     (data) => {
  //     //       if (data) {
  //     //         console.log(data);
  //     //         setProgramDetails(data);
  //     //       }
  //     //     },
  //     //     (msg) => {
  //     //       toast.error(`${msg}`, {
  //     //         id: toastBox,
  //     //       });
  //     //     }
  //     //   )
  //     //   .catch((err) => {
  //     //     toast.error(`${err}`, {
  //     //       id: toastBox,
  //     //     });
  //     //   })
  //     //   .finally(() => {
  //     //     setDataLoader((prevData) => ({ ...prevData, mbProgram: true }));
  //     //   });
  //   };

  //   const getStaffDetailsFunc = async () => {
  //     const toastBox = toast;
  //     try {
  //       const getMindbodyProgramDetailsResult = await getMindbodyStaffDetails({
  //         mb_apikey: connectionData?.mb_apikey,
  //         mb_siteid: connectionData?.mb_siteid,
  //       });
  //       setStaffDetails(getMindbodyProgramDetailsResult?.data);
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       setDataLoader((prevData) => ({ ...prevData, mbProgram: true }));
  //     }

  //     // getMBAvailableSessionPromise
  //     //   .then(
  //     //     (data) => {
  //     //       if (data) {
  //     //         console.log(data);
  //     //         setProgramDetails(data);
  //     //       }
  //     //     },
  //     //     (msg) => {
  //     //       toast.error(`${msg}`, {
  //     //         id: toastBox,
  //     //       });
  //     //     }
  //     //   )
  //     //   .catch((err) => {
  //     //     toast.error(`${err}`, {
  //     //       id: toastBox,
  //     //     });
  //     //   })
  //     //   .finally(() => {
  //     //     setDataLoader((prevData) => ({ ...prevData, mbProgram: true }));
  //     //   });
  //   };
  //   getStaffDetailsFunc();
  //   getProgramDetailsFunc();
  // }, [connectionData]);

  const handleConnectionRemove = (index) => {
    setLocalUpdatedAppointmentCalendar((prevObjects) => {
      const updatedObjects = prevObjects.filter((obj) => obj.index !== index);
      return updatedObjects;
    });
  };

  const handleCalendarSelect = (calendarId, index) => {
    setLocalUpdatedAppointmentCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          calendarId: calendarId,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleLocationSelect = (locationId, index) => {
    setLocalUpdatedAppointmentCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          locationId: locationId,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleSessionSelect = (sessionId, index) => {
    setLocalUpdatedAppointmentCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          sessionId: sessionId,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleClassBookingSelect = (index) => {
    const bookingCurrentStatus = localUpdatedAppointmentCalendar.filter((element) => element.index === index)[0]?.bookingActive;

    setLocalUpdatedAppointmentCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          bookingActive: bookingCurrentStatus === "true" ? "false" : "true",
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleClassCancellingSelect = (index) => {
    const cancelCurrentStatus = localUpdatedAppointmentCalendar.filter((element) => element.index === index)[0]?.cancelActive;

    setLocalUpdatedAppointmentCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          cancelActive: cancelCurrentStatus === "true" ? "false" : "true",
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleProgramSelect = (programId, index) => {
    setLocalUpdatedAppointmentCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          programId: programId,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleStaffSelect = (staffId, index) => {
    setLocalUpdatedAppointmentCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          staffId: staffId,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleSaveButton = () => {
    let letSave = true;
    for (let i = 0; i < localUpdatedAppointmentCalendar.length; i++) {
      if (localUpdatedAppointmentCalendar[i].calendarId === "") {
        letSave = false;
        break;
      } else if (localUpdatedAppointmentCalendar[i].locationId === "") {
        letSave = false;
        break;
      } else if (localUpdatedAppointmentCalendar[i].sessionId === "") {
        letSave = false;
        break;
      } else if (localUpdatedAppointmentCalendar[i].forcefulBooking === "true" && (!localUpdatedAppointmentCalendar[i].programId || !localUpdatedAppointmentCalendar[i].staffId || !localUpdatedAppointmentCalendar[i].staffId === "" || !localUpdatedAppointmentCalendar[i].programId === "")) {
        letSave = false;
        break;
      }
      for (let j = i + 1; j < localUpdatedAppointmentCalendar.length; j++) {
        if (
          localUpdatedAppointmentCalendar[i].calendarId === localUpdatedAppointmentCalendar[j].calendarId ||
          (localUpdatedAppointmentCalendar[i].locationId === localUpdatedAppointmentCalendar[j].locationId && localUpdatedAppointmentCalendar[i].sessionId === localUpdatedAppointmentCalendar[j].sessionId)
        ) {
          letSave = false;
          break;
        }
      }
    }
    if (letSave) {
      //setConnectionData((prevData) => ({ ...prevData, appointmentCalendar: localUpdatedAppointmentCalendar }));
      setConnectionData((prevData) => {
        let foundMatchingItem = false;

        let updatedAppointmentCalendar = prevData.appointmentCalendar.map((item) => {
          if (item.ghl_id === selectedGhlAccount) {
            foundMatchingItem = true;
            return {
              ...item,
              children: localUpdatedAppointmentCalendar,
            };
          }

          return item;
        });

        if (!foundMatchingItem) {
          updatedAppointmentCalendar.push({
            ghl_id: selectedGhlAccount,
            children: localUpdatedAppointmentCalendar,
          });
        }
        return {
          ...prevData,
          appointmentCalendar: updatedAppointmentCalendar,
        };
      });
    } else toast.error(`Check your selection!`);
  };

  const onForcefulBookingChange = (index) => {
    // console.log("checked = ", e.target.checked);
    // setForcefulBookingActive(e.target.checked);
    const forcefulBookingStatus = localUpdatedAppointmentCalendar.filter((element) => element.index === index)[0]?.forcefulBooking;

    setLocalUpdatedAppointmentCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          forcefulBooking: forcefulBookingStatus === "true" ? "false" : "true",
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  return (
    <Spin spinning={loading} className="my-6">
      <Modal
        title={"Manage connection"}
        className="max-w-3xl"
        width={"70vw"}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Fragment key="save">
            {localUpdatedAppointmentCalendar.length !== 0 && JSON.stringify(localUpdatedAppointmentCalendar) !== JSON.stringify(connectionData?.appointmentCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children) ? (
              <Button key="save" type="default" className="bg-red-300" onClick={handleSaveButton}>
                Save & continue
              </Button>
            ) : (
              <Button key="save" type="default" className="bg-red-300" disabled>
                Save & continue
              </Button>
            )}
          </Fragment>,
        ]}
      >
        <Divider className="my-2" />
        <Alert message="Note: Both appointment booking and cancellation share this connection" type="warning" />
        {localUpdatedAppointmentCalendar.length !== 0 &&
          localUpdatedAppointmentCalendar.map(({ index, calendarId, name, locationId, sessionId, programId, staffId }) => {
            return (
              <div key={index} className="grid grid-flow-col mb-6">
                <div className="grid lg:grid-cols-2 grid-cols-1 mt-2 gap-2">
                  <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                    <Typography.Text className="text-base">Select Calendar : </Typography.Text>
                    <Select
                      placeholder="Select a calendar"
                      className="max-w-[12rem] w-full"
                      defaultValue={connectionData?.appointmentCalendar?.length !== 0 ? connectionData?.appointmentCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children.filter((element) => element.index === index)[0]?.calendarId : name && calendarId}
                      onChange={(e) => handleCalendarSelect(e, index)}
                    >
                      {calendarAvailDetails.length !== 0 &&
                        calendarAvailDetails.map((element) => {
                          return (
                            <Select.Option key={element.id} value={element.id}>
                              {element.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </div>
                  <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                    <Typography.Text className="text-base">Select location : </Typography.Text>
                    <Select
                      placeholder="Select a location"
                      className="max-w-[12rem] w-full"
                      defaultValue={connectionData?.appointmentCalendar?.length !== 0 ? connectionData?.appointmentCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children.filter((element) => element.index === index)[0]?.locationId : name ? locationId : null}
                      onChange={(e) => handleLocationSelect(e, index)}
                    >
                      {locationAvailDetails.length !== 0 &&
                        locationAvailDetails.map((element) => {
                          return (
                            <Select.Option key={element.locationId} value={element.locationId}>
                              {element.locationName}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </div>
                  <div className="min-w-[20rem] mx-auto col-span-2">
                    <Typography.Text className="text-base">Select session : </Typography.Text>
                    <Select
                      placeholder="Select a session"
                      className="max-w-[12rem] w-full"
                      defaultValue={connectionData?.appointmentCalendar?.length !== 0 ? connectionData?.appointmentCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children.filter((element) => element.index === index)[0]?.sessionId : name ? sessionId : null}
                      onChange={(e) => handleSessionSelect(e, index)}
                    >
                      {sessionAvailDetails.length !== 0 &&
                        sessionAvailDetails
                          .filter((element) => {
                            return connectionData.appointmentCalendar.every((calendarItem) => {
                              if (calendarItem.ghl_id === selectedGhlAccount) return true;
                              else
                                return calendarItem.children.every((child) => {
                                  if (child.sessionId === element.sessionId) return false;
                                  else return true;
                                });
                            });
                          })
                          .map((element) => {
                            return (
                              <Select.Option key={element.sessionId} value={element.sessionId}>
                                {element.sessionName}
                              </Select.Option>
                            );
                          })}
                    </Select>
                  </div>
                  {/* <div className=" pr-2">
                    <Checkbox checked={localUpdatedAppointmentCalendar.filter((element) => element.index === index)[0]?.forcefulBooking === "true"} onChange={() => onForcefulBookingChange(index)}>
                      Forceful-booking?
                    </Checkbox>
                  </div>
                  {localUpdatedAppointmentCalendar.filter((element) => element.index === index)[0]?.forcefulBooking === "true" && (
                    <>
                      <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                        <Typography.Text className="text-base">Select program : </Typography.Text>
                        <Select
                          placeholder="Select a session"
                          className="max-w-[12rem] w-full"
                          defaultValue={connectionData?.appointmentCalendar?.length !== 0 ? connectionData?.appointmentCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children.filter((element) => element.index === index)[0]?.programId : name ? programId : null}
                          onChange={(e) => handleProgramSelect(e, index)}
                        >
                          {programDetails.length !== 0 &&
                            programDetails.map((element) => {
                              return (
                                <Select.Option key={element.Id} value={`${element.Id}`}>
                                  {element.Name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                        <Typography.Text className="text-base">Select staff : </Typography.Text>
                        <Select
                          placeholder="Select a session"
                          className="max-w-[12rem] w-full"
                          defaultValue={connectionData?.appointmentCalendar?.length !== 0 ? connectionData?.appointmentCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children.filter((element) => element.index === index)[0]?.staffId : name ? staffId : null}
                          onChange={(e) => handleStaffSelect(e, index)}
                        >
                          {staffDetails.length !== 0 &&
                            staffDetails.map((element) => {
                              return (
                                <Select.Option key={element.Id} value={`${element.Id}`}>
                                  {element.DisplayName}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </div>
                    </>
                  )} */}

                  <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                    <Button type="dashed" className={localUpdatedAppointmentCalendar.filter((element) => element.index === index)[0]?.bookingActive === "true" ? "w-11/12 bg-green-300" : "w-11/12"} onClick={() => handleClassBookingSelect(index)}>
                      Booking
                    </Button>
                  </div>
                  <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                    <Button type="dashed" className={localUpdatedAppointmentCalendar.filter((element) => element.index === index)[0]?.cancelActive === "true" ? "w-11/12 bg-green-300" : "w-11/12"} onClick={() => handleClassCancellingSelect(index)}>
                      Cancellation
                    </Button>
                  </div>
                </div>
                <div className="w-full place-items-center m-auto justify-center text-center">
                  <MinusCircleOutlined
                    onClick={() => {
                      handleConnectionRemove(index);
                    }}
                  />
                </div>
              </div>
            );
          })}

        <div className="w-11/12 mx-auto mt-4">
          <Button
            type="dashed"
            onClick={() => {
              const length = Number(localUpdatedAppointmentCalendar.length + 1);
              const newValue = {
                index: length + Math.random(),
                calendarId: "",
                locationId: "",
                sessionId: "",
                type: "new",
                bookingActive: "false",
                cancelActive: "false",
                classBookingTags: "",
                classCancellationTags: "",
              };
              setLocalUpdatedAppointmentCalendar((prevObjects) => [...prevObjects, newValue]);
            }}
            block
            icon={<PlusOutlined />}
          >
            Add connection
          </Button>
        </div>
      </Modal>
    </Spin>
  );
};

export default ServicesAppointmentCalendar;
