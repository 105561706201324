import { Switch } from "@material-tailwind/react";
import React, { useState } from "react";

const OverrideSwitchHandler = ({ userData, newUserData, setNewUserData }) => {
  const [localUserData, setLocalUserData] = useState(userData);

  const handleChange = (val) => {
    // let localArray = [localUserData]
    // let normalArray = newUserData
    // normalArray = [...normalArray, localArray.filter((obj) => newUserData.some(item => item.id !== obj.id))[0]];

    let localArray = [localUserData];
    let normalArray = newUserData;
    let tempArray = localArray.filter((obj) => {
      if (newUserData.length !== 0) {
        return newUserData.some((item) => {
          return item.id !== obj.id;
        });
      }
      return obj;
    })[0];

    if (tempArray) {
      normalArray = [...normalArray, tempArray];
    } else {
      normalArray = [...normalArray];
    }
    const updatedArray = normalArray.map((obj) => {
      if (obj.id === localUserData.id) {
        return { ...obj, override: val };
      }
      return obj;
    });
    setLocalUserData({ ...localUserData, override: val });
    if (updatedArray) setNewUserData(updatedArray);
  };

  return (
    <div className="w-full mx-auto justify-center">
      <div className="max-w-max mx-auto justify-center text-start items-start">
        {userData.override === "true" ? (
          <Switch
            id={userData.id}
            color="green"
            defaultChecked
            onChange={() => {
              setNewUserData(localUserData.override === "true" ? handleChange("false") : handleChange("true"));
            }}
          />
        ) : (
          <Switch
            id={userData.id}
            color="green"
            onChange={() => {
              setNewUserData(localUserData.override === "true" ? handleChange("false") : handleChange("true"));
            }}
          />
        )}
      </div>
    </div>
  );
};

export default OverrideSwitchHandler;
