import React, { useEffect, useState } from "react";
import { getCalendarDetailsForGhl, getClassDetailsForGhl, getGHLAvailAccounts, getLocationDetailsForGhl, getMBAppointmentAvailAccounts, getMBContactAvailAccounts, getSessionDetailsForGhl } from "../../../../helpers/AppUserApi";
import { toast } from "react-hot-toast";
import { Button, Card, CardBody, IconButton, Input, Option, Select, Spinner, Typography } from "@material-tailwind/react";
import { motion } from "framer-motion";
import { useFormik } from "formik";
import { addAppointmentWebhook, addClassBookingWebhook, addContactWebhook } from "../../../../helpers/GhlApi";
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlinePlus } from "react-icons/ai";
import { MdDoneAll } from "react-icons/md";

const GhlToMbForm = ({ triggerAddEvent, triggerRemoveEvent, triggerResetEvent }) => {
  const [ghlService, setGhlService] = useState(null);
  const [ghlAvailAccounts, setGhlAvailAccounts] = useState([]);
  const [mbAvailAccounts, setMbAvailAccounts] = useState([]);
  const [mbAppointmentAvailAccounts, setMbAppointmentAvailAccounts] = useState([]);
  const [contactGhlAcc, setContactGhlAcc] = useState(null);
  const [appointmentGhlAcc, setAppointmentGhlAcc] = useState(null);
  const [classBookingGhlAcc, setClassBookingGhlAcc] = useState(null);
  const [contactMbAcc, setContactMbAcc] = useState(null);
  const [appointmentMbAcc, setAppointmentMbAcc] = useState(null);
  const [classBookingMbAcc, setClassBookingMbAcc] = useState(null);
  const [mbSelectValue, setMbSelectValue] = useState(null);
  const [mbAppointmentSelectValue, setMbAppointmentSelectValue] = useState(null);
  const [mbClassBookingSelectValue, setMbClassBookingSelectValue] = useState(null);
  const [contactMbAccSiteid, setContactMbAccSiteid] = useState(null);
  const [appointmentMbAccSiteid, setAppointmentMbAccSiteid] = useState(null);
  const [classBookingMbAccSiteid, setClassBookingMbAccSiteid] = useState(null);
  const [appointmentPage, setAppointmentPage] = useState(1);
  const [classBookingPage, setClassBookingPage] = useState(1);
  const [classCalendar, setClassCalendar] = useState([
    {
      index: 1,
      calendarId: "",
      className: "",
      booking: false,
      cancel: false,
    },
  ]);
  const [appointmentCalendar, setAppointmentCalendar] = useState([
    {
      index: 1,
      calendarId: "",
      locationId: "",
      sessionTypeId: "",
      booking: false,
      cancel: false,
    },
  ]);
  const [ghlCalendarDetails, setGhlCalendarDetails] = useState([]);
  const [classDetails, setClassDetails] = useState([]);
  const [locationDetails, setLocationDetails] = useState([]);
  const [sessionDetails, setSessionDetails] = useState([]);

  ///////////// loading useStates
  const [loadedAppointmentCalendar, setLoadedAppointmentCalendar] = useState([false, false, false]);
  /////////////

  const handleGhlService = (e) => {
    handleResetClick();
    setClassBookingPage(1);
    setAppointmentPage(1);
    setAppointmentCalendar([
      {
        index: 1,
        calendarId: "",
        locationId: "",
        sessionTypeId: "",
        booking: false,
        cancel: false,
      },
    ]);
    setClassCalendar([
      {
        index: 1,
        calendarId: "",
        className: "",
        booking: false,
        cancel: false,
      },
    ]);
    setLocationDetails([]);
    setSessionDetails([]);
    setLoadedAppointmentCalendar([false, false, false]);
    setGhlService(e);
  };

  const handleGhlContactAcc = (e) => {
    setContactGhlAcc(e);
  };

  const handleGhlAppointmentAcc = (e) => {
    setAppointmentGhlAcc(e);
  };

  const handleGhlClassBookingAcc = (e) => {
    setClassBookingGhlAcc(e);
  };

  const handleMbContactAcc = (e) => {
    const apikey = e.split(",")[0];
    const siteid = e.split(",")[1];
    setContactMbAcc(apikey);
    setContactMbAccSiteid(siteid);
    setMbSelectValue(e);
  };

  const handleMbAppointmentAcc = (e) => {
    const apikey = e.split(",")[0];
    const siteid = e.split(",")[1];
    setAppointmentMbAcc(apikey);
    setAppointmentMbAccSiteid(siteid);
    setMbAppointmentSelectValue(e);
  };

  const handleMbClassBookingAcc = (e) => {
    const apikey = e.split(",")[0];
    const siteid = e.split(",")[1];
    setClassBookingMbAcc(apikey);
    setClassBookingMbAccSiteid(siteid);
    setMbClassBookingSelectValue(e);
  };

  const getGHLContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getGHLAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setGhlAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const getMBAppointmentAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getMBAppointmentAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setMbAppointmentAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const getMBContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getMBContactAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setMbAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const ghlFormik = useFormik({
    initialValues: {
      mb_apikey: "",
      ghl_apikey: "",
      name: "",
      siteid: "",
    },
    validate: false,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      let toastBox = toast;
      toast.loading("Loading...", {
        id: toastBox,
      });
      if (ghlService === "contact") {
        if (contactMbAcc && contactMbAccSiteid && contactGhlAcc && ghlFormik.values.name !== "") {
          values.mb_apikey = contactMbAcc;
          values.siteid = contactMbAccSiteid;
          values.ghl_apikey = contactGhlAcc;
          let invokePromise = addContactWebhook(values);
          invokePromise.then(
            (data) => {
              toast.success("Connection created successfully!", {
                id: toastBox,
              });
              window.location.reload();
            },
            (msg) => {
              if (msg.includes("Duplicate")) {
                toast.error(`connection already exists`, {
                  id: toastBox,
                });
              } else {
                toast.error(`${msg}`, {
                  id: toastBox,
                });
              }
            }
          );
        } else {
          toast.error(`Please fill all details`, {
            id: toastBox,
          });
        }
      } else if (ghlService === "manageClasses") {
        if (classBookingGhlAcc && classBookingMbAcc && classBookingMbAccSiteid && ghlFormik.values.name !== "") {
          let allowSubmit = true;
          let serviceSelect = true;
          for (let i = 0; i < classCalendar.length; i++) {
            if (!classCalendar[i].booking && !classCalendar[i].cancel) {
              allowSubmit = false;
              serviceSelect = false;
              break;
            }
            for (let j = i + 1; j < classCalendar.length; j++) {
              if (classCalendar[j].calendarId === classCalendar[i].calendarId || classCalendar[j].className === classCalendar[i].className) {
                allowSubmit = false;
                break;
              }
            }
            if (classCalendar[i].calendarId === "" || classCalendar[i].className === "") {
              allowSubmit = false;
            }
            if (allowSubmit === false) break;
          }
          if (allowSubmit) {
            values.mb_apikey = classBookingMbAcc;
            values.ghl_apikey = classBookingGhlAcc;
            values.siteid = classBookingMbAccSiteid;
            const newValues = { ...values, classCalendar };
            let invokePromise = addClassBookingWebhook(newValues);
            invokePromise.then(
              (data) => {
                toast.success("Connection created successfully!", {
                  id: toastBox,
                });
                window.location.reload();
              },
              (msg) => {
                if (msg.includes("Duplicate")) {
                  toast.error(`connection already exists`, {
                    id: toastBox,
                  });
                } else {
                  toast.error(`${msg}`, {
                    id: toastBox,
                  });
                }
              }
            );
          } else {
            if (!serviceSelect) {
              toast.error(`Atleast one event selection mandatory for each connection`, {
                id: toastBox,
              });
            } else
              toast.error(`Duplicate or empty connection value selected`, {
                id: toastBox,
              });
          }
        } else {
          toast.error(`Please fill all details`, {
            id: toastBox,
          });
        }
      } else if (ghlService === "appointment") {
        if (appointmentGhlAcc && appointmentMbAcc && appointmentMbAccSiteid && ghlFormik.values.name !== "") {
          let allowSubmit = true;
          let serviceSelect = true;
          for (let i = 0; i < appointmentCalendar.length; i++) {
            if (!appointmentCalendar[i].booking && !appointmentCalendar[i].cancel) {
              allowSubmit = false;
              serviceSelect = false;
              break;
            }
            for (let j = i + 1; j < appointmentCalendar.length; j++) {
              if (appointmentCalendar[j].calendarId === appointmentCalendar[i].calendarId || appointmentCalendar[j].locationId === appointmentCalendar[i].locationId || appointmentCalendar[j].sessionTypeId === appointmentCalendar[i].sessionTypeId) {
                allowSubmit = false;
                break;
              }
            }
            if (appointmentCalendar[i].calendarId === "" || appointmentCalendar[i].locationId === "" || appointmentCalendar[i].sessionTypeId === "") {
              allowSubmit = false;
            }
            if (allowSubmit === false) break;
          }
          if (allowSubmit) {
            values.mb_apikey = appointmentMbAcc;
            values.ghl_apikey = appointmentGhlAcc;
            values.siteid = appointmentMbAccSiteid;
            const newValues = { ...values, appointmentCalendar };
            let invokePromise = addAppointmentWebhook(newValues);
            invokePromise.then(
              (data) => {
                toast.success("Connection created successfully!", {
                  id: toastBox,
                });
                window.location.reload();
              },
              (msg) => {
                if (msg.includes("Duplicate")) {
                  toast.error(`connection already exists`, {
                    id: toastBox,
                  });
                } else {
                  toast.error(`${msg}`, {
                    id: toastBox,
                  });
                }
              }
            );
          } else {
            if (!serviceSelect) {
              toast.error(`Atleast one event selection mandatory for each connection`, {
                id: toastBox,
              });
            } else
              toast.error(`Duplicate or empty connection value selected`, {
                id: toastBox,
              });
          }
        } else {
          toast.error(`Please fill all details`, {
            id: toastBox,
          });
        }
      } else {
        toast.error(`Please select a service connection`, {
          id: toastBox,
        });
      }
    },
  });

  const getCalendarAvailFunc = (apikey, mbAccountApiKey, mbAccountSiteId) => {
    let toastBox = toast;
    const checkCalendarAvailPromise = getCalendarDetailsForGhl({
      apikey: apikey,
    });
    checkCalendarAvailPromise
      .then(
        (data) => {
          if (data) {
            setGhlCalendarDetails(data);
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });

    const getClassNamePromise = getClassDetailsForGhl({
      apikey: mbAccountApiKey,
      siteid: mbAccountSiteId,
    });
    getClassNamePromise
      .then(
        (data) => {
          if (data) {
            if (data) {
              setClassDetails(data);
            }
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const getCalendarLocationSessionAvailFunc = (apikey, mbAccountApiKey, mbAccountSiteId) => {
    let toastBox = toast;
    const checkCalendarAvailPromise = getCalendarDetailsForGhl({
      apikey: apikey,
    });
    checkCalendarAvailPromise
      .then(
        (data) => {
          if (data) {
            setGhlCalendarDetails(data);
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setLoadedAppointmentCalendar((prevState) => [true, prevState[1], prevState[2]]);
      });

    const getLocationPromise = getLocationDetailsForGhl({
      apikey: mbAccountApiKey,
      siteid: mbAccountSiteId,
    });
    getLocationPromise
      .then(
        (data) => {
          if (data) {
            if (data) {
              setLocationDetails(data);
            }
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setLoadedAppointmentCalendar((prevState) => [prevState[0], true, prevState[2]]);
      });

    const getSessionPromise = getSessionDetailsForGhl({
      apikey: mbAccountApiKey,
      siteid: mbAccountSiteId,
    });
    getSessionPromise
      .then(
        (data) => {
          if (data) {
            if (data) {
              setSessionDetails(data);
            }
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setLoadedAppointmentCalendar((prevState) => [prevState[0], prevState[1], true]);
      });
  };

  const handleCalendarSelect = (calendarId, index) => {
    // console.log(e, index)
    setClassCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          calendarId: calendarId,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleAppointmentCalendarSelect = (calendarId, index) => {
    // console.log(e, index)
    setAppointmentCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          calendarId: calendarId,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleClassSelect = (className, index) => {
    // console.log(e, index)
    setClassCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          className: className,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleClassBookingSelect = (bookingBool, index) => {
    // console.log(e, index)
    setClassCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          booking: bookingBool,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleAppointmentBookingSelect = (bookingBool, index) => {
    // console.log(e, index)
    setAppointmentCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          booking: bookingBool,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleClassCancelSelect = (cancelBool, index) => {
    // console.log(e, index)
    setClassCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          cancel: cancelBool,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleAppointmentCancelSelect = (cancelBool, index) => {
    // console.log(e, index)
    setAppointmentCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          cancel: cancelBool,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleLocationSelect = (locationId, index) => {
    // console.log(e, index)
    setAppointmentCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          locationId: locationId,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleSessionSelect = (sessionTypeId, index) => {
    // console.log(e, index)
    setAppointmentCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          sessionTypeId: sessionTypeId,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleAddClick = () => {
    // Call the triggerEvent function passed down from the parent component
    triggerAddEvent();
  };

  const handleRemoveClick = () => {
    // Call the triggerEvent function passed down from the parent component
    triggerRemoveEvent();
  };

  const handleResetClick = () => {
    triggerResetEvent();
  };

  useEffect(() => {
    getGHLContactAvailAccFunc();
    getMBAppointmentAvailAccFunc();
    getMBContactAvailAccFunc();
  }, []);

  return (
    <motion.div
      className="w-full mx-auto mt-4 flex place-items-center"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
      transition={{
        duration: 1.0,
        delay: 0.2,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <Card className="w-96 mx-auto justify-center loginCard">
        <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
          Fill up the details to create connection
        </Typography>
        <CardBody className="flex flex-col gap-4">
          <div>
            <Select label="Select service" onChange={handleGhlService} value={ghlService}>
              <Option value="contact">contact creation</Option>
              <Option value="manageClasses">manage classes</Option>
              <Option value="appointment">manage appointments</Option>
            </Select>
          </div>
          {ghlService === "contact" && (
            <>
              <Input label="Connection name" size="lg" {...ghlFormik.getFieldProps("name")} />
              {ghlAvailAccounts.length !== 0 && (
                <Select variant="outlined" label="Select GoHighLevel account" onChange={handleGhlContactAcc} value={contactGhlAcc}>
                  {ghlAvailAccounts.map((data, index) => {
                    return (
                      <Option key={index} value={data.apikey}>
                        {data.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
              {mbAvailAccounts.length !== 0 && (
                <Select variant="outlined" label="Select MindBody account" onChange={handleMbContactAcc} value={mbSelectValue}>
                  {mbAvailAccounts.map((data, index) => {
                    return (
                      <Option key={index} value={`${data.apikey},${data.siteid}`}>
                        {data.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </>
          )}
          {ghlService === "manageClasses" && (
            <>
              {classBookingPage === 1 && (
                <>
                  <Input label="Connection name" size="lg" {...ghlFormik.getFieldProps("name")} />
                  {ghlAvailAccounts.length !== 0 && (
                    <Select variant="outlined" label="Select GoHighLevel account" onChange={handleGhlClassBookingAcc} value={classBookingGhlAcc}>
                      {ghlAvailAccounts.map((data, index) => {
                        return (
                          <Option key={index} value={data.apikey}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  {mbAppointmentAvailAccounts.length !== 0 && (
                    <Select variant="outlined" label="Select MindBody account" onChange={handleMbClassBookingAcc} value={mbClassBookingSelectValue}>
                      {mbAppointmentAvailAccounts.map((data, index) => {
                        return (
                          <Option key={index} value={`${data.apikey},${data.siteid}`}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  <div className="p-0 pt-0 mx-auto text-end w-full">
                    {classBookingGhlAcc && classBookingMbAcc && classBookingMbAccSiteid && ghlFormik.values.name !== "" ? (
                      <IconButton
                        variant="gradient"
                        size="lg"
                        className="rounded-full text-lg"
                        onClick={() => {
                          getCalendarAvailFunc(classBookingGhlAcc, classBookingMbAcc, classBookingMbAccSiteid);
                          setClassBookingPage(2);
                        }}
                      >
                        <AiOutlineArrowRight />
                      </IconButton>
                    ) : (
                      <IconButton
                        variant="gradient"
                        color="gray"
                        size="lg"
                        className="rounded-full text-lg"
                        onClick={() => {
                          const toastBox = toast;
                          toast("Please fill all details!", {
                            id: toastBox,
                            icon: "⚠️",
                          });
                        }}
                      >
                        <AiOutlineArrowRight />
                      </IconButton>
                    )}
                  </div>
                </>
              )}
              {classBookingPage === 2 && (
                <>
                  <div>
                    <IconButton
                      variant="filled"
                      size="md"
                      color="green"
                      className="rounded-md text-xl "
                      onClick={() => {
                        handleAddClick();
                        const length = Number(classCalendar.length + 1);
                        const newValue = {
                          index: length + Math.random(),
                          calendarId: "",
                          className: "",
                          booking: false,
                          cancel: false,
                        };
                        setClassCalendar((prevObjects) => [...prevObjects, newValue]);
                      }}
                    >
                      <AiOutlinePlus />
                    </IconButton>
                  </div>
                  {classCalendar.map(({ index }) => {
                    return (
                      <div key={index} className="bg-gray-100">
                        <div className="py-3 px-2 pb-0 rounded-lg grid grid-rows-2 grid-flow-col gap-3">
                          {ghlCalendarDetails.length !== 0 ? (
                            <div className="col-span-1 row-span-1">
                              <Select variant="outlined" label="Select Calendar" onChange={(e) => handleCalendarSelect(e, index)}>
                                {ghlCalendarDetails.map((data, index) => {
                                  return (
                                    <Option key={index} value={data.id}>
                                      {data.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </div>
                          ) : (
                            <div className="col-span-1 row-span-1">
                              <Typography color="red" className="mx-auto text-center text-sm mt-2">
                                No calendar available!
                              </Typography>
                            </div>
                          )}
                          {classDetails.length !== 0 ? (
                            <div className="col-span-1 row-span-1">
                              <Select
                                variant="outlined"
                                label="Select class name"
                                onChange={(e) => handleClassSelect(e, index)}
                                //   value={mbClass}
                              >
                                {classDetails.map((data, index) => {
                                  return (
                                    <Option key={index} value={data.name}>
                                      {data.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </div>
                          ) : (
                            <div className="col-span-1 row-span-1">
                              <Typography color="red" className="mx-auto text-center text-sm mt-2">
                                No class available!
                              </Typography>
                            </div>
                          )}

                          <div className="col-span-2 row-span-3 place-items-center w-full mx-auto justify-center text-center">
                            {index !== 1 ? (
                              <Button
                                type="button"
                                className="text-xs px-3"
                                color="red"
                                onClick={() => {
                                  handleRemoveClick();
                                  setClassCalendar((prevObjects) => {
                                    const updatedObjects = prevObjects.filter((obj) => obj.index !== index);
                                    return updatedObjects;
                                  });
                                }}
                              >
                                remove
                              </Button>
                            ) : (
                              <Button type="button" className="text-xs px-3" color="gray">
                                remove
                              </Button>
                            )}
                          </div>
                        </div>
                        <div className="w-full mx-auto justify-evenly flex">
                          <div className="w-2/5">
                            <button
                              type="button"
                              // variant={classBooking ? "filled" : "outlined"}
                              className={
                                classCalendar.filter((element) => element.index === index)[0].booking
                                  ? "px-3 mx-auto w-full rounded-md py-1.5 text-base border-2 bg-gray-200 border-green-500 text-green-600"
                                  : "px-3 mx-auto w-full rounded-md py-1.5 text-base border-2 border-gray-400 text-blue-gray-900 hover:bg-gray-200 hover:border-green-500"
                              }
                              onClick={() => handleClassBookingSelect(!classCalendar.filter((element) => element.index === index)[0].booking, index)}
                            >
                              Booking
                            </button>
                          </div>
                          <div className="w-2/5 pb-2">
                            <button
                              type="outline"
                              // variant={classBooking ? "filled" : "outlined"}
                              className={
                                classCalendar.filter((element) => element.index === index)[0].cancel
                                  ? "px-3 mx-auto w-full rounded-md py-1.5 text-base border-2 bg-gray-200 border-green-500 text-green-600"
                                  : "px-3 mx-auto w-full rounded-md py-1.5 text-base border-2 border-gray-400 text-blue-gray-900 hover:bg-gray-200 hover:border-green-500"
                              }
                              onClick={() => handleClassCancelSelect(!classCalendar.filter((element) => element.index === index)[0].cancel, index)}
                            >
                              Cancellation
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="p-0 pt-0 mx-auto justify-between flex flex-wrap w-full">
                    <div>
                      <IconButton
                        size="lg"
                        className="rounded-full text-lg bg-yellow-800"
                        onClick={() => {
                          handleResetClick();
                          setClassBookingPage(1);
                        }}
                      >
                        <AiOutlineArrowLeft />
                      </IconButton>
                    </div>
                    <div>
                      {classBookingGhlAcc && classBookingMbAcc && classBookingMbAccSiteid && ghlFormik.values.name !== "" ? (
                        <IconButton variant="gradient" size="lg" color="green" className="rounded-full text-lg" onClick={ghlFormik.handleSubmit}>
                          <MdDoneAll />
                        </IconButton>
                      ) : (
                        <IconButton
                          variant="gradient"
                          color="gray"
                          size="lg"
                          className="rounded-full text-lg"
                          onClick={() => {
                            const toastBox = toast;
                            toast("Please fill all details!", {
                              id: toastBox,
                              icon: "⚠️",
                            });
                          }}
                        >
                          <AiOutlineArrowRight />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {ghlService === "appointment" && (
            <>
              {appointmentPage === 1 && (
                <>
                  <Input label="Connection name" size="lg" {...ghlFormik.getFieldProps("name")} />
                  {ghlAvailAccounts.length !== 0 && (
                    <Select variant="outlined" label="Select GoHighLevel account" onChange={handleGhlAppointmentAcc} value={appointmentGhlAcc}>
                      {ghlAvailAccounts.map((data, index) => {
                        return (
                          <Option key={index} value={data.apikey}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  {mbAppointmentAvailAccounts.length !== 0 && (
                    <Select variant="outlined" label="Select MindBody account" onChange={handleMbAppointmentAcc} value={mbAppointmentSelectValue}>
                      {mbAppointmentAvailAccounts.map((data, index) => {
                        return (
                          <Option key={index} value={`${data.apikey},${data.siteid}`}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  <div className="p-0 pt-0 mx-auto text-end w-full">
                    {appointmentGhlAcc && appointmentMbAcc && appointmentMbAccSiteid && ghlFormik.values.name !== "" ? (
                      <IconButton
                        variant="gradient"
                        size="lg"
                        className="rounded-full text-lg"
                        onClick={() => {
                          getCalendarLocationSessionAvailFunc(appointmentGhlAcc, appointmentMbAcc, appointmentMbAccSiteid);
                          setAppointmentPage(2);
                        }}
                      >
                        <AiOutlineArrowRight />
                      </IconButton>
                    ) : (
                      <IconButton
                        variant="gradient"
                        color="gray"
                        size="lg"
                        className="rounded-full text-lg"
                        onClick={() => {
                          const toastBox = toast;
                          toast("Please fill all details!", {
                            id: toastBox,
                            icon: "⚠️",
                          });
                        }}
                      >
                        <AiOutlineArrowRight />
                      </IconButton>
                    )}
                  </div>
                </>
              )}
              {appointmentPage === 2 && (
                <>
                  <div>
                    <Button
                      variant="gradient"
                      size="md"
                      color="green"
                      className="rounded-md text-xs text-white"
                      onClick={() => {
                        handleAddClick();
                        const length = Number(appointmentCalendar.length + 1);
                        const newValue = {
                          index: length + Math.random(),
                          calendarId: "",
                          locationId: "",
                          sessionTypeId: "",
                          booking: false,
                          cancel: false,
                        };
                        setAppointmentCalendar((prevObjects) => [...prevObjects, newValue]);
                      }}
                    >
                      ADD
                    </Button>
                  </div>
                  {loadedAppointmentCalendar[0] && loadedAppointmentCalendar[1] && loadedAppointmentCalendar[2] ? (
                    appointmentCalendar.map(({ index }) => {
                      return (
                        <div key={index} className="bg-gray-100">
                          <div className="py-3 px-2 rounded-lg grid grid-rows-2 grid-flow-col gap-3">
                            {ghlCalendarDetails.length !== 0 ? (
                              <div className="col-span-1 row-span-1">
                                <Select variant="outlined" label="Select Calendar" onChange={(e) => handleAppointmentCalendarSelect(e, index)}>
                                  {ghlCalendarDetails.map((data, index) => {
                                    return (
                                      <Option key={index} value={data.id}>
                                        {data.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            ) : (
                              <div className="col-span-1 row-span-1">
                                <Typography color="red" className="mx-auto text-center text-sm mt-2">
                                  No calendar available!
                                </Typography>
                              </div>
                            )}
                            {locationDetails.length !== 0 ? (
                              <div className="col-span-1 row-span-1">
                                <Select
                                  variant="outlined"
                                  label="Select location"
                                  onChange={(e) => handleLocationSelect(e, index)}
                                  //   value={mbClass}
                                >
                                  {locationDetails.map((data, index) => {
                                    return (
                                      <Option key={index} value={`${data.locationId}`}>
                                        {data.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            ) : (
                              <div className="col-span-1 row-span-1">
                                <Typography color="red" className="mx-auto text-center text-sm mt-2">
                                  No location available!
                                </Typography>
                              </div>
                            )}
                            {sessionDetails.length !== 0 ? (
                              <div className="col-span-1 row-span-1">
                                <Select
                                  variant="outlined"
                                  label="Select session"
                                  onChange={(e) => handleSessionSelect(e, index)}
                                  //   value={mbClass}
                                >
                                  {sessionDetails.map((data, index) => {
                                    return (
                                      <Option key={index} value={`${data.sessionId}`}>
                                        {data.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            ) : (
                              <div className="col-span-1 row-span-1">
                                <Typography color="red" className="mx-auto text-center text-sm mt-2">
                                  No session available!
                                </Typography>
                              </div>
                            )}

                            <div className="col-span-2 row-span-3 place-items-center w-full mx-auto justify-center text-center">
                              {index !== 1 ? (
                                <Button
                                  type="button"
                                  className="text-xs px-3"
                                  color="red"
                                  onClick={() => {
                                    handleRemoveClick();
                                    setAppointmentCalendar((prevObjects) => {
                                      const updatedObjects = prevObjects.filter((obj) => obj.index !== index);
                                      return updatedObjects;
                                    });
                                  }}
                                >
                                  remove
                                </Button>
                              ) : (
                                <Button type="button" className="text-xs px-3" color="gray">
                                  remove
                                </Button>
                              )}
                            </div>
                          </div>
                          <div className="w-full mx-auto justify-evenly flex">
                            <div className="w-2/5">
                              <button
                                type="button"
                                // variant={classBooking ? "filled" : "outlined"}
                                className={
                                  appointmentCalendar.filter((element) => element.index === index)[0].booking
                                    ? "px-3 mx-auto w-full rounded-md py-1.5 text-base border-2 bg-gray-200 border-green-500 text-green-600"
                                    : "px-3 mx-auto w-full rounded-md py-1.5 text-base border-2 border-gray-400 text-blue-gray-900 hover:bg-gray-200 hover:border-green-500"
                                }
                                onClick={() => handleAppointmentBookingSelect(!appointmentCalendar.filter((element) => element.index === index)[0].booking, index)}
                              >
                                Booking
                              </button>
                            </div>
                            <div className="w-2/5 pb-2">
                              <button
                                type="outline"
                                // variant={classBooking ? "filled" : "outlined"}
                                className={
                                  appointmentCalendar.filter((element) => element.index === index)[0].cancel
                                    ? "px-3 mx-auto w-full rounded-md py-1.5 text-base border-2 bg-gray-200 border-green-500 text-green-600"
                                    : "px-3 mx-auto w-full rounded-md py-1.5 text-base border-2 border-gray-400 text-blue-gray-900 hover:bg-gray-200 hover:border-green-500"
                                }
                                onClick={() => handleAppointmentCancelSelect(!appointmentCalendar.filter((element) => element.index === index)[0].cancel, index)}
                              >
                                Cancellation
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="flex place-items-center w-full m-auto justify-center">
                      <Spinner className="h-12 w-12" />
                    </div>
                  )}

                  <div className="p-0 pt-0 mx-auto justify-between flex flex-wrap w-full">
                    <div>
                      <IconButton
                        size="lg"
                        className="rounded-full text-lg bg-yellow-800"
                        onClick={() => {
                          handleResetClick();
                          setAppointmentPage(1);
                          setAppointmentCalendar([
                            {
                              index: 1,
                              calendarId: "",
                              locationId: "",
                              sessionTypeId: "",
                              booking: false,
                              cancel: false,
                            },
                          ]);
                          setLocationDetails([]);
                          setSessionDetails([]);
                          setLoadedAppointmentCalendar([false, false, false]);
                        }}
                      >
                        <AiOutlineArrowLeft />
                      </IconButton>
                    </div>
                    <div>
                      {appointmentGhlAcc && appointmentMbAcc && appointmentMbAccSiteid && ghlFormik.values.name !== "" ? (
                        <IconButton variant="gradient" size="lg" color="green" className="rounded-full text-lg" onClick={ghlFormik.handleSubmit}>
                          <MdDoneAll />
                        </IconButton>
                      ) : (
                        <IconButton
                          variant="gradient"
                          color="gray"
                          size="lg"
                          className="rounded-full text-lg"
                          onClick={() => {
                            const toastBox = toast;
                            toast("Please fill all details!", {
                              id: toastBox,
                              icon: "⚠️",
                            });
                          }}
                        >
                          <AiOutlineArrowRight />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {ghlService === "contact" && (
            <div className="w-2/4 mx-auto justify-center text-center">
              <Button variant="gradient" color="green" onClick={ghlFormik.handleSubmit}>
                <span>Confirm</span>
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
    </motion.div>
  );
};

export default GhlToMbForm;
