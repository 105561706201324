import React, { useEffect, useState } from "react";
import { Alert, Button, Divider, Input, Select, Space, Spin, Switch, Typography } from "antd";
import { getGHLAvailAccounts, getMindbodyAccountAvail } from "../../../../../../helpers/AppUserApi";
import { PlusOutlined } from "@ant-design/icons";
import { toast } from "react-hot-toast";
import AddNewAccountModal from "../globalComponents/AddNewAccountModal";
import AddIntegrationModal from "../globalComponents/AddIntegrationModal";
const { Option } = Select;

const LinkAccountConnection = ({ data, setData, setupDifficulty, setSetupDiffculty }) => {
  const [ghlAvailAccounts, setGhlAvailAccounts] = useState([]);
  const [mbAvailAccounts, setMbAvailAccounts] = useState([]);
  const [addNewAccountModal, setAddNewAccountModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataLoader, setDataLoader] = useState({
    ghlAccounts: false,
    mbAccounts: false,
  });

  const handleGhlAccountSelect = (e) => {
    setData({ ...data, ghl_id: e });
  };

  const handleMBAccountSelect = (e) => {
    const apikey = e.split(",")[0];
    const siteid = e.split(",")[1];
    setData({ ...data, mb_apikey: apikey, mb_siteid: siteid });
  };

  const getGHLContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getGHLAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setGhlAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, ghlAccounts: true }));
      });
  };

  const getMBContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getMindbodyAccountAvail();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setMbAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, mbAccounts: true }));
      });
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setData((prevData) => ({
      ...prevData,
      connectionName: value,
    }));
  };

  useEffect(() => {
    getGHLContactAvailAccFunc();
    getMBContactAvailAccFunc();
  }, []);

  useEffect(() => {
    if (dataLoader.ghlAccounts && dataLoader.mbAccounts) setLoading(false);
  }, [dataLoader]);

  const handleSwitchChange = (checked) => {
    setSetupDiffculty(checked);
  };
  //   useEffect(() => {
  //     if (!hasLoaded) {
  //       formRef.current?.setFieldsValue({
  //         ghlAccount: data?.ghl_id ? data.ghl_id : null,
  //         mbAccount: data?.mb_apikey ? `${data.mb_apikey},${data.mb_siteid}` : null,
  //       });
  //       setHasLoaded(true); // Set the flag to true after executing the code
  //     }
  //   }, [data, hasLoaded]);

  const handleNewAccountMindbodyButton = () => {
    setAddNewAccountModal(!addNewAccountModal);
  };

  return (
    <Spin spinning={loading} className="my-6">
      <AddIntegrationModal setOpen={(bool) => setAddNewAccountModal(bool)} open={addNewAccountModal} type={"mindbody"} />
      {/* <div className="w-full mx-auto justify-end text-end">
        <Typography.Text className="font-medium text-gray-700">Setup difficulty : </Typography.Text>
        <Switch className="ml-4 bg-blue-gray-300" checkedChildren="Advanced" unCheckedChildren="Normal" onChange={handleSwitchChange} checked={setupDifficulty} />
      </div>
      <Divider className=" mt-2" /> */}
      <div className="w-10/12 mx-auto mt-2">
        {!setupDifficulty ? (
          <>
            <Input addonBefore="Connection name" onChange={handleInputChange} value={data?.connectionName} placeholder="Enter Name" className="max-w-lg mb-4" />
            <div>
              <Typography.Text>GoHighLevel account : </Typography.Text>
              <Select
                placeholder="Select an account"
                className="ml-2 lg:max-w-xs lg:w-1/2 xl:w-full"
                onChange={handleGhlAccountSelect}
                defaultValue={data?.ghl_id !== "" ? data?.ghl_id : null}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      {/* <Input
                      placeholder="Please enter item"
                      ref={inputRef}
                      value={name}
                      onChange={onNameChange}
                      onKeyDown={(e) => e.stopPropagation()}
                    /> */}
                      <Button type="text" icon={<PlusOutlined />}>
                        Add item
                      </Button>
                    </Space>
                  </>
                )}
              >
                {ghlAvailAccounts.length !== 0 &&
                  ghlAvailAccounts.map((data, index) => {
                    return (
                      <Option key={index} value={data.apikey}>
                        {data.name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </>
        ) : (
          <>
            <div className="mb-4">
              <Alert type="info" className="bg-gray-100 text-gray-700 border-gray-500" message={"Select GoHighLevel account separately in next step."} />
            </div>
            <Input addonBefore="Connection name" onChange={handleInputChange} value={data?.connectionName} placeholder="Enter Name" className="max-w-lg" />
          </>
        )}
        <div className="mt-4">
          <Typography.Text>Mindbody account : </Typography.Text>
          <Select
            placeholder="Select an account"
            className="ml-6 lg:max-w-xs lg:w-1/2 xl:w-full"
            onChange={handleMBAccountSelect}
            defaultValue={data?.mb_apikey !== "" ? `${data.mb_apikey},${data.mb_siteid}` : null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: "8px 0",
                  }}
                />
                <Space
                  style={{
                    padding: "0 8px 4px",
                  }}
                >
                  {/* <Input
                placeholder="Please enter item"
                ref={inputRef}
                value={name}
                onChange={onNameChange}
                onKeyDown={(e) => e.stopPropagation()}
              /> */}
                  <Button type="text" icon={<PlusOutlined />} onClick={handleNewAccountMindbodyButton}>
                    Add new account
                  </Button>
                </Space>
              </>
            )}
          >
            {mbAvailAccounts.length !== 0 &&
              mbAvailAccounts.map((data, index) => {
                return (
                  <Option key={index} value={`${data.apikey},${data.siteid}`}>
                    {data?.name}
                  </Option>
                );
              })}
          </Select>
        </div>
      </div>
    </Spin>
  );
};
export default LinkAccountConnection;
