import { Button, Divider, Modal, Spin, Steps, Switch, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import LinkAccountMtToGhlConnection from "./marianatekConnection/LinkAccountMtToGhlConnection";
import LinkMtServicesConnection from "./marianatekConnection/LinkMtServicesConnection";
import ManageMtCustomTags from "./marianatekConnection/ManageMtCustomTags";
import { addMTWebhook } from "../../../../../helpers/MtApi";

const AddMarianatekConnection = ({ open, setOpen }) => {
  const [connectionData, setConnectionData] = useState({
    connectionName: "",
    ghl_id: "",
    mt_id: "",
    classCalendar: [],
    contactCreationEvent: [],
    membershipEvent: [],
    clientSaleEvent: [],
  });

  const [setupDifficulty, setSetupDiffculty] = useState(true);
  const [isClientFieldPresent, setIsClientFieldPresent] = useState(null);

  const steps = [
    {
      title: "Select accounts",
      content: "First-content",
    },
    {
      title: "Select services",
      content: "Second-content",
    },
    {
      title: "Modify GoHighLevel tags",
      content: "Last-content",
    },
  ];

  const [current, setCurrent] = useState(0);
  const next = () => {
    if (current === 0) {
      if (connectionData.mt_id !== "" && connectionData.mt_id !== null && connectionData.connectionName !== "") {
        setCurrent(current + 1);
      } else toast.error(`Fill all fields!`);
    } else if (current === 1) {
      if (connectionData.classCalendar.length !== 0 || connectionData.clientSaleEvent.length !== 0 || connectionData.contactCreationEvent.length !== 0 || connectionData.membershipEvent !== 0) {
        setCurrent(current + 1);
      } else {
        toast.error("Setup connection to proceed!");
      }
    } else setCurrent(current + 1);
  };
  const prev = () => {
    if (current - 1 === 0) {
      setConnectionData((prevData) => ({ ...prevData, classCalendar: [], membershipEvent: [], clientSaleEvent: [], contactCreationEvent: false }));
    }
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const handleSubmitConnectionButton = () => {
    let toastBox = toast;
    toast.loading("Loading...", {
      id: toastBox,
    });
    console.log(connectionData);
    let invokePromise = addMTWebhook(connectionData);
    invokePromise.then(
      (data) => {
        toast.success("Connection added successfully", {
          id: toastBox,
        });
        window.location.reload();
      },
      (msg) => {
        toast.error(`${msg}`, {
          id: toastBox,
        });
      }
    );
  };

  useEffect(() => {
    console.log(connectionData);
  }, [connectionData]);

  return (
    <div>
      <Modal title={"Add new connection"} className="max-w-5xl" width={"70vw"} centered open={open} onOk={() => setOpen(false)} onCancel={() => setOpen(false)} footer={null}>
        <Divider className="my-2" />
        {/* <Spin spinning={loading}>{type === "contact_creation" ? <Tabs className="m-4" activeKey={activeTab} onChange={handleTabChange} tabPosition={"left"} items={ContactTabData} size="middle" /> : type === "mindbody_webhook" ? <Tabs className="m-4" activeKey={activeTab} onChange={handleTabChange} tabPosition={"left"} items={MindbodyTabData} size="middle" /> : <Tabs className="m-4" activeKey={activeTab} onChange={handleTabChange} tabPosition={"left"} items={TabData} size="middle" />}</Spin> */}
        <Spin spinning={isClientFieldPresent === null && current === 2}>
          <div className="flex gap-4">
            <div className="max-w-max min-w-max mt-2">
              <Steps current={current} items={items} className="site-navigation-steps" direction="vertical" size="small" />
            </div>
            <Divider className="h-36 bg-blue-gray-100 mt-2" type="vertical" />
            <div className="w-full rounded-lg">
              {current === 0 ? (
                <>
                  <LinkAccountMtToGhlConnection data={connectionData} setData={(data) => setConnectionData(data)} />
                </>
              ) : current === 1 ? (
                <>
                  <LinkMtServicesConnection data={connectionData} setData={(data) => setConnectionData(data)} />
                </>
              ) : (
                current === 2 && (
                  <>
                    <ManageMtCustomTags data={connectionData} setData={(data) => setConnectionData(data)} webhook_type={"mindbody_webhook"} setupDifficulty={setupDifficulty} isClientFieldPresent={isClientFieldPresent} setIsClientFieldPresent={(bool) => setIsClientFieldPresent(bool)} />
                  </>
                )
              )}
            </div>
          </div>

          <div
            style={{
              marginTop: 20,
              marginBottom: 0,
            }}
            className="w-full mx-auto justify-end text-end mb-0"
          >
            {current > 0 && (
              <Button
                style={{
                  margin: "0 8px",
                }}
                onClick={() => prev()}
              >
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                type={current === 1 && connectionData.clientSaleEvent.length === 0 && connectionData.classCalendar.length === 0 && connectionData.contactCreationEvent.length === 0 && connectionData.membershipEvent.length === 0 ? "dashed" : "default"}
                className={current === 1 && connectionData.clientSaleEvent.length === 0 && connectionData.classCalendar.length === 0 && connectionData.contactCreationEvent.length === 0 && connectionData.membershipEvent.length === 0 ? "" : "border-light-green-600 text-light-green-600"}
                onClick={() => next()}
              >
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                type="default"
                disabled={!isClientFieldPresent}
                onClick={() => {
                  handleSubmitConnectionButton();
                }}
              >
                Done
              </Button>
            )}
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default AddMarianatekConnection;
