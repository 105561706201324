import React, { useEffect, useState } from "react";
import { Card, Typography, CardBody, Spinner } from "@material-tailwind/react";
import SelectedTransactionDrawer from "./SelectedTransactionDrawer";
import { Button, Input, Table } from "antd";

const columns = [
  {
    title: "From",
    dataIndex: "senderApp",
    render: (text, record) => {
      // Customize the rendered text based on the value of eventType
      if (record.senderApp === "ghl") {
        return <span>Go High Level</span>;
      } else if (record.senderApp === "mb") {
        return <span>Mindbody</span>;
      }
      // Add more conditions or customize as needed
      return text; // Default behavior if no special case is met
    },
  },
  {
    title: "To",
    dataIndex: "receiverApp",
    render: (text, record) => {
      // Customize the rendered text based on the value of eventType
      if (record.receiverApp === "ghl") {
        return <span>Go High Level</span>;
      } else if (record.receiverApp === "mb") {
        return <span>Mindbody</span>;
      }
      // Add more conditions or customize as needed
      return text; // Default behavior if no special case is met
    },
  },
  {
    title: "Type",
    dataIndex: "eventType",
    render: (text, record) => {
      // Customize the rendered text based on the value of eventType
      if (record.eventType === "appointmentCancelled" || record.eventType === "appointmentCancel") {
        return <span>Appointment cancelled</span>;
      } else if (record.eventType === "appointmentBooking") {
        return <span>Appointment booked</span>;
      } else if (record.eventType === "classCancel" || record.eventType === "classCancelled") {
        return <span>Class cancelled</span>;
      } else if (record.eventType === "classUpdate") {
        return <span>Class updated</span>;
      } else if (record.eventType === "membershipAdd") {
        return <span>Membership purchased</span>;
      } else if (record.eventType === "contractUpdate") {
        return <span>Membership cancelled</span>;
      } else if (record.eventType === "membershipCancel") {
        return <span>Membership declined</span>;
      } else if (record.eventType === "clientCreated") {
        return <span>Client created</span>;
      } else if (record.eventType === "clientUpdate") {
        return <span>Client updated</span>;
      } else if (record.eventType === "classBooking") {
        return <span>Class booked</span>;
      } else if (record.eventType === "clientSale") {
        return <span>Client sale</span>;
      }
      // Add more conditions or customize as needed
      return text; // Default behavior if no special case is met
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text, record) => {
      // Customize the rendered text based on the value of eventType
      if (record.status === "finished") {
        return <span style={{ color: "lightgreen" }}>Finished</span>;
      } else if (record.status === "error") {
        return <span style={{ color: "red" }}>Error</span>;
      } else if (record.status === "skipped") {
        return <span style={{ color: "orange" }}>Skipped</span>;
      }
      // Add more conditions or customize as needed
      return text; // Default behavior if no special case is met
    },
  },
  {
    title: "Created At",
    dataIndex: "created_at",
  },
  {
    title: "Last updated",
    dataIndex: "updated_at",
  },
];

const SelectedActivityTable = ({ transactionData, isAdmin, handleTableChange, searchText, searchedColumn, setSearchText, setSearchedColumn }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const [transactionDrawer, setTransactionDrawer] = useState(false);
  const [selectedTransactionData, setSelectedTransactionData] = useState([]);
  const [fetchedTransactionData, setFetchedTransactionData] = useState(transactionData);
  const [sortInverseBool, setSortInverseBool] = useState(false);

  useEffect(() => {
    setFetchedTransactionData(transactionData);
  }, [transactionData]);

  const handleSelectedTransactionDrawer = (value) => {
    setSelectedTransactionData(fetchedTransactionData.filter((element) => element.transactionId === value));
    setTransactionDrawer(true);
  };

  const handleOnClickCreatedAt = () => {
    setSortInverseBool(!sortInverseBool);
    if (sortInverseBool) setFetchedTransactionData(fetchedTransactionData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)));
    else setFetchedTransactionData(fetchedTransactionData.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at)));
  };

  /////////////////////

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input placeholder={`Search ${dataIndex}`} value={selectedKeys[0]} onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)} style={{ width: 188, marginBottom: 8, display: "block" }} />
        <button type="button" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} style={{ width: 90, marginRight: 8 }}>
          Search
        </button>
        <button onClick={() => handleReset(clearFilters)} style={{ width: 90 }}>
          Reset
        </button>
      </div>
    ),
    filterIcon: (filtered) => (
      <i style={{ color: filtered ? "#1890ff" : undefined }} className="anticon anticon-search">
        Search
      </i>
    ),
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => document.getElementById("search-input").select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? <span>{text}</span> : text),
  });

  const columnsWithSearch = columns.map((column) => {
    if (column.dataIndex) {
      return {
        ...column,
        ...getColumnSearchProps(column.dataIndex),
      };
    }
    return column;
  });

  /////////////////////////

  const handleActivityDrawerOpen = (data) => {
    setSelectedTransactionData([data]);
    setTransactionDrawer(true);
  };

  return (
    <>
      <SelectedTransactionDrawer openRight={transactionDrawer} setOpenRight={(bool) => setTransactionDrawer(bool)} transactionData={selectedTransactionData} setTransactionData={(data) => setSelectedTransactionData(data)} isAdmin={isAdmin} />
      {true ? (
        // <Card className="h-full md:w-5/6 bg-blue-gray-50">
        //   <CardBody className={"overflow-x-scroll 2xl:overflow-x-hidden px-0"} style={{ maxHeight: `calc(100vh - 250px)` }}>

        //   </CardBody>
        // </Card>
        <div className="md:w-full mb-4 bg-white pt-4 px-6 rounded-md shadow-md pb-4">
          <Table
            rowSelection={false}
            columns={columns}
            dataSource={transactionData}
            scroll={{
              y: `calc(100vh - 350px)`,
              x: true,
            }}
            onChange={handleTableChange}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleActivityDrawerOpen(record);
                }, // click row
                onDoubleClick: (event) => {}, // double click row
                onContextMenu: (event) => {}, // right button click row
                onMouseEnter: (event) => {
                  if (record?.isParent) {
                    event.target.style.cursor = "pointer";
                  } else {
                    event.target.style.cursor = "pointer";
                  } // Change cursor to pointer on mouse enter
                },
                onMouseLeave: (event) => {
                  //   event.target.style.cursor = "default"; // Change cursor back to default on mouse leave
                },
              };
            }}
          />
        </div>
      ) : (
        <div className="flex place-items-center w-full m-auto justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      )}
    </>
  );
};

export default SelectedActivityTable;
