import React, { useState } from "react";
import { Fragment } from "react";
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Select, Option } from "@material-tailwind/react";
import { Toaster, toast } from "react-hot-toast";
import { useMediaQuery } from "react-responsive";

import MbToGhlForm from "./MbToGhlForm";
import GhlToMbForm from "./GhlToMbForm";
import { activeUser } from "../../../../helpers/AppUserApi";

const AddConnectionModal = ({ open, setOpen }) => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 1045px)" });
  const isMobileScreenShorter = useMediaQuery({ query: "(max-width: 487px)" });

  const [connectionType, setConnectionType] = useState(null);
  const [userId, setUserId] = useState(null);
  const [countBoxes, setCountBoxes] = useState(1);

  const handleOpen = () => {
    setCountBoxes(1);
    setOpen(!open);
  };

  const handleConnectionType = (e) => {
    setConnectionType(e);
  };

  if (open) {
    let toastBox = toast;
    const activeUserPromise = activeUser();
    activeUserPromise
      .then((data) => {
        if (data.type === "admin") {
        } else if (data.type === "user") {
          setUserId(data.userid);
        }
      })
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  }

  const handleAddEvent = () => {
    setCountBoxes(countBoxes + 1);
    // Perform any necessary actions or state updates
  };

  const handleRemoveEvent = () => {
    setCountBoxes(countBoxes - 1);
    // Perform any necessary actions or state updates
  };

  const handleResetCountEvent = () => {
    setCountBoxes(1);
    // Perform any necessary actions or state updates
  };

  return (
    <Fragment>
      <div>
        <Dialog open={open} handler={handleOpen} size={isMobileScreen ? (isMobileScreenShorter ? "xxl" : "xl") : "md"} className={countBoxes === 1 ? "overflow-y-visible" : "overflow-y-scroll"} style={{ maxHeight: "90vh" }}>
          <Toaster position="top-center" reverseOrder={false}></Toaster>
          <DialogHeader>Add new connection</DialogHeader>
          <DialogBody divider>
            <Select label="Select connection type" onChange={handleConnectionType} value={connectionType}>
              <Option value="ghltomb">GoHighLevel to MindBody</Option>
              <Option value="mbtoghl">MindBody to GoHighLevel</Option>
            </Select>
            {connectionType === "ghltomb" && <GhlToMbForm triggerAddEvent={handleAddEvent} triggerRemoveEvent={handleRemoveEvent} triggerResetEvent={handleResetCountEvent} />}
            {connectionType === "mbtoghl" && <MbToGhlForm userId={userId} />}
          </DialogBody>
          <DialogFooter>
            <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
              <span>Cancel</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    </Fragment>
  );
};

export default AddConnectionModal;
