import { BrowserRouter, Route, Routes, createBrowserRouter } from "react-router-dom";
import "./App.css";
// import "./index.less";
import { Toaster } from "react-hot-toast";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import PageNotFound from "./screens/pageNotFound/PageNotFound";
import Dashboard from "./screens/dashboard/Dashboard";
import LoadingMainPage from "./screens/loadingMainPage/LoadingMainPage";
import { Login } from "./screens/login/Login";
import Register from "./screens/register/Register";
import RequireAuth from "./components/RequireAuth";
import OAuth from "./screens/oauth/OAuth";
import ConfirmModal, { confirmModalRef } from "./components/ConfirmModal";
// import { PageNotFound } from "./screens/pageNotFound/PageNotFound";
// import Homepage from "./screens/homepage/Homepage";
// import Dashboard from "./screens/dashboard/Dashboard";
// import { PageNotFound } from "./screens/pageNotFound/PageNotFound";
// import { Login } from "./screens/login/Login";
// import GHLRegister from "./screens/goHighLevelRegister/GHLRegister";
// import { Toaster } from "react-hot-toast";

function App() {
  // const router = createBrowserRouter([
  //   // {
  //   //   path: "/",
  //   //   element: <Homepage />,
  //   // },
  //   // {
  //   //   path: "/connections",
  //   //   element: <Dashboard />,
  //   // },
  //   // {
  //   //   path: "/activity",
  //   //   element: <Dashboard />,
  //   // },
  //   // {
  //   //   path: "/profile",
  //   //   element: <Dashboard />,
  //   // },
  //   // {
  //   //   path: "/integrations",
  //   //   element: <Dashboard />,
  //   // },
  //   // {
  //   //   path: "/webhooks",
  //   //   element: <Dashboard />,
  //   // },
  //   // {
  //   //   path: "/manageusers",
  //   //   element: <Dashboard />,
  //   // },
  //   // {
  //   //   path: "/login",
  //   //   element: <Login />,
  //   // },
  //   // {
  //   //   path: "/onetimeregister",
  //   //   element: <GHLRegister />,
  //   // },
  //   {
  //     path: "*",
  //     element: <PageNotFound />,
  //   },
  // ]);

  //const { setCurrentColor, setCurrentMode, currentMode, activeMenu, currentColor, themeSettings, setThemeSettings } = useStateContext();
  return (
    <div>
      <ConfirmModal ref={confirmModalRef} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoadingMainPage />} />
          <Route path="*" element={<PageNotFound />} />
          <Route element={<RequireAuth allowedRoles={["user", "admin"]} />}>
            <Route path="/dashboard/*" element={<Dashboard />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/crm/oauth" element={<OAuth />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
