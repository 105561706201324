import React from "react";
import { Fragment } from "react";
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Card, Typography } from "@material-tailwind/react";
import { useMediaQuery } from "react-responsive";
import { Toaster, toast } from "react-hot-toast";
import { deleteUser } from "../../../../helpers/AppUserApi";

const DeleteUserConfirmModal = ({ open, setOpen, userData }) => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 1045px)" });
  const isMobileScreenShorter = useMediaQuery({ query: "(max-width: 487px)" });
  const excessModalHeight = useMediaQuery({ query: "(min-height: 600px)" });

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDeleteButton = () => {
    let toastBox = toast;
    toast.loading("loading...", {
      id: toastBox,
    });
    const userid = userData[0].id;
    const deleteUserPromise = deleteUser({ userid: userid });
    deleteUserPromise
      .then(
        (data) => {
          toast.success("User deleted successfully!", {
            id: toastBox,
          });
          window.location.reload();
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  return (
    <Fragment>
      <Dialog open={open} handler={handleOpen} size={isMobileScreen ? (isMobileScreenShorter ? "xxl" : "xl") : "md"} className={!excessModalHeight ? "overflow-y-scroll" : "overflow-y-visible"} style={{ maxHeight: "90vh" }}>
        <Toaster position="top-center" reverseOrder={false}></Toaster>
        <DialogHeader>Are you sure you want to delete?</DialogHeader>
        <DialogBody divider>
          {open && (
            <Card className="w-96 p-5 mx-auto justify-center loginCard">
              <div className="flex flex-wrap">
                <div>
                  <Typography color="black" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                    name:
                  </Typography>
                </div>
                <div className="w-max ml-4">
                  <Typography color="blue-gray" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                    {userData[0].name}
                  </Typography>
                </div>
              </div>
              <div className="pt-5 flex flex-wrap">
                <div>
                  <Typography color="black" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                    email:
                  </Typography>
                </div>
                <div className="w-max ml-4">
                  <Typography color="blue-gray" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                    {userData[0].email}
                  </Typography>
                </div>
              </div>
            </Card>
          )}
        </DialogBody>
        <DialogFooter>
          <Button variant="text" color="green" onClick={handleOpen} className="mr-1">
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="red" onClick={handleDeleteButton} type="button">
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default DeleteUserConfirmModal;
