import React, { useState } from "react";

const ClickableCards = ({ index, value, label, isActive, onClick }) => {
  const handleClick = () => {
    onClick();
  };
  if (label === "appointmentBooking") label = "Appointment booking";
  else if (label === "appointmentCancelled") label = "Appointment cancelled";
  else if (label === "classBooking") label = "Class booking";
  else if (label === "classCancelled") label = "Class cancelled";
  else if (label === "classUpdate") label = "Class updated";
  else if (label === "clientCreated") label = "Client created";
  else if (label === "clientUpdate") label = "Client updated";
  else if (label === "membershipAdd") label = "Contract purchased";
  else if (label === "contractUpdate") label = "Contract cancelled";
  else if (label === "membershipCancel") label = "Membership declined";
  else if (label === "clientSale") label = "Client sale";
  return (
    <button className={`focus:outline-none flex flex-col mx-auto items-center p-4 py-8 rounded-lg shadow-md transition-colors duration-150 w-52 sm:w-72 ${isActive ? "bg-orange-400 text-white" : "bg-slate-50 focus:bg-orange-400 focus:text-white"}`} onClick={handleClick}>
      <span className="text-3xl font-bold">{value}</span>
      <span className="text-xl">{label}</span>
      {/* <span className="text-6xl">{emoji}</span> */}
    </button>
  );
};

export default ClickableCards;
