import { Button, Divider, Select, Typography } from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useState } from "react";
import "./ManageServicesTab.css";
import { getMBAppointmentCalendarDetails, getMBClassCalendarDetails } from "../../../../../../helpers/MbApi";
import { toast } from "react-hot-toast";
import { updateMBWebhook } from "../../../../../../helpers/WebhookApi";
import { getGHLAvailAccounts } from "../../../../../../helpers/AppUserApi";
import ServicesClassCalendar from "../../connectionAddition/mindbodyConnection/ServicesClassCalendar";
import ServicesAppointmentCalendar from "../../connectionAddition/mindbodyConnection/ServicesAppointmentCalendar";
import { getCustomFieldDetails } from "../../../../../../helpers/GhlApi";
import ServicesClientSale from "../../connectionAddition/mindbodyConnection/ServicesClientSale";
import ServicesClientCreated from "../../connectionAddition/mindbodyConnection/ServicesClientCreated";
import ServicesContractCreated from "../../connectionAddition/mindbodyConnection/ServicesContractCreated";
import ServicesContractUpdate from "../../connectionAddition/mindbodyConnection/ServicesContractUpdate";
import ServicesClientUpdate from "../../connectionAddition/mindbodyConnection/ServicesClientUpdate";
import ServicesMembershipCancel from "../../connectionAddition/mindbodyConnection/ServicesMembershipCancel";

const ManageServicesTab = ({ data, triggerServicesSaveButton, setTriggerServicesSaveButton, setActiveServicesSaveTrigger, reloadWebhookData }) => {
  const [servicesSelected, setServicesSelected] = useState({
    manageClass: false,
    manageAppointment: false,
    clientCreated: false,
    membershipAdd: false,
    membershipCancel: false,
    membershipDeclined: false,
  });

  const [manageModal, setManageModal] = useState({
    manageClass: false,
    manageAppointment: false,
    clientSale: false,
    clientCreated: false,
    membershipAdd: false,
    membershipCancel: false,
    clientUpdate: false,
    membershipDeclined: false,
  });

  const [localConnectionData, setLocalConnectionData] = useState(data);
  const [selectedGhlAccount, setSelectedGhlAccount] = useState(null);

  const [classCalendar, setClassCalendar] = useState([]);
  const [appointmentCalendar, setAppointmentCalendar] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [updatedClassCalendar, setUpdatedClassCalendar] = useState([]);
  const [updatedAppointmentCalendar, setUpdatedAppointmentCalendar] = useState([]);
  const [ghlAvailAccounts, setGhlAvailAccounts] = useState([]);
  const [dataLoader, setDataLoader] = useState({
    ghlAccounts: false,
    mbAccounts: false,
  });

  ////// save new state functions ////////

  const handleSelectGhlAccount = (e) => {
    console.log(e);
    setSelectedGhlAccount(e);
  };

  ////// api-call functions //////

  const getGHLContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getGHLAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setGhlAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, ghlAccounts: true }));
      });
  };

  const reloadClassCalendarDetails = () => {
    const toastBox = toast;
    const mb_id = data?.mb_id;
    const getMBClassCalendarDetailsPromise = getMBClassCalendarDetails({ mb_id: mb_id });
    getMBClassCalendarDetailsPromise
      .then(
        (data) => {
          if (data) {
            if (data) {
              setClassCalendar(data);
            }
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        const updatedServicesSelected = { ...manageModal };
        updatedServicesSelected["manageClass"] = !updatedServicesSelected["manageClass"];
        setManageModal(updatedServicesSelected);
      });
  };

  const reloadAppointmentCalendarDetails = () => {
    const toastBox = toast;
    const mb_id = data?.mb_id;
    const getMBAppointmentCalendarDetailsPromise = getMBAppointmentCalendarDetails({ mb_id: mb_id });
    getMBAppointmentCalendarDetailsPromise
      .then(
        (data) => {
          if (data) {
            setAppointmentCalendar(data);
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        const updatedServicesSelected = { ...manageModal };
        updatedServicesSelected["manageAppointment"] = !updatedServicesSelected["manageAppointment"];
        setManageModal(updatedServicesSelected);
      });
  };

  /////// use effects ////////

  useEffect(() => {
    setLocalConnectionData(data);
  }, [data]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(localConnectionData)) {
      setActiveServicesSaveTrigger(true);
    } else setActiveServicesSaveTrigger(false);
  }, [data, localConnectionData, setActiveServicesSaveTrigger]);

  useEffect(() => {
    getGHLContactAvailAccFunc();
  }, []);

  useEffect(() => {
    const getClassCalendarDetails = (data) => {
      const toastBox = toast;
      const mb_id = data?.mb_id;
      const getMBClassCalendarDetailsPromise = getMBClassCalendarDetails({ mb_id: mb_id });
      getMBClassCalendarDetailsPromise
        .then(
          (data) => {
            if (data) {
              if (data) {
                if (data?.length !== 0)
                  setServicesSelected((prevState) => ({
                    ...prevState,
                    manageClass: true,
                  }));
                setClassCalendar(data);
                setUpdatedClassCalendar(data);
              }
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });

      const getMBAppointmentCalendarDetailsPromise = getMBAppointmentCalendarDetails({ mb_id: mb_id });
      getMBAppointmentCalendarDetailsPromise
        .then(
          (data) => {
            if (data) {
              if (data) {
                if (data?.length !== 0)
                  setServicesSelected((prevState) => ({
                    ...prevState,
                    manageAppointment: true,
                  }));
                setAppointmentCalendar(data);
                setUpdatedAppointmentCalendar(data);
              }
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    };
    getClassCalendarDetails(data);
  }, [data]);

  /////// button handlers ///////

  const handleSelectServiceButton = (keyToUpdate) => {
    let updateType = null;
    // Update the state with the new object
    if (keyToUpdate === "clientCreated") updateType = "clientCreated";
    else if (keyToUpdate === "clientUpdate") updateType = "clientUpdate";
    else if (keyToUpdate === "membershipAdd") updateType = "membershipAdd";
    else if (keyToUpdate === "membershipCancel") updateType = "membershipCancel";
    else if (keyToUpdate === "clientSale") updateType = "clientSale";

    if (updateType) {
      setLocalConnectionData((prevData) => {
        const updatedClientCreatedData = prevData.otherEvents.map((item) => {
          if (item.ghl_id === selectedGhlAccount) {
            return {
              ...item,
              [updateType]: item[updateType] === "true" ? "false" : "true",
            };
          }
          return item;
        });

        const matchingItem = prevData.otherEvents.find((item) => item.ghl_id === selectedGhlAccount);
        if (!matchingItem) {
          updatedClientCreatedData.push({
            ghl_id: selectedGhlAccount,
            [updateType]: "true",
          });
        }

        return {
          ...prevData,
          otherEvents: updatedClientCreatedData,
        };
      });
    }
  };

  const handleManageButton = (keyToUpdate) => {
    if (keyToUpdate === "manageClass" && updatedClassCalendar.length === 0) {
      reloadClassCalendarDetails();
    } else if (keyToUpdate === "manageAppointment" && updatedAppointmentCalendar.length === 0) {
      reloadAppointmentCalendarDetails();
    } else {
      const updatedServicesSelected = { ...manageModal };
      updatedServicesSelected[keyToUpdate] = !updatedServicesSelected[keyToUpdate];
      setManageModal(updatedServicesSelected);
    }
  };

  const handleServicesSelectedReset = () => {
    setLocalConnectionData(data);
  };

  const handleSubmitButton = () => {
    setTimeout(() => {
      setTriggerServicesSaveButton(false);
      let toastBox = toast;

      let invokePromise = updateMBWebhook(localConnectionData);
      toast.loading("Saving...", {
        id: toastBox,
      });
      invokePromise.then(
        (data) => {
          toast.success("Connection updated successfully", {
            id: toastBox,
          });
          reloadWebhookData();
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      );
    }, 300);
  };

  return (
    <div>
      {manageModal.manageClass && (
        <ServicesClassCalendar
          open={manageModal.manageClass}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              manageClass: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          connectionData={localConnectionData}
          setConnectionData={(data) => setLocalConnectionData(data)}
        />
      )}
      {triggerServicesSaveButton && handleSubmitButton()}
      {manageModal.manageAppointment && (
        <ServicesAppointmentCalendar
          open={manageModal.manageAppointment}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              manageAppointment: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          connectionData={localConnectionData}
          setConnectionData={(data) => setLocalConnectionData(data)}
        />
      )}
      {manageModal.clientSale && (
        <ServicesClientSale
          open={manageModal.clientSale}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              clientSale: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          connectionData={localConnectionData}
          setConnectionData={(data) => setLocalConnectionData(data)}
        />
      )}
      {manageModal.clientCreated && (
        <ServicesClientCreated
          open={manageModal.clientCreated}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              clientCreated: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          connectionData={localConnectionData}
          setConnectionData={(data) => setLocalConnectionData(data)}
        />
      )}
      {manageModal.membershipAdd && (
        <ServicesContractCreated
          open={manageModal.membershipAdd}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              membershipAdd: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          connectionData={localConnectionData}
          setConnectionData={(data) => setLocalConnectionData(data)}
        />
      )}
      {manageModal.membershipCancel && (
        <ServicesContractUpdate
          open={manageModal.membershipCancel}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              membershipCancel: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          connectionData={localConnectionData}
          setConnectionData={(data) => setLocalConnectionData(data)}
        />
      )}
      {manageModal.membershipDeclined && (
        <ServicesMembershipCancel
          open={manageModal.membershipDeclined}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              membershipDeclined: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          connectionData={localConnectionData}
          setConnectionData={(data) => setLocalConnectionData(data)}
        />
      )}
      {manageModal.clientUpdate && (
        <ServicesClientUpdate
          open={manageModal.clientUpdate}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              clientUpdate: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          connectionData={localConnectionData}
          setConnectionData={(data) => setLocalConnectionData(data)}
        />
      )}
      <div className="w-full mx-auto flex justify-between place-items-center">
        <Typography.Text>Click on service name to enable / disable</Typography.Text>
        <Button
          onClick={() => {
            setUpdatedClassCalendar(classCalendar);
            setUpdatedAppointmentCalendar(appointmentCalendar);
            handleServicesSelectedReset();
          }}
        >
          Reset to Previous
        </Button>
      </div>
      <Divider className="my-2" />
      <div className="mb-2">
        <Select placeholder="Select an account" className="ml-2 lg:max-w-xs xl:w-52" onChange={handleSelectGhlAccount} value={selectedGhlAccount}>
          {ghlAvailAccounts.length !== 0 &&
            ghlAvailAccounts.map((data, index) => {
              return (
                <Select.Option key={index} value={data.id}>
                  {data.name}
                </Select.Option>
              );
            })}
        </Select>
      </div>

      {selectedGhlAccount && (
        <div className="grid w-full grid-cols-1 lg:grid-cols-2 gap-2">
          <Search
            defaultValue={"Class connection"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("manageClass");
            }}
            // className={data.classCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("classCalendar");
            }}
          />
          <Search
            defaultValue={"Appointment connection"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("manageAppointment");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          <Search
            defaultValue={"Client sale"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("clientSale");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          <Search
            defaultValue={"Client creation"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("clientCreated");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          <Search
            defaultValue={"Client update"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("clientUpdate");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          <Search
            defaultValue={"Contract purchase"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("membershipAdd");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          <Search
            defaultValue={"Contract update/cancellation"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("membershipCancel");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          <Search
            defaultValue={"Membership declined"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("membershipDeclined");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          {/* <Search
            defaultValue={"Contact creation"}
            readOnly
            enterButton={localConnectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.clientCreated === "true" ? "Enabled" : "Disabled"}
            onSearch={() => {
              handleSelectServiceButton("clientCreated");
            }}
            className={
              localConnectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.clientCreated === "true"
                ? "bg-green-500 rounded-md max-w-[17rem] mx-auto custom-search-input select-none"
                : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input select-none"
            }
            onClick={() => {
              handleSelectServiceButton("clientCreated");
            }}
          />
          <Search
            defaultValue={"Contact update"}
            readOnly
            enterButton={localConnectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.clientUpdate === "true" ? "Enabled" : "Disabled"}
            onSearch={() => {
              handleSelectServiceButton("clientUpdate");
            }}
            className={
              localConnectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.clientUpdate === "true"
                ? "bg-green-500 rounded-md max-w-[17rem] mx-auto custom-search-input select-none"
                : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input select-none"
            }
            onClick={() => {
              handleSelectServiceButton("clientUpdate");
            }}
          />
          <Search
            defaultValue={"Membership purchase"}
            readOnly
            enterButton={localConnectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.membershipAdd === "true" ? "Enabled" : "Disabled"}
            onSearch={() => {
              handleSelectServiceButton("membershipAdd");
            }}
            className={localConnectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.membershipAdd === "true" ? "bg-green-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("membershipAdd");
            }}
          />
          <Search
            defaultValue={"Membership cancellation"}
            readOnly
            enterButton={localConnectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.membershipCancel === "true" ? "Enabled" : "Disabled"}
            onSearch={() => {
              handleSelectServiceButton("membershipCancel");
            }}
            className={localConnectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.membershipCancel === "true" ? "bg-green-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("membershipCancel");
            }}
          /> */}
        </div>
      )}

      {/* <div className="grid w-full grid-cols-1 lg:grid-cols-2 gap-2">
        <Search
          defaultValue={"Class connection"}
          readOnly
          enterButton={servicesSelected.manageClass ? "Manage" : "Disabled"}
          onSearch={() => {
            if (servicesSelected.manageClass) handleManageButton("manageClass");
            else handleSelectServiceButton("manageClass");
          }}
          className={servicesSelected.manageClass ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
          onClick={() => {
            handleSelectServiceButton("manageClass");
          }}
        />
        <Search
          defaultValue={"Appointment connection"}
          readOnly
          enterButton={servicesSelected.manageAppointment ? "Manage" : "Disabled"}
          onSearch={() => {
            if (servicesSelected.manageAppointment) handleManageButton("manageAppointment");
            else handleSelectServiceButton("manageAppointment");
          }}
          className={servicesSelected.manageAppointment ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
          onClick={() => {
            handleSelectServiceButton("manageAppointment");
          }}
        />
        <Search
          defaultValue={"Contact creation"}
          readOnly
          enterButton={servicesSelected.clientCreated ? "Enabled" : "Disabled"}
          onSearch={() => {
            handleSelectServiceButton("clientCreated");
          }}
          className={servicesSelected.clientCreated ? "bg-green-500 rounded-md max-w-[17rem] mx-auto custom-search-input select-none" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input select-none"}
          onClick={() => {
            handleSelectServiceButton("clientCreated");
          }}
        />
        <Search
          defaultValue={"Membership purchase"}
          readOnly
          enterButton={servicesSelected.membershipAdd ? "Enabled" : "Disabled"}
          onSearch={() => {
            handleSelectServiceButton("membershipAdd");
          }}
          className={servicesSelected.membershipAdd ? "bg-green-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
          onClick={() => {
            handleSelectServiceButton("membershipAdd");
          }}
        />
        <Search
          defaultValue={"Membership cancellation"}
          readOnly
          enterButton={servicesSelected.membershipCancel ? "Enabled" : "Disabled"}
          onSearch={() => {
            handleSelectServiceButton("membershipCancel");
          }}
          className={servicesSelected.membershipCancel ? "bg-green-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
          onClick={() => {
            handleSelectServiceButton("membershipCancel");
          }}
        />
      </div> */}
    </div>
  );
};

export default ManageServicesTab;
