import { Alert, Button, Select, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { getCalendarDetailsForGhl, getClassDetailsForGhl } from "../../../../../../helpers/AppUserApi";
const { Text } = Typography;

const LinkClassCalendarGhlConnection = ({ connectionData, setConnectionData }) => {
  const [calendarAvailDetails, setCalendarAvailDetails] = useState([]);
  const [classAvailDetails, setClassAvailDetails] = useState([]);

  useEffect(() => {
    const getMindbodyCalendarAvail = () => {
      const toastBox = toast;
      const getLocationPromise = getCalendarDetailsForGhl({
        ghl_id: connectionData?.ghl_id,
      });
      getLocationPromise
        .then(
          (data) => {
            if (data) {
              setCalendarAvailDetails(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    };

    const getMindbodyClassAvail = () => {
      const toastBox = toast;
      const getMBAvailableClassPromise = getClassDetailsForGhl({
        apikey: connectionData?.mb_apikey,
        siteid: connectionData?.mb_siteid,
      });
      getMBAvailableClassPromise
        .then(
          (data) => {
            if (data) {
              setClassAvailDetails(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    };
    getMindbodyCalendarAvail();
    getMindbodyClassAvail();
  }, [connectionData]);

  const handleConnectionRemove = (index) => {
    setConnectionData((prevConnectionData) => {
      const updatedClassCalendar = [...prevConnectionData.classCalendar];
      const updatedObjects = updatedClassCalendar.filter((obj) => obj.index !== index);
      return {
        ...prevConnectionData,
        classCalendar: updatedObjects,
      };
    });
  };

  const handleCalendarSelect = (calendarId, index) => {
    setConnectionData((prevConnectionData) => {
      const updatedClassCalendar = [...prevConnectionData.classCalendar];

      const objectIndex = updatedClassCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedClassCalendar[objectIndex],
          calendarId: calendarId,
        };

        updatedClassCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        classCalendar: updatedClassCalendar,
      };
    });
  };

  const handleClassSelect = (className, index) => {
    setConnectionData((prevConnectionData) => {
      const updatedClassCalendar = [...prevConnectionData.classCalendar];

      const objectIndex = updatedClassCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedClassCalendar[objectIndex],
          className: className,
        };

        updatedClassCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        classCalendar: updatedClassCalendar,
      };
    });
  };

  const handleClassBookingSelect = (index) => {
    const bookingCurrentStatus = connectionData?.classCalendar.filter((element) => element.index === index)[0]?.bookingActive;

    setConnectionData((prevConnectionData) => {
      const updatedClassCalendar = [...prevConnectionData.classCalendar];

      const objectIndex = updatedClassCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedClassCalendar[objectIndex],
          bookingActive: bookingCurrentStatus === "true" ? "false" : "true",
        };

        updatedClassCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        classCalendar: updatedClassCalendar,
      };
    });
  };

  const handleClassCancellingSelect = (index) => {
    const cancelCurrentStatus = connectionData?.classCalendar.filter((element) => element.index === index)[0]?.cancelActive;

    setConnectionData((prevConnectionData) => {
      const updatedClassCalendar = [...prevConnectionData.classCalendar];

      const objectIndex = updatedClassCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedClassCalendar[objectIndex],
          cancelActive: cancelCurrentStatus === "true" ? "false" : "true",
        };

        updatedClassCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        classCalendar: updatedClassCalendar,
      };
    });
  };

  return (
    <>
      <Alert message="Note: Both class booking and cancellation share this connection" type="warning" />
      {connectionData?.classCalendar.length !== 0 &&
        connectionData?.classCalendar.map(({ index, calendarId, name, className }) => {
          return (
            <div key={index} className="grid grid-flow-col mb-6">
              <div className="grid lg:grid-cols-2 grid-cols-1 mt-2 gap-2">
                <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                  <Text className="text-base">Select Calendar : </Text>
                  <Select placeholder="Select a calendar" className="max-w-[12rem] w-full" defaultValue={calendarId ? calendarId : null} onChange={(e) => handleCalendarSelect(e, index)}>
                    {calendarAvailDetails.length !== 0 &&
                      calendarAvailDetails.map((element) => {
                        return (
                          <Select.Option key={element.id} value={element.id}>
                            {element.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
                <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                  <Text className="text-base">Select Class : </Text>
                  <Select placeholder="Select a class" className="max-w-[12rem] w-full" defaultValue={className ? className : null} onChange={(e) => handleClassSelect(e, index)}>
                    {classAvailDetails.length !== 0 &&
                      classAvailDetails.map((element) => {
                        return (
                          <Select.Option key={element.name} value={element.name}>
                            {element.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
                <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                  <Button type="dashed" className={connectionData?.classCalendar.filter((element) => element.index === index)[0]?.bookingActive === "true" ? "w-11/12 bg-green-300" : "w-11/12"} onClick={() => handleClassBookingSelect(index)}>
                    Booking
                  </Button>
                </div>
                <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                  <Button type="dashed" className={connectionData?.classCalendar.filter((element) => element.index === index)[0]?.cancelActive === "true" ? "w-11/12 bg-green-300" : "w-11/12"} onClick={() => handleClassCancellingSelect(index)}>
                    Cancellation
                  </Button>
                </div>
              </div>
              <div className="w-full place-items-center m-auto justify-center text-center">
                <MinusCircleOutlined
                  onClick={() => {
                    handleConnectionRemove(index);
                  }}
                />
              </div>
            </div>
          );
        })}

      <div className="w-11/12 mx-auto mt-4">
        <Button
          type="dashed"
          onClick={() => {
            const length = Number(connectionData?.classCalendar.length + 1);
            const newValue = {
              index: length + Math.random(),
              calendarId: "",
              className: "",
              type: "new",
              bookingActive: "false",
              cancelActive: "false",
            };
            setConnectionData((prevConnectionData) => {
              return {
                ...prevConnectionData,
                classCalendar: [...prevConnectionData.classCalendar, newValue],
              };
            });
          }}
          block
          icon={<PlusOutlined />}
        >
          Add connection
        </Button>
      </div>
    </>
  );
};

export default LinkClassCalendarGhlConnection;
