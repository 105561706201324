// import React from "react";
// import { Fragment } from "react";
// // import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Card, Typography, CardBody, Input } from "@material-tailwind/react";
// import { useFormik } from "formik";
// import { Toaster, toast } from "react-hot-toast";
// import { useMediaQuery } from "react-responsive";
// import { registerUser } from "../../../../helpers/AppUserApi";
// import { Input, Modal, Select, Space, Switch } from "antd";
// const { Option } = Select;

// const AddNewUserModal = ({ open, setOpen }) => {
//   const isMobileScreen = useMediaQuery({ query: "(max-width: 1045px)" });
//   const isMobileScreenShorter = useMediaQuery({ query: "(max-width: 487px)" });
//   const excessModalHeight = useMediaQuery({ query: "(min-height: 800px)" });

//   const formik = useFormik({
//     initialValues: {
//       name: "",
//       email: "",
//       password: "",
//       confirmPassword: "",
//       type: "user",
//     },
//     validate: false,
//     validateOnBlur: false,
//     validateOnChange: false,
//     onSubmit: async (values) => {
//       let toastBox = toast;
//       toast.loading("Loading...", {
//         id: toastBox,
//       });
//       if (values.confirmPassword === values.password) {
//         const registerUserPromise = registerUser(values);
//         registerUserPromise.then(
//           (data) => {
//             toast.success("User added successfully", {
//               id: toastBox,
//             });
//             window.location.reload();
//           },
//           (msg) => {
//             toast.error(`${msg}`, {
//               id: toastBox,
//             });
//           }
//         );
//       } else {
//         toast.error(`Password doesn't match!`, {
//           id: toastBox,
//         });
//       }
//     },
//   });

//   const handleOpen = () => {
//     setOpen(!open);
//   };

//   return (
//     // <Fragment>
//     //   <Dialog open={open} handler={handleOpen} size={isMobileScreen ? (isMobileScreenShorter ? "xxl" : "xl") : "md"} className={!excessModalHeight ? "overflow-y-scroll" : "overflow-y-visible"} style={{ maxHeight: "90vh" }}>
//     //     <Toaster position="top-center" reverseOrder={false}></Toaster>
//     //     <DialogHeader>Add new user</DialogHeader>
//     //     <form onSubmit={formik.handleSubmit}>
//     //       <DialogBody divider>
//     //         <Card className="mt-5 w-3/4 md:w-96 mx-auto justify-center loginCard">
//     //           <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
//     //             Enter the following details
//     //           </Typography>
//     //           {open && (
//     //             <CardBody className="flex flex-col gap-4">
//     //               <Input label="Name" size="lg" {...formik.getFieldProps("name")} required />
//     //               <Input label="Email" size="lg" type="email" {...formik.getFieldProps("email")} required />
//     //               <Input label="Password" size="lg" type="password" {...formik.getFieldProps("password")} required />
//     //               <Input label="Confirm password" size="lg" type="password" {...formik.getFieldProps("confirmPassword")} required />
//     //               <Switch checkedChildren="Marianatek" unCheckedChildren="Mindbody" size="default" defaultChecked />
//     //             </CardBody>
//     //           )}
//     //         </Card>
//     //       </DialogBody>
//     //       <DialogFooter>
//     //         <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
//     //           <span>Cancel</span>
//     //         </Button>
//     //         <Button
//     //           variant="gradient"
//     //           color="green"
//     //           // onClick={handleConfirmButton}
//     //           type="submit"
//     //         >
//     //           <span>Confirm</span>
//     //         </Button>
//     //       </DialogFooter>
//     //     </form>
//     //   </Dialog>
//     // </Fragment>
//     <Modal title="Create a new user" open={open} onOk={handleOpen} onCancel={handleOpen} className="mx-auto text-center">
//       <Space direction="vertical" className="mx-auto w-10/12 text-center">
//         <Input placeholder="Name" required size="large" />
//         <Input placeholder="Email" required size="large" />
//         <Input.Password placeholder="Password" size="large" />
//         <Input.Password placeholder="Confirm Password" size="large" />
//         <Input placeholder="Confirm password" required size="large" />
//       </Space>
//     </Modal>
//   );
// };

// export default AddNewUserModal;

import React, { useState } from "react";
import { useFormik } from "formik";
import { Modal, Input, Space, Select, Button } from "antd";
import { Toaster, toast } from "react-hot-toast";
import * as Yup from "yup";
import { registerUser } from "../../../../helpers/AppUserApi";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  connectionWith: Yup.array().of(Yup.string()).required("Connection is required"),
});

const { Option } = Select;

const AddNewUserModal = ({ open, setOpen }) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      type: "user", // Set type as 'user'
      connectionWith: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const toastBox = toast.loading("Loading...");
      try {
        await registerUser(values);
        toast.success("User added successfully", { id: toastBox });
        window.location.reload();
        setOpen(false); // Close modal after success
      } catch (error) {
        toast.error(`${error}`, { id: toastBox });
      } finally {
        setLoading(false);
      }
    },
  });

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Modal
      title="Create a new user"
      open={open}
      onOk={formik.handleSubmit}
      footer={[
        <Button key="back" onClick={handleOpen}>
          Cancel
        </Button>,
        <Button className="bg-green-500" key="submit" type="primary" loading={loading} onClick={() => formik.handleSubmit()}>
          Submit
        </Button>,
      ]}
      className="mx-auto text-center"
    >
      <Toaster position="top-center" reverseOrder={false} />
      <form onSubmit={formik.handleSubmit} className="w-full">
        <Space direction="vertical" className="mx-auto w-10/12 text-center">
          <div className="mb-4">
            <Input placeholder="Name" name="name" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} size="large" className={`w-full ${formik.touched.name && formik.errors.name ? "border-red-500" : ""}`} />
            {formik.touched.name && formik.errors.name ? <div className="text-red-500 text-sm mt-1">{formik.errors.name}</div> : null}
          </div>

          <div className="mb-4">
            <Input placeholder="Email" name="email" type="email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} size="large" className={`w-full ${formik.touched.email && formik.errors.email ? "border-red-500" : ""}`} />
            {formik.touched.email && formik.errors.email ? <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div> : null}
          </div>

          <div className="mb-4">
            <Input.Password placeholder="Password" name="password" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} size="large" className={`w-full ${formik.touched.password && formik.errors.password ? "border-red-500" : ""}`} />
            {formik.touched.password && formik.errors.password ? <div className="text-red-500 text-sm mt-1">{formik.errors.password}</div> : null}
          </div>

          <div className="mb-4">
            <Input.Password
              placeholder="Confirm Password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size="large"
              className={`w-full ${formik.touched.confirmPassword && formik.errors.confirmPassword ? "border-red-500" : ""}`}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? <div className="text-red-500 text-sm mt-1">{formik.errors.confirmPassword}</div> : null}
          </div>

          <div className="mb-4">
            <Select
              size="large"
              mode="multiple"
              placeholder="Select connections"
              name="connectionWith"
              value={formik.values.connectionWith}
              onChange={(value) => formik.setFieldValue("connectionWith", value)}
              onBlur={formik.handleBlur}
              className={`w-full ${formik.touched.connectionWith && formik.errors.connectionWith ? "border-red-500" : ""}`}
            >
              <Option value="MT">Mariana-Tek</Option>
              <Option value="MB">Mindbody</Option>
            </Select>
            {formik.touched.connectionWith && formik.errors.connectionWith ? <div className="text-red-500 text-sm mt-1">{formik.errors.connectionWith}</div> : null}
          </div>
        </Space>
      </form>
    </Modal>
  );
};

export default AddNewUserModal;
