import React, { useContext, useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { FiShoppingCart } from "react-icons/fi";
import { BsChatLeft } from "react-icons/bs";
import { RiNotification3Line } from "react-icons/ri";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Button, DatePicker, Space } from "antd";
import avatar from "../../../data/images/ghl_logo.png";
// import { Cart, Chat, Notification, UserProfile } from ".";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useLocation } from "react-router-dom";
import AuthContext from "../../../contexts/AuthContextProvider";
import { switchBackToAdminAccount } from "../../../helpers/AppUserApi";
import toast from "react-hot-toast";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button type="button" onClick={() => customFunc()} style={{ color }} className="relative text-xl rounded-full p-3 hover:bg-light-gray">
      <span style={{ background: dotColor }} className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2" />
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const { RangePicker } = DatePicker;
  const { currentColor, activeMenu, setActiveMenu, isClicked, setScreenSize, screenSize } = useStateContext();
  const { auth, setAuth } = useContext(AuthContext);
  const isAdmin = auth?.userType?.includes("admin");
  const email = auth?.subEmail;
  const isSubLogin = auth?.subLogin;
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
    // eslint-disable-next-line
  }, [screenSize]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleButtonClick = () => {
    let toastBox = toast;
    const subAccountLoginPromise = switchBackToAdminAccount();
    subAccountLoginPromise
      .then(
        (data) => {
          const userActive = data?.active;
          const userType = Array.isArray(data?.usertype) ? data?.usertype : [data?.usertype];
          const subLogin = data?.subLogin || false;
          setAuth({ userType, userActive, subLogin });
          window.location.replace("/dashboard/manageusers");
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  return (
    <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative">
      <div className="flex items-center gap-4">
        <NavButton title="Menu" customFunc={handleActiveMenu} color={currentColor} icon={<AiOutlineMenu />} />
        {/* {location.pathname === "/dashboard/overview" && (
          <Space direction="vertical" size={12}>
            <RangePicker />
          </Space>
        )} */}
      </div>

      <div className="flex">
        {/* <NavButton title="Cart" customFunc={() => handleClick("cart")} color={currentColor} icon={<FiShoppingCart />} />
        <NavButton title="Chat" dotColor="#03C9D7" customFunc={() => handleClick("chat")} color={currentColor} icon={<BsChatLeft />} />
        <NavButton title="Notification" dotColor="rgb(254, 201, 15)" customFunc={() => handleClick("notification")} color={currentColor} icon={<RiNotification3Line />} /> */}
        {isSubLogin && (
          <TooltipComponent content="Profile" position="BottomCenter">
            <div className="relative">
              <div className="h-10 flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg" onClick={handleClick}>
                <p>
                  <span className="text-gray-400 text-14">Hi,</span> <span className="text-gray-400 font-bold ml-1 text-14">{email}</span>
                </p>
                <MdKeyboardArrowDown className="text-gray-400 text-14" />
              </div>
              {isDropdownOpen && (
                <div className="absolute top-12 right-0 bg-white border rounded-md p-2">
                  {/* Additional button */}
                  <Button onClick={handleButtonClick} type="text">
                    Switch back
                  </Button>
                </div>
              )}
            </div>
          </TooltipComponent>
        )}

        {/* {isClicked.cart && <Cart />}
        {isClicked.chat && <Chat />}
        {isClicked.notification && <Notification />} */}
      </div>
    </div>
  );
};

export default Navbar;
