import { Button, Divider, Select, Typography } from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useState } from "react";
import "./ManageServicesTab.css";
import { getMBClassCalendarDetails } from "../../../../../../helpers/MbApi";
import { toast } from "react-hot-toast";
import { updateMBWebhook } from "../../../../../../helpers/WebhookApi";
import { getGHLAvailAccounts } from "../../../../../../helpers/AppUserApi";
import { getMtAvailLocations, getMtClassCalendarDetails, updateMtWebhook } from "../../../../../../helpers/MtApi";
import ServicesMtClassCalendar from "../../connectionAddition/marianatekConnection/ServicesMtClassCalendar";
import ServicesMtClientSale from "../../connectionAddition/marianatekConnection/ServicesMtClientSale";
import ServicesMtClientCreated from "../../connectionAddition/marianatekConnection/ServicesMtClientCreated";
import ServicesMtContractCreated from "../../connectionAddition/marianatekConnection/ServicesMtContractCreated";
import ServicesMtContractUpdate from "../../connectionAddition/marianatekConnection/ServicesMtContractUpdate";
import ServicesMtMembershipCancel from "../../connectionAddition/marianatekConnection/ServicesMtMembershipCancel";
import ServicesMtClientUpdate from "../../connectionAddition/marianatekConnection/ServicesMtClientUpdate";

const ManageMtServicesTab = ({ data, triggerServicesSaveButton, setTriggerServicesSaveButton, setActiveServicesSaveTrigger, reloadWebhookData }) => {
  const [servicesSelected, setServicesSelected] = useState({
    manageClass: false,
    manageAppointment: false,
    clientCreated: false,
    membershipAdd: false,
    membershipCancel: false,
    membershipDeclined: false,
  });

  const [manageModal, setManageModal] = useState({
    manageClass: false,
    clientSale: false,
    clientCreated: false,
    membershipAdd: false,
    membershipCancel: false,
    clientUpdate: false,
    membershipDeclined: false,
  });

  const [localConnectionData, setLocalConnectionData] = useState(data);
  const [selectedGhlAccount, setSelectedGhlAccount] = useState(null);
  const [selectedMtLocation, setSelectedMtLocation] = useState(null);
  const [mtLocations, setMtLocations] = useState([]);

  const [classCalendar, setClassCalendar] = useState([]);
  const [updatedClassCalendar, setUpdatedClassCalendar] = useState([]);
  const [ghlAvailAccounts, setGhlAvailAccounts] = useState([]);
  const [dataLoader, setDataLoader] = useState({
    ghlAccounts: false,
    mtAccounts: false,
  });

  ////// save new state functions ////////

  const handleSelectGhlAccount = (e) => {
    setSelectedGhlAccount(e);
  };

  ////// api-call functions //////

  const getGHLContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getGHLAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setGhlAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, ghlAccounts: true }));
      });
  };

  const getMtLocationAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getMtAvailLocations(data?.mt_id);
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setMtLocations(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const reloadClassCalendarDetails = () => {
    const toastBox = toast;
    const mt_id = data?.mt_id;
    const getMBClassCalendarDetailsPromise = getMtClassCalendarDetails({ mt_id: mt_id });
    getMBClassCalendarDetailsPromise
      .then(
        (data) => {
          if (data) {
            setClassCalendar(data);
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        const updatedServicesSelected = { ...manageModal };
        updatedServicesSelected["manageClass"] = !updatedServicesSelected["manageClass"];
        setManageModal(updatedServicesSelected);
      });
  };

  /////// use effects ////////

  useEffect(() => {
    setLocalConnectionData(data);
  }, [data]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(localConnectionData)) {
      setActiveServicesSaveTrigger(true);
    } else setActiveServicesSaveTrigger(false);
  }, [data, localConnectionData, setActiveServicesSaveTrigger]);

  useEffect(() => {
    getMtLocationAvailAccFunc();
    getGHLContactAvailAccFunc();
  }, []);

  useEffect(() => {
    const getClassCalendarDetails = (data) => {
      const toastBox = toast;
      const mt_id = data?.mt_id;
      const getMBClassCalendarDetailsPromise = getMtClassCalendarDetails({ mt_id: mt_id });
      getMBClassCalendarDetailsPromise
        .then(
          (data) => {
            if (data) {
              setClassCalendar(data);
              setUpdatedClassCalendar(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    };
    getClassCalendarDetails(data);
  }, [data]);

  /////// button handlers ///////

  const handleSelectServiceButton = (keyToUpdate) => {
    let updateType = null;
    // Update the state with the new object
    if (keyToUpdate === "clientCreated") updateType = "clientCreated";
    else if (keyToUpdate === "clientUpdate") updateType = "clientUpdate";
    else if (keyToUpdate === "membershipAdd") updateType = "membershipAdd";
    else if (keyToUpdate === "membershipCancel") updateType = "membershipCancel";
    else if (keyToUpdate === "clientSale") updateType = "clientSale";
  };

  const handleManageButton = (keyToUpdate) => {
    if (keyToUpdate === "manageClass" && updatedClassCalendar.length === 0) {
      reloadClassCalendarDetails();
    } else {
      const updatedServicesSelected = { ...manageModal };
      updatedServicesSelected[keyToUpdate] = !updatedServicesSelected[keyToUpdate];
      setManageModal(updatedServicesSelected);
    }
  };

  const handleServicesSelectedReset = () => {
    setLocalConnectionData(data);
  };

  const handleSubmitButton = () => {
    setTimeout(() => {
      setTriggerServicesSaveButton(false);
      let toastBox = toast;

      let invokePromise = updateMtWebhook(localConnectionData);
      toast.loading("Saving...", {
        id: toastBox,
      });
      invokePromise.then(
        (data) => {
          toast.success("Connection updated successfully", {
            id: toastBox,
          });
          reloadWebhookData();
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      );
    }, 300);
  };

  return (
    <div>
      {manageModal.manageClass && (
        <ServicesMtClassCalendar
          open={manageModal.manageClass}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              manageClass: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          selectedLocationId={selectedMtLocation}
          connectionData={localConnectionData}
          setConnectionData={(data) => setLocalConnectionData(data)}
        />
      )}
      {triggerServicesSaveButton && handleSubmitButton()}
      {manageModal.clientSale && (
        <ServicesMtClientSale
          open={manageModal.clientSale}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              clientSale: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          selectedMtLocation={selectedMtLocation}
          connectionData={localConnectionData}
          setConnectionData={(data) => setLocalConnectionData(data)}
        />
      )}
      {manageModal.clientCreated && (
        <ServicesMtClientCreated
          open={manageModal.clientCreated}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              clientCreated: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          selectedMtLocation={selectedMtLocation}
          connectionData={localConnectionData}
          setConnectionData={(data) => setLocalConnectionData(data)}
        />
      )}
      {manageModal.membershipAdd && (
        <ServicesMtContractCreated
          open={manageModal.membershipAdd}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              membershipAdd: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          selectedMtLocation={selectedMtLocation}
          connectionData={localConnectionData}
          setConnectionData={(data) => setLocalConnectionData(data)}
        />
      )}
      {manageModal.membershipCancel && (
        <ServicesMtContractUpdate
          open={manageModal.membershipCancel}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              membershipCancel: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          selectedMtLocation={selectedMtLocation}
          connectionData={localConnectionData}
          setConnectionData={(data) => setLocalConnectionData(data)}
        />
      )}
      {manageModal.membershipDeclined && (
        <ServicesMtMembershipCancel
          open={manageModal.membershipDeclined}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              membershipDeclined: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          selectedMtLocation={selectedMtLocation}
          connectionData={localConnectionData}
          setConnectionData={(data) => setLocalConnectionData(data)}
        />
      )}
      {manageModal.clientUpdate && (
        <ServicesMtClientUpdate
          open={manageModal.clientUpdate}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              clientUpdate: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          selectedMtLocation={selectedMtLocation}
          connectionData={localConnectionData}
          setConnectionData={(data) => setLocalConnectionData(data)}
        />
      )}
      <div className="w-full mx-auto flex justify-between place-items-center">
        <Typography.Text>Click on service name to enable / disable</Typography.Text>
        <Button
          onClick={() => {
            setUpdatedClassCalendar(classCalendar);
            handleServicesSelectedReset();
          }}
        >
          Reset to Previous
        </Button>
      </div>
      <Divider className="my-2" />
      <div className="mb-2">
        <div className="grid 2xl:grid-cols-2 items-center gap-2 grid-cols-1">
          <div>
            <Typography.Text>GHL account: </Typography.Text>
            <Select placeholder="Select an account" className="ml-2 lg:max-w-xs xl:w-52" onChange={handleSelectGhlAccount} value={selectedGhlAccount}>
              {ghlAvailAccounts.length !== 0 &&
                ghlAvailAccounts.map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.id}>
                      {data.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          <div>
            <Typography.Text>MT Location: </Typography.Text>
            <Select placeholder="Select a location" className="ml-2 lg:max-w-xs xl:w-52" onChange={(e) => setSelectedMtLocation(e)} value={selectedMtLocation}>
              {mtLocations.length !== 0 &&
                mtLocations.map((data) => {
                  return (
                    <Select.Option key={data?.id} value={data?.id}>
                      {data?.attributes?.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
        </div>
      </div>

      {selectedGhlAccount && selectedMtLocation && (
        <div className="grid w-full grid-cols-1 lg:grid-cols-2 gap-2">
          <Search
            defaultValue={"Class connection"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("manageClass");
            }}
            // className={data.classCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("classCalendar");
            }}
          />
          <Search
            defaultValue={"Client sale"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("clientSale");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          <Search
            defaultValue={"Client creation"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("clientCreated");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          <Search
            defaultValue={"Client update"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("clientUpdate");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          <Search
            defaultValue={"Contract purchase"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("membershipAdd");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          <Search
            defaultValue={"Contract update/cancellation"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("membershipCancel");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          <Search
            defaultValue={"Membership declined"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("membershipDeclined");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          {/* <Search
            defaultValue={"Contact creation"}
            readOnly
            enterButton={localConnectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.clientCreated === "true" ? "Enabled" : "Disabled"}
            onSearch={() => {
              handleSelectServiceButton("clientCreated");
            }}
            className={
              localConnectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.clientCreated === "true"
                ? "bg-green-500 rounded-md max-w-[17rem] mx-auto custom-search-input select-none"
                : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input select-none"
            }
            onClick={() => {
              handleSelectServiceButton("clientCreated");
            }}
          />
          <Search
            defaultValue={"Contact update"}
            readOnly
            enterButton={localConnectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.clientUpdate === "true" ? "Enabled" : "Disabled"}
            onSearch={() => {
              handleSelectServiceButton("clientUpdate");
            }}
            className={
              localConnectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.clientUpdate === "true"
                ? "bg-green-500 rounded-md max-w-[17rem] mx-auto custom-search-input select-none"
                : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input select-none"
            }
            onClick={() => {
              handleSelectServiceButton("clientUpdate");
            }}
          />
          <Search
            defaultValue={"Membership purchase"}
            readOnly
            enterButton={localConnectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.membershipAdd === "true" ? "Enabled" : "Disabled"}
            onSearch={() => {
              handleSelectServiceButton("membershipAdd");
            }}
            className={localConnectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.membershipAdd === "true" ? "bg-green-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("membershipAdd");
            }}
          />
          <Search
            defaultValue={"Membership cancellation"}
            readOnly
            enterButton={localConnectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.membershipCancel === "true" ? "Enabled" : "Disabled"}
            onSearch={() => {
              handleSelectServiceButton("membershipCancel");
            }}
            className={localConnectionData?.otherEvents?.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.membershipCancel === "true" ? "bg-green-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("membershipCancel");
            }}
          /> */}
        </div>
      )}

      {/* <div className="grid w-full grid-cols-1 lg:grid-cols-2 gap-2">
        <Search
          defaultValue={"Class connection"}
          readOnly
          enterButton={servicesSelected.manageClass ? "Manage" : "Disabled"}
          onSearch={() => {
            if (servicesSelected.manageClass) handleManageButton("manageClass");
            else handleSelectServiceButton("manageClass");
          }}
          className={servicesSelected.manageClass ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
          onClick={() => {
            handleSelectServiceButton("manageClass");
          }}
        />
        <Search
          defaultValue={"Appointment connection"}
          readOnly
          enterButton={servicesSelected.manageAppointment ? "Manage" : "Disabled"}
          onSearch={() => {
            if (servicesSelected.manageAppointment) handleManageButton("manageAppointment");
            else handleSelectServiceButton("manageAppointment");
          }}
          className={servicesSelected.manageAppointment ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
          onClick={() => {
            handleSelectServiceButton("manageAppointment");
          }}
        />
        <Search
          defaultValue={"Contact creation"}
          readOnly
          enterButton={servicesSelected.clientCreated ? "Enabled" : "Disabled"}
          onSearch={() => {
            handleSelectServiceButton("clientCreated");
          }}
          className={servicesSelected.clientCreated ? "bg-green-500 rounded-md max-w-[17rem] mx-auto custom-search-input select-none" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input select-none"}
          onClick={() => {
            handleSelectServiceButton("clientCreated");
          }}
        />
        <Search
          defaultValue={"Membership purchase"}
          readOnly
          enterButton={servicesSelected.membershipAdd ? "Enabled" : "Disabled"}
          onSearch={() => {
            handleSelectServiceButton("membershipAdd");
          }}
          className={servicesSelected.membershipAdd ? "bg-green-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
          onClick={() => {
            handleSelectServiceButton("membershipAdd");
          }}
        />
        <Search
          defaultValue={"Membership cancellation"}
          readOnly
          enterButton={servicesSelected.membershipCancel ? "Enabled" : "Disabled"}
          onSearch={() => {
            handleSelectServiceButton("membershipCancel");
          }}
          className={servicesSelected.membershipCancel ? "bg-green-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
          onClick={() => {
            handleSelectServiceButton("membershipCancel");
          }}
        />
      </div> */}
    </div>
  );
};

export default ManageMtServicesTab;
