import axios from "axios";
import { API_URL, APP_WEBHOOK_BASE_URL } from "./AppApiUrls";

//const BASE_URL = "https://www.webhook.synx.ai/mindbody";
const BASE_URL = `${API_URL}${APP_WEBHOOK_BASE_URL}`;
//const BASE_URL = "http://13.232.11.86:8000/mindbody";
//const BASE_URL = "http://192.168.0.109:8000/mindbody";

export async function invokeMBWebhook(userdetails) {
  try {
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/invoke`, userdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      //  data: filterOptions,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function invokeMBWebhookUpdate(userdetails) {
  try {
    const {
      data: { msg, result },
    } = await axios.put(`${BASE_URL}/updateApi`, userdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteMBData(userdetails) {
  try {
    const {
      data: { msg },
    } = await axios.delete(`${BASE_URL}/delete`, { data: userdetails });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addMBWebhook(contactdetails) {
  try {
    const {
      data: { msg },
    } = await axios.post(`${BASE_URL}/webhook/add`, contactdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      //  data: filterOptions,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateMBWebhook(contactdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.post(`${BASE_URL}/webhook/update`, contactdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function generateMBWebhook() {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.get(`${BASE_URL}/webhook/init`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addNewClassCalendarWebhook(details) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.post(`${BASE_URL}/webhook/classcalendar/add`, details, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addNewAppointmentCalendarWebhook(details) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.post(`${BASE_URL}/webhook/appointmentcalendar/add`, details, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMindbodyWebhooks() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/webhook/webhooks`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else if (data.msg === "NO_WEBHOOKS") return Promise.reject(data.msg);
    else return Promise.reject({ msg: "mindbody webhooks retrieve failed" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function getClassBookingDetails(details) {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.post(`${BASE_URL}/webhook/calendar/calendars`, details, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data.msg === "success") return Promise.resolve(data.result);
    else if (data.msg === "NO_WEBHOOKS") return Promise.reject(data.msg);
    else return Promise.reject({ msg: "mindbody webhooks retrieve failed" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function getAppointmentBookingDetails(details) {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.post(`${BASE_URL}/webhook/appointment/appointments`, details, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data.msg === "success") return Promise.resolve(data.result);
    else if (data.msg === "NO_WEBHOOKS") return Promise.reject(data.msg);
    else return Promise.reject({ msg: "mindbody webhooks retrieve failed" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function deleteMindbodyWebhook(webdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.delete(`${BASE_URL}/webhook/delete`, { data: webdetails, headers: { "Content-Type": "application/json" }, withCredentials: true });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deactivateMindbodyWebhook() {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.delete(`${BASE_URL}/webhook/deactivate`, { headers: { "Content-Type": "application/json" }, withCredentials: true });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getActivationCode(details) {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.post(`${BASE_URL}/account/activation`, details, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.code);
    else return Promise.reject({ msg: "Activation code retrieve failed" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function getActiveWebhook() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/webhook/active`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data);
    else return Promise.reject(data.msg);
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}
