// import React from "react";
// import { Fragment } from "react";
// import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Card, Typography, CardBody, Input } from "@material-tailwind/react";
// import { useFormik } from "formik";
// import { Toaster, toast } from "react-hot-toast";
// import { useMediaQuery } from "react-responsive";
// import { updateUser } from "../../../../helpers/AppUserApi";

// const EditUserModal = ({ open, setOpen, userData }) => {
//   const isMobileScreen = useMediaQuery({ query: "(max-width: 1045px)" });
//   const isMobileScreenShorter = useMediaQuery({ query: "(max-width: 487px)" });
//   const excessModalHeight = useMediaQuery({ query: "(min-height: 800px)" });

//   const formik = useFormik({
//     initialValues: {
//       password: "",
//       confirmPassword: "",
//     },
//     validate: false,
//     validateOnBlur: false,
//     validateOnChange: false,
//     onSubmit: async (values) => {
//       let toastBox = toast;
//       toast.loading("Loading...", {
//         id: toastBox,
//       });
//       if (values.confirmPassword === values.password) {
//         const userid = userData[0].id;
//         if (userid) {
//           const newValues = { ...values, userid };
//           const updateUserPromise = updateUser(newValues);
//           updateUserPromise.then(
//             (data) => {
//               toast.success("User updated successfully", {
//                 id: toastBox,
//               });
//               window.location.reload();
//             },
//             (msg) => {
//               toast.error(`${msg}`, {
//                 id: toastBox,
//               });
//             }
//           );
//         } else {
//           toast.error("Invalid User", {
//             id: toastBox,
//           });
//         }
//       } else {
//         toast.error(`Password doesn't match!`, {
//           id: toastBox,
//         });
//       }
//     },
//   });

//   const handleOpen = () => {
//     setOpen(!open);
//   };

//   return (
//     <Fragment>
//       <Dialog open={open} handler={handleOpen} size={isMobileScreen ? (isMobileScreenShorter ? "xxl" : "xl") : "md"} className={!excessModalHeight ? "overflow-y-scroll" : "overflow-y-visible"} style={{ maxHeight: "90vh" }}>
//         <Toaster position="top-center" reverseOrder={false}></Toaster>
//         <DialogHeader>Change password</DialogHeader>
//         <form onSubmit={formik.handleSubmit}>
//           <DialogBody divider>
//             <Card className="mt-5 w-3/4 md:w-96 mx-auto justify-center loginCard">
//               <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
//                 Enter the following details
//               </Typography>
//               {open && (
//                 <CardBody className="flex flex-col gap-4">
//                   <Input label="Name" size="lg" value={`Name: ${userData[0].name}`} disabled />
//                   <Input label="Password" size="lg" type="password" {...formik.getFieldProps("password")} required />
//                   <Input label="Confirm password" size="lg" type="password" {...formik.getFieldProps("confirmPassword")} required />
//                 </CardBody>
//               )}
//             </Card>
//           </DialogBody>
//           <DialogFooter>
//             <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
//               <span>Cancel</span>
//             </Button>
//             <Button
//               variant="gradient"
//               color="green"
//               // onClick={handleConfirmButton}
//               type="submit"
//             >
//               <span>Change</span>
//             </Button>
//           </DialogFooter>
//         </form>
//       </Dialog>
//     </Fragment>
//   );
// };

// export default EditUserModal;

import React, { Fragment, useEffect } from "react";
import { Button, Modal, Input, Form, Space, Select } from "antd";
import { useFormik } from "formik";
import { Toaster, toast } from "react-hot-toast";
import { useMediaQuery } from "react-responsive";
import { updateUser } from "../../../../helpers/AppUserApi";

const EditUserModal = ({ open, setOpen, userData }) => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 1045px)" });
  const isMobileScreenShorter = useMediaQuery({ query: "(max-width: 487px)" });
  const excessModalHeight = useMediaQuery({ query: "(min-height: 800px)" });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      connectedWith: [],
    },
    validate: false,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      let toastBox = toast;
      toast.loading("Loading...", { id: toastBox });
      if (values.confirmPassword === values.password) {
        const userid = userData[0]?.id;
        if (userid) {
          const newValues = { ...values, userid };
          const updateUserPromise = updateUser(newValues);
          updateUserPromise.then(
            (data) => {
              toast.success("User updated successfully", { id: toastBox });
              window.location.reload();
            },
            (msg) => {
              toast.error(`${msg}`, { id: toastBox });
            }
          );
        } else {
          toast.error("Invalid User", { id: toastBox });
        }
      } else {
        toast.error(`Password doesn't match!`, { id: toastBox });
      }
    },
  });

  useEffect(() => {
    console.log(userData);
    formik.setFieldValue("connectedWith", userData[0]?.connectedWith?.split(","));
  }, []);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <Modal
        title="Change Details"
        open={open}
        footer={[
          <Button key="back" onClick={handleOpen}>
            Cancel
          </Button>,
          <Button className="bg-green-500" key="submit" type="primary" onClick={formik.handleSubmit}>
            Submit
          </Button>,
        ]}
      >
        <Toaster position="top-center" reverseOrder={false} />
        <Form onFinish={formik.handleSubmit}>
          <Space direction="vertical" className="w-full" size="large">
            <Input label="Name" size="large" value={`Name: ${userData[0]?.name}`} disabled />
            <Input.Password label="Password" size="large" placeholder="Enter new password" {...formik.getFieldProps("password")} required />
            <Input.Password label="Confirm Password" size="large" placeholder="Confirm new password" {...formik.getFieldProps("confirmPassword")} required />
            <div className="mb-4">
              <Select
                size="large"
                mode="multiple"
                placeholder="Select connections"
                name="connectedWith"
                value={formik.values.connectedWith}
                onChange={(value) => formik.setFieldValue("connectedWith", value)}
                onBlur={formik.handleBlur}
                className={`w-full ${formik.touched.connectedWith && formik.errors.connectedWith ? "border-red-500" : ""}`}
              >
                <Select.Option value="MT">Mariana-Tek</Select.Option>
                <Select.Option value="MB">Mindbody</Select.Option>
              </Select>
              {formik.touched.connectedWith && formik.errors.connectedWith ? <div className="text-red-500 text-sm mt-1">{formik.errors.connectedWith}</div> : null}
            </div>
          </Space>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default EditUserModal;
