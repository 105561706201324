import axios from "axios";
import { API_URL, APP_TRANSACTION_BASE_URL } from "./AppApiUrls";

const BASE_URL = `${API_URL}${APP_TRANSACTION_BASE_URL}`;
//const BASE_URL = "http://localhost:8000/app/api/transaction";
//const BASE_URL = "http://13.232.11.86:8000/app/api/user";
//const BASE_URL = "http://192.168.0.109:8000/app/api/user";

export async function fetchAllTransactionsSpecificUser(pageNumber) {
  try {
    const { data } = await axios.get(`${BASE_URL}/transactions`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      params: { page: pageNumber },
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else return Promise.reject({ msg: data.msg });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function fetchFilteredTransactionsSpecificUser(pageNumber, filterOptions) {
  if (filterOptions === undefined || filterOptions === null)
    filterOptions = {
      filterEvent: [],
      filterDate: [],
    };
  try {
    const { data } = await axios.post(`${BASE_URL}/transactions`, filterOptions, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      params: { page: pageNumber },
      //  data: filterOptions,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else return Promise.reject({ msg: data.msg });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function showFilteredTransactionsCountsSpecificUser(activeCardIndex, filterDate) {
  const filterOptions = {
    activeCardIndex: [activeCardIndex],
    filterDate: filterDate || [],
  };
  try {
    const { data } = await axios.post(`${BASE_URL}/counts`, filterOptions, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      //  data: filterOptions,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else {
      console.log(data?.reason);
      return Promise.reject({ msg: data.msg });
    }
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function showTransactionEventsSpecificUser() {
  try {
    const { data } = await axios.get(`${BASE_URL}/events`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      //  data: filterOptions,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else {
      console.log(data?.reason);
      return Promise.reject({ msg: data.msg });
    }
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function repeatTransaction(bodyData) {
  try {
    const { data } = await axios.post(`${BASE_URL}/repeat`, bodyData, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data.msg === "success" || data.msg === "failed" || data?.success || data?.success === false) return Promise.resolve(data);
    else return Promise.reject({ msg: data.msg });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}
