import React, { Fragment, useEffect, useState } from "react";
import { Button, Divider, Modal, Spin, Switch, Tabs, Typography } from "antd";
import LinkAccountsForm from "./subComponents/LinkAccountsForm";
import LinkCalendarForm from "./subComponents/LinkCalendarForm";
import { toast } from "react-hot-toast";
import { changeStatusContactWebhook, updateGhlAppointmentBookingWebhook, updateGhlClassBookingWebhook, updateGhlContactWebhook, updateGhlMembershipWebhook } from "../../../../../helpers/GhlApi";
import LinkGoHighLevelTags from "./subComponents/LinkGoHighLevelTags";
import "./ConnectionManage.css";
import ManageServicesTab from "./subComponents/ManageServicesTab";
import { updateMBWebhook } from "../../../../../helpers/WebhookApi";
import LinkMembershipGhlConnection from "../connectionAddition/gohighlevelConnection/LinkMembershipGhlConnection";
import LinkAccountGhlToMtConnection from "../connectionAddition/marianatekConnection/LinkAccountGhlToMtConnection";
import LinkClassGhlToMtConnection from "../connectionAddition/marianatekConnection/LinkClassGhlToMtConnection";
import { updateGhlToMtClassWebhook, updateGhlToMtMembershipWebhook, updateMtWebhook } from "../../../../../helpers/MtApi";
import LinkMembershipGhlToMtConnection from "../connectionAddition/marianatekConnection/LinkMembershipGhlToMtConnection";
import LinkAccountMtToGhlConnection from "../connectionAddition/marianatekConnection/LinkAccountMtToGhlConnection";
import ManageMtServicesTab from "./subComponents/ManageMtServicesTab";

const ConnectionManage = ({ existingData, open, setOpen, type, reloadWebhookData }) => {
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [manageType, setManageType] = useState("new");

  const [newData, setNewData] = useState(
    existingData
      ? existingData
      : {
          staffId: null,
          programId: null,
          forcefulBooking: "false",
          bookingActive: "false",
          calendarId: null,
          calendarName: null,
          cancelActive: "false",
          className: null,
          ghlName: null,
          ghl_id: null,
          key: null,
          mbName: null,
          mb_id: null,
          mb_apikey: null,
          mb_siteid: null,
          name: null,
          secret_key: null,
          userid: null,
          webhook_type: "manage_class",
        }
  );
  const [triggerServicesSaveButton, setTriggerServicesSaveButton] = useState(null);
  const [triggerMtServicesSaveButton, setTriggerMtServicesSaveButton] = useState(null);
  const [activeServicesSaveTrigger, setActiveServicesSaveTrigger] = useState(false);
  const [isClientFieldPresent, setIsClientFieldPresent] = useState(null);

  const [triggerTagsSaveButton, setTriggerTagsSaveButton] = useState(false);
  const [contactStatus, setContactStatus] = useState(existingData?.active === "true");

  const [setupDifficulty, setSetupDiffculty] = useState(true);

  const handleServicesSaveButton = () => {
    // Toggle the trigger state on button click
    setTriggerServicesSaveButton(!triggerServicesSaveButton);
  };

  const handleMtServicesSaveButton = () => {
    // Toggle the trigger state on button click
    setTriggerMtServicesSaveButton(!triggerMtServicesSaveButton);
  };

  const handleCustomTagsSaveButton = () => {
    // Toggle the trigger state on button click
    setTriggerTagsSaveButton(!triggerTagsSaveButton);
  };

  let TabData = [
    {
      label: `Setup connection accounts`,
      key: 1,
      children: (
        <div>
          <LinkAccountsForm data={newData} setData={(data) => setNewData(data)} webhook_type={type} />
        </div>
      ),
    },
    {
      label: `Link calendars`,
      key: 2,
      children: (
        <div>
          <LinkCalendarForm data={newData} setData={(data) => setNewData(data)} manageType={manageType} webhook_type={type} />
        </div>
      ),
    },
    {
      label: `Manage GoHighLevel tags`,
      key: 3,
      children: (
        <div>
          <LinkGoHighLevelTags data={newData} setData={(data) => setNewData(data)} manageType={manageType} webhook_type={type} isClientFieldPresent={isClientFieldPresent} setIsClientFieldPresent={(bool) => setIsClientFieldPresent(bool)} />
        </div>
      ),
    },
  ];

  let GhlToMtClassData = [
    {
      label: `Setup connection accounts`,
      key: 1,
      children: (
        <div>
          <LinkAccountGhlToMtConnection data={newData} setData={(data) => setNewData(data)} isExist={true} />
        </div>
      ),
    },
    {
      label: `Link calendars`,
      key: 2,
      children: (
        <div>
          <LinkClassGhlToMtConnection connectionData={newData} setConnectionData={(data) => setNewData(data)} isExist={true} />
        </div>
      ),
    },
    {
      label: `Manage GoHighLevel tags`,
      key: 3,
      children: (
        <div>
          <LinkGoHighLevelTags data={newData} setData={(data) => setNewData(data)} manageType={manageType} webhook_type={type} isClientFieldPresent={isClientFieldPresent} setIsClientFieldPresent={(bool) => setIsClientFieldPresent(bool)} />
        </div>
      ),
    },
  ];

  let GhlMembershipTabData = [
    {
      label: `Setup connection accounts`,
      key: 1,
      children: (
        <div>
          <LinkAccountsForm data={newData} setData={(data) => setNewData(data)} webhook_type={type} />
        </div>
      ),
    },
    {
      label: `Link membership details`,
      key: 2,
      children: (
        <div>
          <LinkMembershipGhlConnection connectionData={newData} setConnectionData={(data) => setNewData(data)} manageType={manageType} />
        </div>
      ),
    },
    {
      label: `Manage GoHighLevel tags`,
      key: 3,
      children: (
        <div>
          <LinkGoHighLevelTags data={newData} setData={(data) => setNewData(data)} manageType={manageType} webhook_type={type} isClientFieldPresent={isClientFieldPresent} setIsClientFieldPresent={(bool) => setIsClientFieldPresent(bool)} />
        </div>
      ),
    },
  ];

  let GhlToMtMembershipTabData = [
    {
      label: `Setup connection accounts`,
      key: 1,
      children: (
        <div>
          <LinkAccountGhlToMtConnection data={newData} setData={(data) => setNewData(data)} isExist={true} />
        </div>
      ),
    },
    {
      label: `Link membership details`,
      key: 2,
      children: (
        <div>
          <LinkMembershipGhlToMtConnection connectionData={newData} setConnectionData={(data) => setNewData(data)} isExist={true} />
        </div>
      ),
    },
    {
      label: `Manage GoHighLevel tags`,
      key: 3,
      children: (
        <div>
          <LinkGoHighLevelTags data={newData} setData={(data) => setNewData(data)} manageType={manageType} webhook_type={type} isClientFieldPresent={isClientFieldPresent} setIsClientFieldPresent={(bool) => setIsClientFieldPresent(bool)} />
        </div>
      ),
    },
  ];

  let ContactTabData = [
    {
      label: `Setup connection accounts`,
      key: 1,
      children: (
        <div>
          <LinkAccountsForm data={newData} setData={(data) => setNewData(data)} webhook_type={type} />
        </div>
      ),
    },
    {
      label: `Manage GoHighLevel tags`,
      key: 2,
      children: (
        <div>
          <LinkGoHighLevelTags data={newData} setData={(data) => setNewData(data)} manageType={manageType} isClientFieldPresent={isClientFieldPresent} setIsClientFieldPresent={(bool) => setIsClientFieldPresent(bool)} />
        </div>
      ),
    },
  ];

  let GhlToMtContactTabData = [
    {
      label: `Setup connection accounts`,
      key: 1,
      children: (
        <div>
          <LinkAccountGhlToMtConnection data={newData} setData={(data) => setNewData(data)} isExist={true} />
        </div>
      ),
    },
    {
      label: `Manage GoHighLevel tags`,
      key: 2,
      children: (
        <div>
          <LinkGoHighLevelTags data={newData} setData={(data) => setNewData(data)} manageType={manageType} isClientFieldPresent={isClientFieldPresent} setIsClientFieldPresent={(bool) => setIsClientFieldPresent(bool)} />
        </div>
      ),
    },
  ];

  let MindbodyTabData = [
    {
      label: `Setup connection accounts`,
      key: 1,
      children: (
        <div>
          <LinkAccountsForm data={newData} setData={(data) => setNewData(data)} webhook_type={type} setupDifficulty={setupDifficulty} />
        </div>
      ),
    },
    {
      label: `Manage services`,
      key: 2,
      children: (
        <div>
          {activeTab === 2 && (
            <ManageServicesTab
              data={newData}
              setData={(data) => setNewData(data)}
              webhook_type={type}
              reloadWebhookData={reloadWebhookData}
              triggerServicesSaveButton={triggerServicesSaveButton}
              setTriggerServicesSaveButton={(bool) => setTriggerServicesSaveButton(bool)}
              activeServicesSaveTrigger={activeServicesSaveTrigger}
              setActiveServicesSaveTrigger={(bool) => setActiveServicesSaveTrigger(bool)}
              setupDifficulty={setupDifficulty}
            />
          )}{" "}
        </div>
      ),
    },
    {
      label: `Manage GoHighLevel tags`,
      key: 3,
      children: (
        <div>
          {activeTab === 3 && (
            <LinkGoHighLevelTags
              data={newData}
              setData={(data) => setNewData(data)}
              manageType={manageType}
              reloadWebhookData={reloadWebhookData}
              webhook_type={type}
              triggerTagsSaveButton={triggerTagsSaveButton}
              setTriggerTagsSaveButton={(bool) => setTriggerTagsSaveButton(bool)}
              setupDifficulty={setupDifficulty}
              isClientFieldPresent={isClientFieldPresent}
              setIsClientFieldPresent={(bool) => setIsClientFieldPresent(bool)}
            />
          )}
        </div>
      ),
    },
  ];

  let MarianatekTabData = [
    {
      label: `Setup connection accounts`,
      key: 1,
      children: (
        <div>
          <LinkAccountMtToGhlConnection data={newData} setData={(data) => setNewData(data)} />
        </div>
      ),
    },
    {
      label: `Manage services`,
      key: 2,
      children: (
        <div>
          {activeTab === 2 && (
            <ManageMtServicesTab
              data={newData}
              setData={(data) => setNewData(data)}
              webhook_type={type}
              reloadWebhookData={reloadWebhookData}
              triggerServicesSaveButton={triggerMtServicesSaveButton}
              setTriggerServicesSaveButton={(bool) => setTriggerMtServicesSaveButton(bool)}
              activeServicesSaveTrigger={activeServicesSaveTrigger}
              setActiveServicesSaveTrigger={(bool) => setActiveServicesSaveTrigger(bool)}
              setupDifficulty={setupDifficulty}
            />
          )}{" "}
        </div>
      ),
    },
    {
      label: `Manage GoHighLevel tags`,
      key: 3,
      children: (
        <div>
          {activeTab === 3 && (
            <LinkGoHighLevelTags
              data={newData}
              setData={(data) => setNewData(data)}
              manageType={manageType}
              reloadWebhookData={reloadWebhookData}
              webhook_type={type}
              triggerTagsSaveButton={triggerTagsSaveButton}
              setTriggerTagsSaveButton={(bool) => setTriggerTagsSaveButton(bool)}
              setupDifficulty={setupDifficulty}
              isClientFieldPresent={isClientFieldPresent}
              setIsClientFieldPresent={(bool) => setIsClientFieldPresent(bool)}
            />
          )}
        </div>
      ),
    },
  ];

  const handleTabChange = (activeKey) => {
    setActiveTab(activeKey);
  };

  useEffect(() => {
    if (existingData) {
      setManageType("exist");
      setNewData(existingData);
    }
  }, [existingData]);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleStatusChange = (data) => {
    setContactStatus(!contactStatus);
    setStatusLoading(true);

    let toastBox = toast;
    const changeStatusContactPromise = changeStatusContactWebhook({
      secret_key: data,
    });
    changeStatusContactPromise
      .then(
        (data) => {
          reloadWebhookData();
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setTimeout(() => {
          setStatusLoading(false);
        }, 2000);
      });
  };

  const handleSaveButton = () => {
    let toastBox = toast;
    if (activeTab === 1) {
      toast.loading(`updating...`, {
        id: toastBox,
      });
      if (type === "manage_class") {
        const updateGhlClassBookingPromise = updateGhlClassBookingWebhook({ data: newData, type: "account" });
        updateGhlClassBookingPromise
          .then(
            (msg) => {
              toast.success(`updated successfully`, {
                id: toastBox,
              });
              reloadWebhookData();
            },
            (msg) => {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
          )
          .catch((err) => {
            toast.error(`${err}`, {
              id: toastBox,
            });
          });
      } else if (type === "ghl_to_mt_manage_class") {
        const updateGhlClassBookingPromise = updateGhlToMtClassWebhook({ data: newData, type: "account" });
        updateGhlClassBookingPromise
          .then(
            (msg) => {
              toast.success(`updated successfully`, {
                id: toastBox,
              });
              reloadWebhookData();
            },
            (msg) => {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
          )
          .catch((err) => {
            toast.error(`${err}`, {
              id: toastBox,
            });
          });
      } else if (type === "manage_appointment") {
        const updateGhlAppointmentBookingPromise = updateGhlAppointmentBookingWebhook({ data: newData, type: "account" });
        updateGhlAppointmentBookingPromise
          .then(
            (msg) => {
              toast.success(`updated successfully`, {
                id: toastBox,
              });
              reloadWebhookData();
            },
            (msg) => {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
          )
          .catch((err) => {
            toast.error(`${err}`, {
              id: toastBox,
            });
          });
      } else if (type === "ghl_to_mt_manage_membership") {
        const updateGhlAppointmentBookingPromise = updateGhlToMtMembershipWebhook({ data: newData, type: "account" });
        updateGhlAppointmentBookingPromise
          .then(
            (msg) => {
              toast.success(`updated successfully`, {
                id: toastBox,
              });
              reloadWebhookData();
            },
            (msg) => {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
          )
          .catch((err) => {
            toast.error(`${err}`, {
              id: toastBox,
            });
          });
      } else if (type === "ghl_purchase_membership") {
        const updateGhlAppointmentBookingPromise = updateGhlMembershipWebhook({ data: newData, type: "account" });
        updateGhlAppointmentBookingPromise
          .then(
            (msg) => {
              toast.success(`updated successfully`, {
                id: toastBox,
              });
              reloadWebhookData();
            },
            (msg) => {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
          )
          .catch((err) => {
            toast.error(`${err}`, {
              id: toastBox,
            });
          });
      } else if (type === "contact_creation") {
        const updateGhlAppointmentBookingPromise = updateGhlContactWebhook({ data: newData, type: "account" });
        updateGhlAppointmentBookingPromise
          .then(
            (msg) => {
              toast.success(`updated successfully`, {
                id: toastBox,
              });
              reloadWebhookData();
            },
            (msg) => {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
          )
          .catch((err) => {
            toast.error(`${err}`, {
              id: toastBox,
            });
          });
      } else if (type === "mindbody_webhook") {
        const updateMBWebhookPromise = updateMBWebhook({ data: newData, type: "account" });
        updateMBWebhookPromise
          .then(
            (msg) => {
              toast.success(`updated successfully`, {
                id: toastBox,
              });
              reloadWebhookData();
            },
            (msg) => {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
          )
          .catch((err) => {
            toast.error(`${err}`, {
              id: toastBox,
            });
          });
      } else if (type === "marianatek_webhook") {
        const updateMBWebhookPromise = updateMtWebhook({ data: newData, type: "account" });
        updateMBWebhookPromise
          .then(
            (msg) => {
              toast.success(`updated successfully`, {
                id: toastBox,
              });
              reloadWebhookData();
            },
            (msg) => {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
          )
          .catch((err) => {
            toast.error(`${err}`, {
              id: toastBox,
            });
          });
      } else {
        toast.error(`Invalid update`, {
          id: toastBox,
        });
      }
    } else if (activeTab === 2) {
      if (type === "manage_class") {
        toast.loading(`updating...`, {
          id: toastBox,
        });
        setLoading(true);
        const updateGhlClassBookingPromise = updateGhlClassBookingWebhook({ data: newData, type: "classCalendar" });
        updateGhlClassBookingPromise
          .then(
            (msg) => {
              toast.success(`updated successfully`, {
                id: toastBox,
              });
              window.location.reload();
            },
            (msg) => {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
          )
          .catch((err) => {
            toast.error(`${err}`, {
              id: toastBox,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (type === "ghl_to_mt_manage_class") {
        const updateGhlClassBookingPromise = updateGhlToMtClassWebhook({ data: newData, type: "classCalendar" });
        updateGhlClassBookingPromise
          .then(
            (msg) => {
              toast.success(`updated successfully`, {
                id: toastBox,
              });
              reloadWebhookData();
            },
            (msg) => {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
          )
          .catch((err) => {
            toast.error(`${err}`, {
              id: toastBox,
            });
          });
      } else if (type === "manage_appointment") {
        toast.loading(`updating...`, {
          id: toastBox,
        });
        const updateGhlClassBookingPromise = updateGhlAppointmentBookingWebhook({ data: newData, type: "appointmentCalendar" });
        updateGhlClassBookingPromise
          .then(
            (msg) => {
              toast.success(`updated successfully`, {
                id: toastBox,
              });
              window.location.reload();
            },
            (msg) => {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
          )
          .catch((err) => {
            toast.error(`${err}`, {
              id: toastBox,
            });
          });
      } else if (type === "ghl_to_mt_manage_membership") {
        const updateGhlAppointmentBookingPromise = updateGhlToMtMembershipWebhook({ data: newData, type: "membershipUpdate" });
        updateGhlAppointmentBookingPromise
          .then(
            (msg) => {
              toast.success(`updated successfully`, {
                id: toastBox,
              });
              reloadWebhookData();
            },
            (msg) => {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
          )
          .catch((err) => {
            toast.error(`${err}`, {
              id: toastBox,
            });
          });
      } else if (type === "ghl_purchase_membership") {
        toast.loading(`updating...`, {
          id: toastBox,
        });
        const updateGhlClassBookingPromise = updateGhlMembershipWebhook({ data: newData, type: "membershipUpdate" });
        updateGhlClassBookingPromise
          .then(
            (msg) => {
              toast.success(`updated successfully`, {
                id: toastBox,
              });
              window.location.reload();
            },
            (msg) => {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
          )
          .catch((err) => {
            toast.error(`${err}`, {
              id: toastBox,
            });
          });
      } else if (type === "mindbody_webhook") {
        // toast.success(`updated successfully!`, {
        //   id: toastBox,
        // });
        handleServicesSaveButton();
        // reloadWebhookData();
      } else if (type === "marianatek_webhook") {
        // toast.success(`updated successfully!`, {
        //   id: toastBox,
        // });
        handleMtServicesSaveButton();
        // reloadWebhookData();
      } else {
        toast.error(`Invalid update`, {
          id: toastBox,
        });
      }
    } else if (activeTab === 3) {
      if (type === "mindbody_webhook") {
        handleCustomTagsSaveButton();
      }
    } else {
      toast.error(`Invalid save option`, {
        id: toastBox,
      });
    }
  };

  return (
    <div>
      <Modal
        title={manageType === "new" ? "Add new connection" : "Edit connection details"}
        className="max-w-5xl"
        width={"70vw"}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Fragment key="save">
            {(manageType === "exist" && type === "mindbody_webhook") ||
              (type === "marianatek_webhook" && (
                <>
                  {activeTab === 1 || activeTab === 3 ? (
                    <Button key="save" type="default" className="bg-green-400" loading={loading} onClick={handleSaveButton}>
                      Save
                    </Button>
                  ) : activeServicesSaveTrigger ? (
                    <Button key="save" type="default" className="bg-green-400" loading={loading} onClick={handleSaveButton}>
                      Save
                    </Button>
                  ) : (
                    <Button key="save" type="default" className="bg-green-400" loading={loading} disabled>
                      Save
                    </Button>
                  )}
                </>
              ))}
            {manageType === "exist" && (type === "manage_class" || type === "ghl_to_mt_manage_class") && (
              <>
                {activeTab === 1 || activeTab === 2 ? (
                  <Button key="save" type="default" className="bg-green-400" loading={loading} onClick={handleSaveButton}>
                    Save
                  </Button>
                ) : (
                  <Button key="save" type="default" className="bg-green-400" loading={loading} disabled>
                    Save
                  </Button>
                )}
              </>
            )}
            {manageType === "exist" && (type === "contact_creation" || type === "ghl_to_mt_contact_creation") && (
              <>
                {activeTab === 1 ? (
                  <Button key="save" type="default" className="bg-green-400" loading={loading} onClick={handleSaveButton}>
                    Save
                  </Button>
                ) : (
                  <Button key="save" type="default" className="bg-green-400" loading={loading} disabled>
                    Save
                  </Button>
                )}
              </>
            )}
            {manageType === "exist" && type === "ghl_to_mt_manage_membership" && (
              <>
                {(activeTab === 1 || activeTab === 2) && JSON.stringify(newData) !== JSON.stringify(existingData) ? (
                  <Button key="save" type="default" className="bg-green-400" loading={loading} onClick={handleSaveButton}>
                    Save
                  </Button>
                ) : (
                  <Button key="save" type="default" className="bg-green-400" loading={loading} disabled>
                    Save
                  </Button>
                )}
              </>
            )}
            {manageType === "exist" && type === "manage_appointment" && (
              <>
                {activeTab === 1 || activeTab === 2 ? (
                  <Button key="save" type="default" className="bg-green-400" loading={loading} onClick={handleSaveButton}>
                    Save
                  </Button>
                ) : (
                  <Button key="save" type="default" className="bg-green-400" loading={loading} disabled>
                    Save
                  </Button>
                )}
              </>
            )}
            {manageType === "exist" && type === "ghl_purchase_membership" && (
              <>
                {activeTab === 1 && newData?.contractId && newData?.locationId && newData?.promocode && JSON.stringify(existingData) !== JSON.stringify(newData) ? (
                  <Button key="save" type="default" className="bg-green-400" loading={loading} onClick={handleSaveButton}>
                    Save
                  </Button>
                ) : (
                  <Button key="save" type="default" className="bg-green-400" loading={loading} disabled>
                    Save
                  </Button>
                )}
              </>
            )}
          </Fragment>,
        ]}
      >
        {/* {type === "mindbody_webhook" && (
          <>
            <Divider className="mb-1 mt-0" />
            <div className="w-full mx-auto justify-end text-end mb-1">
              <Typography.Text className="font-medium text-gray-700">Setup difficulty : </Typography.Text>
              <Switch className="ml-4 bg-blue-gray-300" checkedChildren="Advanced" unCheckedChildren="Normal" onChange={handleSwitchChange} checked={setupDifficulty} />
            </div>
          </>
        )} */}
        {type === "contact_creation" ? (
          <>
            <Divider className="mb-1 mt-0" />
            <Spin spinning={statusLoading}>
              <div className="w-full mx-auto justify-end text-end">
                <Typography.Text className="font-medium text-gray-700">Change status : </Typography.Text>
                <Switch
                  className="ml-4 bg-blue-gray-300"
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  onChange={() => {
                    handleStatusChange(existingData?.secret_key);
                  }}
                  checked={contactStatus}
                />
              </div>
            </Spin>

            <Divider className="my-1" />
          </>
        ) : (
          <Divider className="mt-0" />
        )}
        <Spin spinning={loading}>
          {type === "contact_creation" ? (
            <Tabs className="m-4" activeKey={activeTab} onChange={handleTabChange} tabPosition={"left"} items={ContactTabData} size="middle" />
          ) : type === "ghl_to_mt_contact_creation" ? (
            <Tabs className="m-4" activeKey={activeTab} onChange={handleTabChange} tabPosition={"left"} items={GhlToMtContactTabData} size="middle" />
          ) : type === "ghl_to_mt_manage_class" ? (
            <Tabs className="m-4" activeKey={activeTab} onChange={handleTabChange} tabPosition={"left"} items={GhlToMtClassData} size="middle" />
          ) : type === "ghl_to_mt_manage_membership" ? (
            <Tabs className="m-4" activeKey={activeTab} onChange={handleTabChange} tabPosition={"left"} items={GhlToMtMembershipTabData} size="middle" />
          ) : type === "ghl_purchase_membership" ? (
            <Tabs className="m-4" activeKey={activeTab} onChange={handleTabChange} tabPosition={"left"} items={GhlMembershipTabData} size="middle" />
          ) : type === "mindbody_webhook" ? (
            <Tabs className="m-4" activeKey={activeTab} onChange={handleTabChange} tabPosition={"left"} items={MindbodyTabData} size="middle" />
          ) : type === "marianatek_webhook" ? (
            <Tabs className="m-4" activeKey={activeTab} onChange={handleTabChange} tabPosition={"left"} items={MarianatekTabData} size="middle" />
          ) : (
            <Tabs className="m-4" activeKey={activeTab} onChange={handleTabChange} tabPosition={"left"} items={TabData} size="middle" />
          )}
        </Spin>
      </Modal>
    </div>
  );
};

export default ConnectionManage;
