import React, { Fragment, useState } from "react";
import { Alert, Button, Divider, Modal, Typography } from "antd";
import { deleteMindbodyWebhook } from "../../../../../helpers/WebhookApi";
import { toast } from "react-hot-toast";
import { deleteAppointmentWebhook, deleteClassBookingWebhook, deleteContactWebhook, deleteMembershipWebhook } from "../../../../../helpers/GhlApi";
import { deleteGhlToMtClassWebhook, deleteGhlToMtContactWebhook, deleteGhlToMtMembershipWebhook, deleteMarianatekWebhook } from "../../../../../helpers/MtApi";

const ConnectionDelete = ({ open, setOpen, webhookData, type, reloadWebhookData }) => {
  // const [connectionName, setConnectionName] = useState(
  //   webhookData?.isParent
  //     ? webhookData.children?.map((element) => {
  //         return element.name + ",";
  //       })
  //     : webhookData.name
  // );

  const handleOk = (type) => {
    let toastBox = toast;
    let deleteWebhookPromise = null;
    if (type === "mindbody_webhook") {
      if (webhookData?.isParent) {
        deleteWebhookPromise = deleteMindbodyWebhook({
          secret_key: webhookData?.children[0]?.secret_key,
        });
      } else {
        deleteWebhookPromise = deleteMindbodyWebhook({
          secret_key: webhookData?.secret_key,
        });
      }
    } else if (type === "marianatek_webhook") {
      if (webhookData?.isParent) {
        deleteWebhookPromise = deleteMarianatekWebhook({
          secret_key: webhookData?.children[0]?.secret_key,
        });
      } else {
        deleteWebhookPromise = deleteMarianatekWebhook({
          secret_key: webhookData?.secret_key,
        });
      }
    } else if (type === "manage_class") {
      if (webhookData?.isParent) {
        deleteWebhookPromise = deleteClassBookingWebhook({
          secret_key: webhookData.children.map((item) => item.secret_key),
        });
      } else {
        deleteWebhookPromise = deleteClassBookingWebhook({
          secret_key: [webhookData?.secret_key],
        });
      }
    } else if (type === "contact_creation") {
      if (webhookData?.isParent) {
        deleteWebhookPromise = deleteContactWebhook({
          secret_key: webhookData.children.map((item) => item.secret_key),
        });
      } else {
        deleteWebhookPromise = deleteContactWebhook({
          secret_key: [webhookData?.secret_key],
        });
      }
    } else if (type === "ghl_to_mt_contact_creation") {
      if (webhookData?.isParent) {
        deleteWebhookPromise = deleteGhlToMtContactWebhook({
          secret_key: webhookData.children.map((item) => item.secret_key),
        });
      } else {
        deleteWebhookPromise = deleteGhlToMtContactWebhook({
          secret_key: [webhookData?.secret_key],
        });
      }
    } else if (type === "ghl_to_mt_manage_class") {
      if (webhookData?.isParent) {
        deleteWebhookPromise = deleteGhlToMtClassWebhook({
          secret_key: webhookData.children.map((item) => item.secret_key),
        });
      } else {
        deleteWebhookPromise = deleteGhlToMtClassWebhook({
          secret_key: [webhookData?.secret_key],
        });
      }
    } else if (type === "ghl_to_mt_manage_membership") {
      if (webhookData?.isParent) {
        deleteWebhookPromise = deleteGhlToMtMembershipWebhook({
          secret_key: webhookData.children.map((item) => item.secret_key),
        });
      } else {
        deleteWebhookPromise = deleteGhlToMtMembershipWebhook({
          secret_key: [webhookData?.secret_key],
        });
      }
    } else if (type === "ghl_purchase_membership") {
      if (webhookData?.isParent) {
        deleteWebhookPromise = deleteMembershipWebhook({
          secret_key: webhookData.children.map((item) => item.secret_key),
        });
      } else {
        deleteWebhookPromise = deleteMembershipWebhook({
          secret_key: [webhookData?.secret_key],
        });
      }
    } else if (type === "manage_appointment") {
      if (webhookData?.isParent) {
        deleteWebhookPromise = deleteAppointmentWebhook({
          secret_key: webhookData.children.map((item) => item.secret_key),
        });
      } else {
        deleteWebhookPromise = deleteAppointmentWebhook({
          secret_key: [webhookData?.secret_key],
        });
      }
    }

    if (deleteWebhookPromise !== null) {
      toast.loading("Loading...", {
        id: toastBox,
      });
      deleteWebhookPromise
        .then(
          (data) => {
            toast.success("Connection deleted", {
              id: toastBox,
            });
            window.location.reload();
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      title="Are you sure delete this connection?"
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Fragment key="confirm">
          <Button className="bg-red-300 text-white" onClick={() => handleOk(type)}>
            Confirm
          </Button>
        </Fragment>,
      ]}
    >
      <Divider className="my-2" />
      <Alert message="Warning! Once deleted, cannot be reverted back." type="warning" />
      <Typography.Title level={5} className="mt-2">
        Connection name(s): {webhookData?.isParent ? webhookData.children?.map((element, index) => (index === webhookData.children.length - 1 ? element.name : element.name + ", ")).join("") : webhookData?.name}
      </Typography.Title>
      <div className="mt-2 flex-col flex">
        <Typography.Text level={5} className="mb-0 pb-0">
          • Webhook URL will be permanently deleted
        </Typography.Text>
        <Typography.Text level={5} className="mt-0 pt-0">
          • Custom selections and tags will be lost
        </Typography.Text>
      </div>
    </Modal>
  );
};

export default ConnectionDelete;
