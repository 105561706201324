import React from "react";
import { Fragment } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Card,
  Typography,
} from "@material-tailwind/react";
import { useMediaQuery } from "react-responsive";

const ShowUserDataModal = ({ open, setOpen, userData }) => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 1045px)" });
  const isMobileScreenShorter = useMediaQuery({ query: "(max-width: 487px)" });
  const excessModalHeight = useMediaQuery({ query: "(min-height: 600px)" });

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        handler={handleOpen}
        size={isMobileScreen ? (isMobileScreenShorter ? "xxl" : "xl") : "md"}
        className={
          !excessModalHeight ? "overflow-y-scroll" : "overflow-y-visible"
        }
        style={{ maxHeight: "90vh" }}
      >
        <DialogHeader>User Webhook Usage Details</DialogHeader>
        <DialogBody divider>
          {open && <Card className="w-96 p-5 mx-auto justify-center loginCard">
            <div className="grid grid-flow-col">
              <div>
                <Typography
                  color="black"
                  className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3"
                >
                  Contact / clients transferred:
                </Typography>
              </div>
              <div className="w-full ml-4">
                <Typography
                  color="blue-gray"
                  className="font-normal text-lg leading-none opacity-70 w-full text-end pt-3 pr-4"
                >
                  {userData[0].clientTransferred}
                </Typography>
              </div>
            </div>
            <div className="pt-5 grid grid-flow-col">
              <div>
                <Typography
                  color="black"
                  className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3"
                >
                  Appointments transferred:
                </Typography>
              </div>
              <div className="w-full ml-4">
                <Typography
                  color="blue-gray"
                  className="font-normal text-lg leading-none opacity-70 w-full text-end pt-3 pr-4"
                >
                  {userData[0].appointmentTransferred}
                </Typography>
              </div>
            </div>
            <div className="pt-5 grid grid-flow-col">
              <div>
                <Typography
                  color="black"
                  className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3"
                >
                  Membership purchased:
                </Typography>
              </div>
              <div className="w-full ml-4">
                <Typography
                  color="blue-gray"
                  className="font-normal text-lg leading-none opacity-70 w-full text-end pt-3 pr-4"
                >
                  {userData[0].membershipPurchased}
                </Typography>
              </div>
            </div>
            <div className="pt-5 grid grid-flow-col">
              <div>
                <Typography
                  color="black"
                  className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3"
                >
                  Membership cancelled:
                </Typography>
              </div>
              <div className="w-full ml-4">
                <Typography
                  color="blue-gray"
                  className="font-normal text-lg leading-none opacity-70 w-full text-end pt-3 pr-4"
                >
                  {userData[0].membershipCancelled}
                </Typography>
              </div>
            </div>
          </Card>}
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default ShowUserDataModal;
